import React, { useEffect, useState } from "react";
import MenuPrincipal from '../../components/navbar'
import { Form, Row, Col, Spinner, Modal, Button, Container } from "react-bootstrap";
import { faPlus, faPencil, faSave, faTrash, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";

/**TABLE */
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@tremor/react";
import { messageAlert, verificarAcceso } from "../../components/utils";
/**TABLE */

function Usuarios() {
  const [userData, setUserData] = useState([]);
  const [idx, setIdx] = useState(0);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [mail2, setMail2] = useState("");
  const [pass, setPass] = useState("");
  const [rol, setRol] = useState("2");
  const [accesosAdicionales, setAccesosAdicionales] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [existValue, setExistValue] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [arrPermisos, setArrPermisos] = useState([]);
  const [arrPermisosUsuarios, setArrPermisosUsuarios] = useState({});
  const [showPermisos, setShowPermisos] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();

  const columns = [
    { id: "name", label: "Nombre", fontWeight: 700 },
    {
      id: "email",
      label: "Email",
      align: "center",
      fontWeight: 700,
      minWidth: "15vw",
    },
    {
      id: "role",
      label: "Rol",
      align: "center",
      fontWeight: 700,
      minWidth: "10vw",
    },
    {
      id: "accion",
      label: "Accion",
      align: "center",
      fontWeight: 700,
      minWidth: "5vw",
    },
  ];

  const handleChangePage = (event, newPage, ord) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, ord) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    if (isLoading) return;
    setShow(false);
    setIsEditing(false);
    setExistValue(false);
    resetForm();
    setIsLoading(false);
  };
  const handleSave = async () => {
    try {
      setIsLoading(true);
      setExistValue(false);
      if (userData.some((e) => e.email.toUpperCase() === mail.toUpperCase())) {
        setExistValue(true);
        setIsLoading(false);
        return;
      }
      let dat = {
        ci_nombre: name,
        ci_apellido: lastname,
        ci_email: mail,
        ci_role: rol.toString() || "2",
        ci_admin: isAdmin || false,
      };
      const created = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/create-user-web",
        JSON.stringify(dat)
      );
      const res = await axios.get(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/get-users"
      );
      if (res.data?.data?.count > 0) setUserData(order(res.data?.data?.data));
      else setUserData([]);
      if (created.data?.data?.message === "El email ya existe") {
        await messageAlert("Alerta", "El email ya fue registrado anteriormente.", 1800);
      } else {
        await messageAlert("¡Listo!", "Usuario creado", 1800);
      }
      setShow(false);
      resetForm();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleShow = () => setShow(true);

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      setExistValue(false);
      if (
        mail2.toUpperCase() !== mail.toUpperCase() &&
        userData.some((e) => e.email.toUpperCase() === mail.toUpperCase())
      ) {
        setExistValue(true);
        setIsLoading(false);
        return;
      }
      let dat = {
        ci_nombre: name,
        ci_apellido: lastname,
        ci_email: mail,
        ci_password: pass,
        ci_role: rol.toString() || "2",
        ci_admin: isAdmin || false,
        ci_id: idx,
      };
      await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/update-user-web",
        JSON.stringify(dat)
      );
      const res = await axios.get(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/get-users"
      );
      if (res.data?.data?.count > 0) setUserData(order(res.data?.data?.data));
      else setUserData([]);
      await messageAlert("¡Listo!", "Usuario actualizado", 1800);
      setShow(false);
      setIsEditing(false);
      resetForm();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  function capitalize(str) {
    if (str === "R.R.H.H." || str === "RRHH" || str === "CI") return str;
    let words = str.split(" ");
    let capitalizedWords = words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    let result = capitalizedWords.join(" ");
    return result;
  }

  const handleDel = async (id, idx) => {
    const result = await Swal.fire({
      title: "¿Está seguro?",
      text: "Se procederá a desactivar el usuario seleccionado",
      icon: "warning",
      confirmButtonColor: "#329fe7",
      showCancelButton: true,
      confirmButtonText: "Si, Desactivar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      try {
        setIsLoading(true);
        if (user.ext === id) {
          await messageAlert(
            "¡Alerta!",
            "No se puede eliminar este usuario",
            2800
          );
          setIsLoading(false);
          return;
        }
        await axios.post(
          "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/delete-user-web",
          JSON.stringify({ ci_id: id })
        );
        const res = await axios.get(
          "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/get-users"
        );
        if (res.data?.data?.count > 0) setUserData(order(res.data?.data?.data));
        else setUserData([]);
        await messageAlert("¡Listo!", "Usuario Desactivado", 1800);
        setIsLoading(false);
      } catch (error) {
        if (error.response.status === 403) {
          let aux = [...userData];
          aux[idx].noDelete = true;
          setUserData(aux);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      }
    }
  };

  const resetForm = () => {
    setIdx(0);
    setName("");
    setLastName("");
    setMail("");
    setMail2("");
    setPass("");
    setRol("2");
    setIsAdmin(false);
  };

  const order = (arr) => {
    arr.sort((a, b) => {
      let pa = a.nombre.toUpperCase();
      let pb = b.nombre.toUpperCase();
      if (pa < pb) return -1;
      if (pa > pb) return 1;
      return 0;
    });
    return arr;
  };

  const getUsers = async () => {
    try {
      const res = await axios.get("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/get-users");
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  const getPermisosPorUsuario = async (id) => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/obtener-permisos",
        JSON.stringify({ ci_id: id })
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  }

  const changePermisions = async (value, id) => {
    const arrAuxPermisos = { ...arrPermisosUsuarios };
    arrAuxPermisos[id] = value
    setArrPermisosUsuarios(arrAuxPermisos)
  }

  const getPermisos = async () => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/obtener-permisos",
        JSON.stringify({})
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  }

  const handleSavePermisos = async () => {
    try {
      setIsLoading(true)
      let aux = []
      for (const key in arrPermisosUsuarios) {
        if (arrPermisosUsuarios[key]) aux.push(key)
      }
      await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/actualizar-permisos",
        JSON.stringify({ ci_id: idx, ci_views: aux })
      );
      handleClosePermisos()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const handleClosePermisos = () => {
    setShowPermisos(false)
    setArrPermisosUsuarios([])
    setIdx(0);
  }

  const handleShowPermisos = async (id) => {
    setIsLoading(true)
    setIdx(id);
    try {
      const dataPermisos = await getPermisosPorUsuario(id)
      if (dataPermisos?.data?.count > 0) {
        let aux = {}
        dataPermisos?.data?.data.forEach(el => {
          if (el.id_vista !== null) aux[el.id_vista] = true
        });
        setArrPermisosUsuarios(aux)
      }
      setShowPermisos(true)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        let accesos = await verificarAcceso(user.ext)
        if (!accesos?.adm && !accesos?.v3) {
          navigate("/", { replace: true });
          return
        } else {
          setAccesosAdicionales(accesos)
        }
        const [dataUsuarios, dataPermisos] = await Promise.all([
          getUsers(),
          getPermisos()
        ]);
        if (dataUsuarios?.data?.count > 0) setUserData(order(dataUsuarios?.data?.data));
        if (dataPermisos?.data?.count > 0) setArrPermisos(dataPermisos?.data?.data)
        setTimeout(() => {
          setIsPageLoading(false);
        }, 850);
      } catch (error) {
        console.log(error);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    accesosAdicionales && <>
      <MenuPrincipal isLoading={isPageLoading} />
      <Container className="page-content">
        <Row className="mb-3" xs="auto">
          <Col>
            <h4>Usuarios</h4>
          </Col>
          <Col className="text-right">
            <Button variant="success" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlus} /> Crear
            </Button>
          </Col>
        </Row>
        <Row>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "calc(100vh - 220px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: column.fontWeight,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((userx, idx) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                          <TableCell
                            key={"A"}
                            align={"left"}
                            style={{ fontWeight: 500 }}
                          >
                            {capitalize(userx.nombre || "")}{" "}
                            {capitalize(userx.apellido || "")}
                          </TableCell>
                          <TableCell
                            key={"B"}
                            align={"center"}
                            style={{ fontWeight: 500 }}
                          >
                            {userx.email}
                          </TableCell>
                          <TableCell key={"C"} align={"center"}>
                            {userx.rol_id && Number(userx.rol_id) === 2
                              ? "RRHH"
                              : "Verificador"}
                            {userx.is_admin && ", Administrador"}
                          </TableCell>
                          <TableCell
                            key={"D"}
                            align={"center"}
                            className="gap-4"
                          >
                            <Button
                              className="me-2"
                              variant="dark"
                              disabled={isLoading}
                              onClick={() => {
                                setIsEditing(true);
                                setIdx(userx.id);
                                setName(userx.nombre);
                                setLastName(userx.apellido);
                                setMail(userx.email);
                                setMail2(userx.email);
                                setRol(userx.rol_id);
                                setIsAdmin(userx.is_admin || false);
                                handleShow();
                              }}
                            >
                              {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faPencil} />}
                            </Button>
                            {userx.rol_id === 2 && !userx.is_admin && <Button onClick={() => handleShowPermisos(userx.id)} disabled={isLoading}>
                              {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faListCheck} />}
                            </Button>}
                            <Button
                              className="ms-2"
                              variant="danger"
                              disabled={isLoading}
                              onClick={() => {
                                handleDel(userx.id, idx);
                              }}
                            >
                              {isLoading === true ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <FontAwesomeIcon icon={faTrash} />
                              )}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={userData?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {!isEditing ? "Agregar " : "Editar "}Usuario
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Grid numItemsMd={1} numItemsLg={2} className="gap-4">
              <Form.Group className="mb-3" controlId="newUserName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="newUserLastName">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastname}
                />
              </Form.Group>
            </Grid>
            <Form.Group className="mb-3" controlId="newUserMail">
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                value={mail}
              />
            </Form.Group>
            <Grid numItemsMd={1} numItemsLg={2} className="gap-4">
              <Form.Group className="mb-3" controlId="newRolePosition">
                <Form.Label>Rol</Form.Label>
                <Form.Select
                  value={rol}
                  onChange={(e) => {
                    setRol(Number(e.target.value));
                  }}
                >
                  <option key={1} value={"1"}>
                    Verificador
                  </option>
                  <option key={0} value={"2"}>
                    RRHH
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="newAdmin">
                <Form.Label>¿Administrador?</Form.Label>
                <Form.Select
                  value={isAdmin}
                  onChange={(e) => {
                    setIsAdmin(e.target.value);
                  }}
                >
                  <option key={1} value={true}>
                    Si
                  </option>
                  <option key={0} value={false}>
                    No
                  </option>
                </Form.Select>
              </Form.Group>
            </Grid>
            <p className="text-danger">
              {existValue && "Correo ya existente."}
            </p>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={isLoading} onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={!isEditing ? handleSave : handleUpdate}
          >
            {isLoading === true ? "" : "Guardar "}
            {isLoading === true ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FontAwesomeIcon icon={faSave} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPermisos} onHide={handleClosePermisos} centered>
        <Modal.Header closeButton>
          <Modal.Title>Permisos Adicionales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {arrPermisos.map((permiso) => (
            <Row key={permiso.id} >
              <Col>{permiso.descripcion}</Col>
              <Col>
                <Form.Check type={"checkbox"} checked={arrPermisosUsuarios[permiso.id] || false} id={"check-" + permiso.id} onChange={(e) => changePermisions(e.target.checked, permiso.id)} className="customCheckbox" />
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={isLoading} onClick={handleClosePermisos}>
            Cerrar
          </Button>
          <Button variant="primary" disabled={isLoading} onClick={handleSavePermisos}>
            {isLoading === true ? "" : "Guardar "}
            {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FontAwesomeIcon icon={faSave} />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Usuarios;
