import React from "react";
import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import ListaDocumentos from "./pages/listaDocumentos"
import DashboardMedico from "./pages/dashboardMedico"
import DashboardRRHH from "./pages/dashboardRRHH";
// Marco
import DashboardUpdate from "./pages/dashboardUpdate";
import DashboardMasivo from "./pages/dashboardMasivo";
import Error404 from "./pages/error404";
//import VisorDocumentos from "./pages/visorDocumentos"
import Login from "./pages/login";
import VerS3 from "./pages/verS3";
import DashboardReportes from "./pages/reportes";
import ResetPassword from "./pages/resetPass";
import Usuarios from "./pages/admin/usuarios";

function AppRouter() {
  return (
    <Router basename={"/"}>
      <Routes basename={"/"}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/docList/:idReg" element={<ListaDocumentos />} />
        <Route path="/op" element={<DashboardMedico />} />
        <Route path="/completarfile" element={<DashboardMedico />} />
        <Route path="/admUsuarios" element={<Usuarios />} />
        {/* Marco */}
        <Route path="/dashboardUpdate" element={<DashboardUpdate />} />
        <Route path="/dashboardMasivo" element={<DashboardMasivo />} />
        {/* Marco */}
        <Route path="/dashboardRRHH/:idReg" element={<DashboardRRHH />} />
        {<Route path="/misReportes" element={<DashboardReportes />} />}
        {/*<Route path="/visor/:idReg/:ref" element={<VisorDocumentos />} />*/}
        <Route path="/abrirArchivoS3" element={<VerS3 />} />
        <Route path="/abrirArchivoS3/:url" element={<VerS3 />} />
        {/*  <Route path="*" element={<Navigate to="/" />} /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
