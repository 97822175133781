import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import Swal from "sweetalert2";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calcularVigencia, limpiarTexto, messageAlert } from '../components/utils';
import Viewer from 'react-viewer';

function VisorDocumentosModal({ onHide, documentRef, tipoDocOptions, personal, onRefresh, PageLoading }) {
  //const [dataArchivo, setDataArchivo] = useState()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [tipoFileViewer, setTipoFileViewer] = useState("PDF")
  const [file, setSelectedDocumentUrl] = useState()
  //const [persona, setPersona] = useState(1)

  const [arrChilds, setArrChilds] = useState([])
  /**datos por form */
  const [idxActual, setIdxActual] = useState(0)
  const [aplicaVigencia, setAplicaVigencia] = useState("NO");
  const [vigencia, setVigencia] = useState("")
  const [vigenteEnero, setVigenteEnero] = useState("SI")
  const [tipoArchivo, setTipoArchivo] = useState("")
  const [label, setLabelTipoArchivo] = useState("")
  const [pagina, setPagina] = useState(1)
  const [obs, setObs] = useState("")
  const [estado, setEstado] = useState("OBSERVADO")
  /** */

  const [regPrincipal, setRegPrincipal] = useState(null)
  const [showModalEdicion, setShowModalEdicion] = useState(false)
  const [isAdicional, setIsAdicional] = useState(false)
  const [tDocObj, setTDocObj] = useState({})
  const [incomplete, setIncomplete] = useState(true)
  const [isRevision, setIsRevision] = useState(false)
  const [isOpenNewTab, setIsOpenNewTab] = useState(false)

  const [imgPane, setImgPane] = useState(true)

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const EXT_NEW_TAB = ["XLS", "XLSX", "DOC", "DOCX", "PPT", "PPTX"];

  useEffect(() => {
    if (documentRef) {
      (async () => {
        try {
          //let dup = getUriDuplicate(documentRef.ruta_nueva)
          //console.log(dup)
          setIsPageLoading(true)
          setArrChilds([])
          setIncomplete(true)
          let reg = {
            aplicaVigencia: documentRef.vigencia_archivo,
            label: documentRef.nomTDoc,
            "ci_tb_general_id": documentRef.id_registro?.toString(),
            "ci_tb_general_cod_tipo_doc": documentRef.cod_tipo_doc,
            "ci_tb_general_status_archivo": documentRef.status_archivo || "OBSERVADO",
            "ci_tb_general_vigencia_archivo_2024": documentRef.vigencia_archivo_2024 && documentRef.vigencia_archivo_2024.trim() !== "" ? documentRef.vigencia_archivo_2024 : calcularVigencia(documentRef.fecha_fin_vigencia_archivo).toString(),
            "ci_tb_general_fecha_fin_vigencia_archivo": documentRef.fecha_fin_vigencia_archivo,
            "ci_tb_general_id_usuario": user.ext,
            "ci_tb_general_pagina": documentRef.pagina || 1,
            "ci_tb_general_observacion": documentRef.observacion || "",
            "ci_tb_general_ruta_nueva": documentRef.ruta_nueva || ""
          }
          if (documentRef?.status_archivo === "POR REVISAR") setIsRevision(true)
          if (documentRef.cod_tipo_doc !== null && documentRef.cod_tipo_doc !== "X DEFINIR") { setIncomplete(false) }
          else {
            reg.label = ""
            reg.ci_tb_general_cod_tipo_doc = ""
            reg.aplicaVigencia = "NO"
          }
          setRegPrincipal(reg)
          //}
          setSelectedDocumentUrl(documentRef.url);
          setTipoFileViewer(documentRef.tp)
          setImgPane(true)
          if (EXT_NEW_TAB.indexOf(documentRef.tp) !== -1) setIsOpenNewTab(true)
          setIsPageLoading(false)
        } catch (error) {
          console.log(error)
          setIsPageLoading(false)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentRef])

  const changeSelectorTDoc = (data, idx) => {
    setAplicaVigencia(data.exp)
    setTipoArchivo(data.value)
    setLabelTipoArchivo(data.label)
  }

  /**nuevo */
  const openEditModal = () => {
    setShowModalEdicion(true)
    setIsAdicional(false)
    setAplicaVigencia(regPrincipal.aplicaVigencia || "SI")
    setVigencia(regPrincipal.ci_tb_general_fecha_fin_vigencia_archivo)
    setVigenteEnero(regPrincipal.ci_tb_general_vigencia_archivo_2024)
    setTipoArchivo(regPrincipal.ci_tb_general_cod_tipo_doc)
    setLabelTipoArchivo(regPrincipal.label)
    setPagina(regPrincipal.ci_tb_general_pagina)
    setObs(regPrincipal.ci_tb_general_observacion)
    setIdxActual(regPrincipal.ci_tb_general_id)
    setEstado(regPrincipal.ci_tb_general_status_archivo)
    setTDocObj({ "label": regPrincipal.label, "value": regPrincipal.ci_tb_general_cod_tipo_doc, "exp": regPrincipal.aplicaVigencia })
  }

  const openNewDataModal = () => {
    setShowModalEdicion(true)
    setIsAdicional(true)
  }

  const closeEditModal = () => {
    setShowModalEdicion(false)
    setIsAdicional(false)
    setAplicaVigencia("NO")
    setVigencia("")
    setVigenteEnero("SI")
    setTipoArchivo("")
    setLabelTipoArchivo("")
    setPagina(1)
    setObs("")
    setIdxActual(0)
    setTDocObj({})
  }

  const getUriDuplicate = (l) => {
    if (!l) return;
    if (l.length < 6) return
    let arr = l.split("s3://ci-gestor-documental-2023/");
    return arr[arr.length - 1];
  }

  const deleteOldFile = async (key) => {
    if (key && key.length < 6) return;
    try {
      let res = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/delete-file-s3", JSON.stringify({ "key": key }))
      //console.log("duplicate: ", key)
    } catch (error) {
      console.log(error)
    }
  }

  const updateData = async () => {
    setIsLoading(true)
    let reg = regPrincipal;
    reg.label = label
    reg.ci_tb_general_id = documentRef.id_registro?.toString()
    reg.ci_tb_general_cod_tipo_doc = tipoArchivo
    reg.ci_tb_general_status_archivo = estado
    reg.ci_tb_general_vigencia_archivo_2024 = vigenteEnero.toString()
    reg.ci_tb_general_fecha_fin_vigencia_archivo = vigencia
    reg.ci_tb_general_id_usuario = user.ext
    reg.ci_tb_general_pagina = pagina
    reg.ci_tb_general_observacion = obs
    reg.ci_tb_general_ruta_nueva = ""
    setRegPrincipal(reg)
    let res1 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/update-tb-general", JSON.stringify(reg))
    let dSave = res1.data;
    if (dSave?.data?.count > 0) {
      if (documentRef.ruta_nueva) await deleteOldFile(getUriDuplicate(documentRef.ruta_nueva))
      //await onRefresh();
      await messageAlert("Datos Registro", "Se guardaron los cambios. Recuerda 'Sellar File'", 1800)
    }
    setIncomplete(false)
    setIsLoading(false)
    closeEditModal()
  }

  const saveNewData = async () => {
    let rows = [...arrChilds]
    setIsLoading(true)
    let reg = {};
    reg.label = label
    reg.ci_tb_general_ruta_origen = documentRef.ruta_origen?.toString()
    reg.ci_tb_general_cod_tipo_doc = tipoArchivo
    reg.ci_tb_general_status_archivo = estado
    reg.ci_tb_general_vigencia_archivo_2024 = vigenteEnero.toString()
    reg.ci_tb_general_fecha_fin_vigencia_archivo = vigencia
    reg.ci_tb_general_id_usuario = user.ext
    reg.ci_tb_general_pagina = pagina
    reg.ci_tb_general_observacion = obs
    reg.ci_tb_general_ruta_nueva = ""
    reg.ci_tb_general_doc_identidad_medico = personal["N°Documento"]
    let res1 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/insert-tb-general", JSON.stringify(reg))
    let dSave = res1.data;
    if (dSave?.data?.count > 0) {
      reg.ci_tb_general_id = dSave?.data?.data[0].id_registro
      rows.push(reg)
      setArrChilds(rows)
      //await onRefresh();
      await messageAlert("Datos Registro", "Se guardaron los cambios", 1800)
    }
    setIncomplete(false)
    closeEditModal()
    setIsLoading(false)
  }

  const getInfoUriNew = (uri) => {
    let res = {}
    let st1 = uri.split("/")
    res.p1 = st1[1]
    let st2 = st1[2].split("_")
    res.p2 = st2[1]
    return res
  }

  const aproveRegistroAdicionales = async () => {
    try {
      for (const it of arrChilds) {
        let payload = { "key": documentRef.uri }

        if (documentRef.new) { let r = getInfoUriNew(documentRef.uri); payload.key_nuevo = `${r.p1}/${it.ci_tb_general_status_archivo}/${r.p1}_${r.p2}_${it.ci_tb_general_cod_tipo_doc}.${tipoFileViewer.toLowerCase()}` }
        else payload.key_nuevo = `${personal["T.Doc."]}_${personal["N°Documento"]}/${it.ci_tb_general_status_archivo}/${personal["T.Doc."]}_${personal["N°Documento"]}_${limpiarTexto(personal["Apellidos y Nombres"])}_${it.ci_tb_general_cod_tipo_doc}.${tipoFileViewer.toLowerCase()}`

        let link = documentRef.new ? "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/moved-file-s3-2" : "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/moved-file-s3"
        let res1 = await axios.post(link, JSON.stringify(payload))
        let dSave = res1.data;
        if (dSave?.data?.statusCode === 200) {
          let temp = it
          temp.ci_tb_general_ruta_nueva = dSave?.data?.ruta_s3;
          let resUp = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/update-tb-general", JSON.stringify(temp))
          let dUp = resUp.data;
          if (dUp?.data?.count > 0) {
            console.log("up", it)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const aproveRegistroPrincipal = async (regProp) => {
    let regProcess = regProp || regPrincipal
    let payload = { "key": documentRef.uri }

    if (documentRef.new) { let r = getInfoUriNew(documentRef.uri); payload.key_nuevo = `${r.p1}/${regProcess.ci_tb_general_status_archivo}/${r.p1}_${r.p2}_${regProcess.ci_tb_general_cod_tipo_doc || ""}.${tipoFileViewer.toLowerCase()}` }
    else payload.key_nuevo = `${personal["T.Doc."]}_${personal["N°Documento"]}/${regProcess.ci_tb_general_status_archivo}/${personal["T.Doc."]}_${personal["N°Documento"]}_${limpiarTexto(personal["Apellidos y Nombres"])}_${regProcess.ci_tb_general_cod_tipo_doc || ""}.${tipoFileViewer.toLowerCase()}`

    let link = documentRef.new ? "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/moved-file-s3-2" : "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/moved-file-s3"
    let res1 = await axios.post(link, JSON.stringify(payload))
    let dSave = res1.data;
    if (dSave?.data?.statusCode === 200) {
      let reg = regProcess;
      reg.ci_tb_general_ruta_nueva = dSave?.data?.ruta_s3;
      setRegPrincipal(reg)
      let resUp = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/update-tb-general", JSON.stringify(reg))
      let dUp = resUp.data;
      if (dUp?.data?.count > 0) {
        console.log("up", reg)
        await messageAlert("Datos Registro", "Se guardaron los cambios", 900)
      }
    }
  }

  const aproveAll = async () => {
    PageLoading(true)
    setIsLoading(true)
    await aproveRegistroAdicionales()
    await aproveRegistroPrincipal()
    await onRefresh()
    onHide()
    setIsLoading(false)
    PageLoading(false)
  }

  const noValid = async () => {
    try {
      const res = await Swal.fire({
        title: 'Alerta',
        text: "Este documento será marcado como No válido",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#329fe7',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar'
      })
      if (res.isConfirmed) {
        setIsPageLoading(true);
        PageLoading(true)
        setIsLoading(true)
        let reg = regPrincipal;
        reg.ci_tb_general_status_archivo = "NO VALIDO"
        reg.ci_tb_general_ruta_nueva = ""
        setRegPrincipal(reg)
        if (documentRef.ruta_nueva) await deleteOldFile(getUriDuplicate(documentRef.ruta_nueva))
        await aproveRegistroPrincipal(reg)
        await onRefresh()
        setIsPageLoading(false)
        setIsLoading(false);
        PageLoading(false)
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      PageLoading(false)
      await messageAlert("Error", "Se produjo un error al efectuar la acción.", 1100)
    }
  }

  /** */

  return (
    <>
      {documentRef && <>
        <Row className='mb-3 mt-1'><h6 style={{ marginBottom: 0 }}>{documentRef.nomTDoc === "X DEFINIR" ? "No identificado" : documentRef.nomTDoc}</h6></Row>
        <Row xs={"auto"} className='align-items-center justify-content-center mb-2'>
          <Col><Button variant='primary' onClick={() => openEditModal()} disabled={isLoading}>{isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : "Reclasificar"}</Button></Col>
          <Col><Button variant='primary' onClick={() => openNewDataModal()} disabled={/*true ||*/ isLoading}>{isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : "Añadir tipo adicional"}</Button></Col>
          <Col><Button variant='success' onClick={aproveAll} disabled={isLoading || incomplete || (regPrincipal.ci_tb_general_ruta_nueva !== null && regPrincipal.ci_tb_general_ruta_nueva !== "null" && regPrincipal.ci_tb_general_ruta_nueva !== "")}> {isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : "Sellar File"}</Button></Col>
          <Col><Button variant='danger' onClick={noValid} disabled={isLoading}> {isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : "Invalidar"}</Button></Col>
        </Row>
        <Row>
          {!isPageLoading && (tipoFileViewer === "PDF" || isOpenNewTab) ? <iframe src={file} toolbar="0" height="100%" width="100%" style={{ padding: "0", minHeight: "calc(100vh - 270px)" }} title='file-pdf'></iframe> : <><Viewer visible={imgPane} noImgDetails showTotal={false} zoomSpeed={0.5} scalable={false} changeable={false} noNavbar images={[{ src: file, alt: '' }]} onClose={() => setImgPane(false)} container={document.getElementById("containerImg")} /></>}
        </Row>
      </>}
      <Modal show={showModalEdicion} onHide={closeEditModal} centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{isAdicional ? "Nuevo Registro" : "Editar Registro"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <InputGroup className="mt-1 d-block">
                  <p className="peso">Asignar Tipo documento</p>
                  <Select options={tipoDocOptions} onChange={(e) => { changeSelectorTDoc(e, 1) }} placeholder="Seleccione.." defaultValue={tDocObj} />
                </InputGroup>
                {aplicaVigencia?.toUpperCase() !== "NO" &&
                  <Row>
                    <Col>
                      <p className="mt-4">Asignar Vigencia</p>
                      <InputGroup className='d-block'>
                        <Form.Control
                          placeholder="yyyy-mm-dd"
                          type="date"
                          style={{ width: "50%" }}
                          value={vigencia}
                          onChange={(e) => {
                            setVigencia(e.target.value);
                          }}
                        />
                        {vigencia?.length > 0 && <Button variant='link' onClick={() => { setVigencia("") }}><FontAwesomeIcon className='text-dark' icon={faClose} /></Button>}
                      </InputGroup>
                    </Col>
                    <Col>
                      <p className="mt-4">¿Vigente hasta enero de 2024?</p>
                      <InputGroup className='d-block'>
                        <Form.Select name="Vigente Enero 2024" style={{ width: "70%" }} onChange={(e) => { setVigenteEnero(e.target.value) }} value={vigenteEnero} >
                          <option key={1} value={"SI"}>Si</option>
                          <option key={2} value={"NO"}>No</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </Row>}
                <Row>
                  <Col>
                    <p className="mt-4">Página en la que ubica el documento</p>
                    <InputGroup className='d-block'>
                      <Form.Control
                        placeholder="Página"
                        style={{ width: "120px" }}
                        type='number'
                        value={pagina}
                        onChange={(e) => {
                          setPagina(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <p className="mt-4">Estado</p>
                    <InputGroup className='d-block'>
                      <Form.Select name="estado" style={{ width: "200px" }} onChange={(e) => { setEstado(e.target.value) }} value={estado} >
                        <option key={"OBSERVADO"} value={"OBSERVADO"}>OBSERVADO</option>
                        <option key={"VERIFICADO"} value={"VERIFICADO"}>VERIFICADO</option>
                        <option key={"NO VALIDO"} value={"NO VALIDO"}>NO VALIDO</option>
                        {isRevision && <option key={"POR REVISAR"} value={"POR REVISAR"}>POR REVISAR</option>}
                      </Form.Select>
                    </InputGroup>
                  </Col>
                </Row>
                <p className="mt-4">Observaciones</p>
                <InputGroup className=''>
                  <FormControl as="textarea" rows={3} placeholder="Observaciones" className="" value={obs} maxLength={300} onChange={(e) => setObs(e.target.value)} />
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={isAdicional ? saveNewData : updateData} disabled={isLoading}>Guardar</Button>
          <Button variant='secondary' onClick={closeEditModal} disabled={isLoading}>{"Cancelar"}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default VisorDocumentosModal