let array_staff =  [
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "COP - COLEGIO ODONTOLÓGICO DEL PERÚ",
          "Desc. Posición": "EXTRAMURAL",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ANESTESIOLOGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "51"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUMOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CPP - COLEGIO DE PSICÓLOGOS DEL PERÚ",
          "Desc. Posición": "PSICOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "14"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CPP - COLEGIO DE PSICÓLOGOS DEL PERÚ",
          "Desc. Posición": "NUTRICION ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA INTENSIVA",
          "Desc. Área Personal": "T.H. UCI LIMA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO SUPERVISOR DE QUIMIOTERAPIA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GINECOLOGIA Y OBSTETRICIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "HEMATOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "9"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO GESTOR",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CARDIOLOGIA - RADIOLOGIA",
          "Desc. Área Personal": "CDI",
          "count": "4"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "SERVICIO UMP",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CNP - COLEGIO DE NUTRICIONISTAS DEL PERÚ",
          "Desc. Posición": "NUTRICION",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "COP - COLEGIO ODONTOLÓGICO DEL PERÚ",
          "Desc. Posición": "LACTANCIA MATERNA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO RESIDENTE OTORRINOLARINGOLOGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO HOSPITALARIO",
          "Desc. Área Personal": "T.H. HOSPITALARIO SAN BORJA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA PLASTICA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "12"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PEDIATRIA",
          "Desc. Área Personal": "SERVICIO MAD",
          "count": "21"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GINECOLOGIA Y OBSTETRICIA",
          "Desc. Área Personal": "DPTO. GINECOLOGIA Y OBSTETRICIA",
          "count": "85"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GERIATRIA",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "HEMATOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "T.H. EMERGENCIA SAN BORJA",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA DE TORAX Y CARDIOVASCULAR",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "24"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "14"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "POD - PODÓLOGO",
          "Desc. Posición": "PODOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "7"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUROCIRUGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "4"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "URGENCIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO RESIDENTE DE PEDIATRIA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA INTERNA",
          "Desc. Área Personal": "SERVICIO UMP",
          "count": "12"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ALERGOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA INTERNA",
          "Desc. Área Personal": "SERVICIO EMPO",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EXTRAMURAL",
          "Desc. Área Personal": "ADMINISTRATIVO",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "14"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA DE CABEZA, CUELLO Y MAXILOFACIAL",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "6"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUROLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "25"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ONCOLOGIA MEDICA",
          "Desc. Área Personal": "SERVICIO UDID",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "SERVICIO EMPO",
          "count": "35"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA",
          "Desc. Área Personal": "SERVICIO UMP",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CARDIOLOGIA",
          "Desc. Área Personal": "DPTO. CARDIOLOGIA",
          "count": "41"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "COP - COLEGIO ODONTOLÓGICO DEL PERÚ",
          "Desc. Posición": "ODONTOLOGIA",
          "Desc. Área Personal": "SERVICIO EMPO",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GASTROENTEROLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "38"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "POD - PODÓLOGO",
          "Desc. Posición": "PODOLOGIA",
          "Desc. Área Personal": "SERVICIO UMP",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "SERVICIO UDID",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PSICOLOGIA",
          "Desc. Área Personal": "SERVICIO EMPO",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PSICOLOGIA",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EMERGENCIA GINECOLOGICA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA INTENSIVA",
          "Desc. Área Personal": "T.H. UCI SAN BORJA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "OFTALMOLOGIA",
          "Desc. Área Personal": "DPTO. OFTALMOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EMERGENCIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA NUCLEAR",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEFROLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PSIQUIATRIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "9"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PEDIATRIA",
          "Desc. Área Personal": "SERVICIO DOCTE",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "COP - COLEGIO ODONTOLÓGICO DEL PERÚ",
          "Desc. Posición": "ODONTOLOGIA",
          "Desc. Área Personal": "DPTO. SONRISA TOTAL",
          "count": "72"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CARDIOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "4"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO INVESTIGADOR",
          "Desc. Área Personal": "SERVICIO UDID",
          "count": "6"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA DE TORAX ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "4"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA INTERNA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "51"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEFROLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "9"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GASTROENTEROLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEONATOLOGIA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA GENERAL",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "33"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA DE TORAX Y CARDIOVASCULAR PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA PLASTICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ONCOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "DERMATOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "7"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ONCOLOGIA MEDICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "14"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CNP - COLEGIO DE NUTRICIONISTAS DEL PERÚ",
          "Desc. Posición": "NUTRICION",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "15"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EXTRAMURAL",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "45"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA ",
          "Desc. Área Personal": "SERVICIO MAD",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA ONCOLOGICA DE CABEZA Y CUELLO",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "INFECTOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "7"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PEDIATRIA",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ADMINISTRATIVO",
          "Desc. Área Personal": "ADMINISTRATIVO",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA DE MANO",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ADMINISTRATIVO",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUROCIRUGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "22"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EMERGENCIA",
          "Desc. Área Personal": "T.H. EMERGENCIA SAN BORJA",
          "count": "4"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CNP - COLEGIO DE NUTRICIONISTAS DEL PERÚ",
          "Desc. Posición": "NUTRICION",
          "Desc. Área Personal": "T.H. HOSPITALARIO NUTRICION ",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUMOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "27"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "OFTALMOLOGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "31"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "LACTANCIA MATERNA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA INTERNA",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EMERGENCIA GINECOLOGICA",
          "Desc. Área Personal": "DPTO. GINECOLOGIA Y OBSTETRICIA",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CPP - COLEGIO DE PSICÓLOGOS DEL PERÚ",
          "Desc. Posición": "EXTRAMURAL",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "RADIOTERAPIA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "UROLOGIA ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "9"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA",
          "Desc. Área Personal": "DPTO. MFC / CI DIGITAL",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUMOLOGIA PEDIATRICA",
          "Desc. Área Personal": "SERVICIO UMP",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PATOLOGIA Y LABORATORIO CLINICO",
          "Desc. Área Personal": "PATOLOGIA Y LABORATORIO CLINICO",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "EMERGENCIA",
          "Desc. Área Personal": "T.H. EMERGENCIA LIMA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PEDIATRIA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "40"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "PEDIATRIA",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "OTORRINOLARINGOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "OTORRINOLARINGOLOGIA",
          "Desc. Área Personal": "CDI",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FISICA Y REHABILITACION",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "OTORRINOLARINGOLOGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "34"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "OFTALMOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "12"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GENETICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "2"
      },
      {
        "medico_perfil": "STAFF",
        "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
        "Desc. Posición": "GENETICA",
        "Desc. Área Personal": "DPTO. ONCOLOGIA",
        "count": "1"
    },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA PLASTICA ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "RADIOLOGIA",
          "Desc. Área Personal": "CDI",
          "count": "68"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "SERVICIO MAD",
          "count": "44"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CPP - COLEGIO DE PSICÓLOGOS DEL PERÚ",
          "Desc. Posición": "PSICOLOGIA",
          "Desc. Área Personal": "CI DIGITAL",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA ONCOLOGICA DE MAMAS, TEJIDOS BLANDOS Y PIEL",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "8"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ENDOCRINOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA PALIATIVA Y DOLOR ONCOLOGICO",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "UROLOGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "25"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CPP - COLEGIO DE PSICÓLOGOS DEL PERÚ",
          "Desc. Posición": "PSICOLOGIA",
          "Desc. Área Personal": "SERVICIO EMPO",
          "count": "12"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "DERMATOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "32"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "13"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUROLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA DE TORAX Y CARDIOVASCULAR",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "REUMATOLOGIA PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA ",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUROCIRUGIA ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL INTEGRAL",
          "Desc. Área Personal": "SERVICIO UMP",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ENDOCRINOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "44"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "RADIOLOGIA INTERVENCIONISTA",
          "Desc. Área Personal": "CDI",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA ONCOLOGICA ABDOMINAL",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA GENERAL",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "4"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA FAMILIAR Y COMUNITARIA",
          "Desc. Área Personal": "SERVICIO MAD",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GINECOLOGIA_CDI",
          "Desc. Área Personal": "CDI",
          "count": "10"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICINA PALIATIVA Y DOLOR ONCOLOGICO",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "ANESTESIOLOGIA - TERAPIA DEL DOLOR",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "3"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GINECOLOGIA ONCOLOGICA",
          "Desc. Área Personal": "DPTO. ONCOLOGIA",
          "count": "8"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO HOSPITALARIO",
          "Desc. Área Personal": "T.H. HOSPITALARIO LIMA",
          "count": "6"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "TRAUMATOLOGIA",
          "Desc. Área Personal": "DPTO. CIRUGIA",
          "count": "65"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "NEUROLOGIA",
          "Desc. Área Personal": "SERVICIO UDID",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "CIRUGIA CARDIOVASCULAR PEDIATRICA",
          "Desc. Área Personal": "DPTO. PEDIATRIA",
          "count": "2"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CPP - COLEGIO DE PSICÓLOGOS DEL PERÚ",
          "Desc. Posición": "PSICOLOGIA",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CNP - COLEGIO DE NUTRICIONISTAS DEL PERÚ",
          "Desc. Posición": "NUTRICION",
          "Desc. Área Personal": "SERVICIO UME",
          "count": "1"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "GERIATRIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "5"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "REUMATOLOGIA",
          "Desc. Área Personal": "DPTO. MEDICINA",
          "count": "13"
      },
      {
          "medico_perfil": "STAFF",
          "PUESTO ARCHIVO": "CMP - COLEGIO MÉDICO DEL PERÚ ",
          "Desc. Posición": "MEDICO RESIDENTE DE GASTROENTEROLOGIA",
          "Desc. Área Personal": "DPTO. GASTROENTEROLOGIA",
          "count": "1"
      }
  ]

  export default array_staff;