let array_asistencial = [
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "GERENCIA DE PRODUCTO",
          "unidad_org_2": "NEGOCIACION DE PRODUCTOS",
          "Unid.Organizativa 1": "NEGOCIACION DE PRODUCTOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "SALA DE OPERACIONES",
          "count": "56"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA IMAGENES",
          "Unid.Organizativa 1": "ENFERMERIA IMAGENES",
          "count": "45"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FACTURACION Y BACK OFFICE",
          "Unid.Organizativa 1": "PRE FACTURACION",
          "count": "17"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIVISION LEGAL Y REGULARIZACION",
          "unidad_org_3": "ASESORIA Y CONTRATOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "ASESORIA Y CONTRATOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFE MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "INVESTIGACION Y DOCENCIA",
          "Unid.Organizativa 1": "INVESTIGACION Y DOCENCIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO CDI",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "UDIM",
          "count": "16"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE NEGOCIO DIGITAL",
          "unidad_org_2": "PROYECTO DIGITAL",
          "Unid.Organizativa 1": "PROYECTO DIGITAL",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "TESORERIA",
          "Unid.Organizativa 1": "TESORERIA - INGRESOS",
          "count": "10"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 4",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UNIDAD DE MEDICINA PREVENTIVA",
          "Unid.Organizativa 1": "UNIDAD DE MEDICINA PREVENTIVA",
          "count": "44"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "UDIM",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA NEGOCIACIONES",
          "Unid.Organizativa 1": "FARMACIA NEGOCIACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE NEGOCIOS",
          "Unid.Organizativa 1": "CARTAS DE GARANTIA",
          "count": "14"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UME ADMINISTRATIVO",
          "Unid.Organizativa 1": "UME ADMINISTRATIVO",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "DIRECCION DE UNID ESTRATEGICA DE NEGOCIO",
          "Unid.Organizativa 1": "DIRECCION DE UNID ESTRATEGICA DE NEGOCIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 5",
          "count": "11"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "SERVICIOS NO CLINICOS",
          "Unid.Organizativa 1": "ARCHIVO - HC",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE AUDITORIA GENERAL",
          "unidad_org_3": "GERENCIA DE AUDITORIA GENERAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE AUDITORIA GENERAL",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE NEGOCIOS",
          "Unid.Organizativa 1": "GESTION DE NEGOCIOS",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "COMPRAS Y LOGISTICA",
          "unidad_org_2": "COMPRAS",
          "Unid.Organizativa 1": "COMPRAS",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "PROYECTOS ESTRATEGICOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROYECTOS ESTRATEGICOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA CRITICA - U.C.I.",
          "Unid.Organizativa 1": "ENFERMERIA CRITICA - U.C.I.",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "CONTABILIDAD",
          "Unid.Organizativa 1": "CONTABILIDAD",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "UCIPED",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "NUTRICIONISTA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "NUTRICION",
          "Unid.Organizativa 1": "NUTRICION",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "FARMACIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "109"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": "SERVICIO DE OPERACIONES",
          "Unid.Organizativa 1": "SERVICIO DE OPERACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CANALES DIGITALES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA CANALES DIGITALES",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "COMPRAS Y LOGISTICA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "COMPRAS Y LOGISTICA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 3",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA CRONICOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA DOSIS UNITARIA",
          "count": "17"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "COMPRAS Y LOGISTICA",
          "unidad_org_2": "ABASTECIMIENTO",
          "Unid.Organizativa 1": "ABASTECIMIENTO",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "SEGURIDAD Y SALUD EN EL TRABAJO",
          "unidad_org_2": "SALUD OCUPACIONAL",
          "Unid.Organizativa 1": "SALUD OCUPACIONAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": "DATA GOVERNANCE",
          "Unid.Organizativa 1": "DATA GOVERNANCE",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "MANTENIMIENTO",
          "Unid.Organizativa 1": "MANTENIMIENTO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "CONTROL DE GESTION - OPERACIONES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CONTROL DE GESTION - OPERACIONES",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "DIRECCION DE ENFERMERIA",
          "Unid.Organizativa 1": "ENFERMERIA CRITICA - SALA DE OPERACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA HOSPITALARIA",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "MARKETING",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MARKETING",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "10"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "PEOPLE ANALYTICS Y COMPENSACIONES",
          "unidad_org_2": "COMPENSACIONES",
          "Unid.Organizativa 1": "COMPENSACIONES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA ADMINISTRACION Y FINANZAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 6",
          "count": "16"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO / MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "LOGISTICA DE FARMACIA",
          "Unid.Organizativa 1": "LOGISTICA DE FARMACIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION DE ENFERMERIA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "INFORMES MEDICOS",
          "Unid.Organizativa 1": "INFORMES MEDICOS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UNIDADES ESTRATEGICAS",
          "Unid.Organizativa 1": "SONRISA TOTAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CENTRO OBSTETRICO",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA IMAGENES",
          "Unid.Organizativa 1": "ENFERMERIA IMAGENES",
          "count": "51"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SERVICIOS CLINICOS",
          "unidad_org_2": "LIQUIDACIONES",
          "Unid.Organizativa 1": "LIQUIDACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA DE INGENIERIA CLINICA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE INGENIERIA CLINICA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": "SOLUTION DELIVERY DIGITAL",
          "Unid.Organizativa 1": "SOLUTION DELIVERY DIGITAL",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "GESTION DE REMU. Y ADM. DE PERSONAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GESTION DE REMU. Y ADM. DE PERSONAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "MEJORA CONTINUA DE LA CALIDAD",
          "Unid.Organizativa 1": "MEJORA CONTINUA DE LA CALIDAD",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS URGENCIAS",
          "Unid.Organizativa 1": "MEDICOS URGENCIAS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "PREVENCION Y CONTROL DE INFECCIONES",
          "Unid.Organizativa 1": "PREVENCION Y CONTROL DE INFECCIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CALIDAD",
          "Unid.Organizativa 1": "LABORATORIO CALIDAD",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA UMIV",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIVISION LEGAL Y REGULARIZACION",
          "unidad_org_3": "ASESORIA Y CONTRATOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "ASESORIA Y CONTRATOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "NEONATOLOGIA",
          "count": "23"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA GENERAL",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA GENERAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "EMERGENCIA PEDIATRICA",
          "count": "29"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "PLANEAMIENTO E INFRAESTRUCTURA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PLANEAMIENTO E INFRAESTRUCTURA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "PROYECTOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROYECTOS",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "COMPRAS Y LOGISTICA",
          "unidad_org_2": "ABASTECIMIENTO",
          "Unid.Organizativa 1": "ABASTECIMIENTO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": "NEGOCIACION DE PRODUCTOS",
          "Unid.Organizativa 1": "NEGOCIACION DE PRODUCTOS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA NEGOCIACIONES",
          "Unid.Organizativa 1": "FARMACIA NEGOCIACIONES",
          "count": "11"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "PROYECTOS SERVICIOS CLINICOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROYECTOS SERVICIOS CLINICOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "NEONATOLOGIA",
          "count": "28"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "ADMISION - UNIDADES",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA DE INGENIERIA CLINICA",
          "unidad_org_2": "INGENIERIA CLINICA",
          "Unid.Organizativa 1": "INGENIERIA CLINICA",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "IMPUESTOS",
          "Unid.Organizativa 1": "IMPUESTOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "LIDERAZGO, CULTURA Y COMUNICACIONES",
          "unidad_org_2": "COMUNICACION Y CULTURA",
          "Unid.Organizativa 1": "COMUNICACION Y CULTURA",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FACTURACION Y BACK OFFICE",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CENTRO DE ESTERILIZACION",
          "count": "15"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "ADMISION - LABORATORIO",
          "count": "34"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FACTURACION Y BACK OFFICE",
          "Unid.Organizativa 1": "UME ADMINISTRATIVO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNICO DE ENFERMERIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CONSULTORIO CLINICO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS EMERGENCIA PEDIATRICA",
          "Unid.Organizativa 1": "MEDICOS EMERGENCIA PEDIATRICA",
          "count": "22"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "SALA DE OPERACIONES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE DATA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO CDI",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "121"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CENTRO DE ESTERILIZACION",
          "count": "44"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SERVICIOS CLINICOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SERVICIOS CLINICOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "SERVICIOS NO CLINICOS",
          "Unid.Organizativa 1": "LAVANDERIA",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA DE OPERACIONES",
          "unidad_org_2": "SERVICIOS CLINICOS",
          "Unid.Organizativa 1": "ADMINISTRACION DE LABORATORIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA GENERAL",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA GENERAL",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "SEGURIDAD Y SALUD EN EL TRABAJO",
          "unidad_org_2": "SALUD OCUPACIONAL",
          "Unid.Organizativa 1": "SALUD OCUPACIONAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA CRONICOS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "INFRAESTRUCTURA - LICENCIAS",
          "Unid.Organizativa 1": "INFRAESTRUCTURA - LICENCIAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "DESARROLLO DE NEGOCIO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DESARROLLO DE NEGOCIO",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "GERENCIA DE CANALES Y VENTAS",
          "unidad_org_2": "CONTACT CENTER",
          "Unid.Organizativa 1": "CONTACT CENTER",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 1",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "OPTOMETRIA",
          "count": "13"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "GESTION DE REMU. Y ADM. DE PERSONAL",
          "unidad_org_2": "REMUNERACIONES",
          "Unid.Organizativa 1": "REMUNERACIONES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "ARCHIVO - HC",
          "Unid.Organizativa 1": "ARCHIVO - HC",
          "count": "11"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CONTABILIDAD GENERAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO CDI",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA UMO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "RELACIONAMIENTO DEL NEGOCIO",
          "unidad_org_2": "OCP Y HHMM",
          "Unid.Organizativa 1": "HONORARIOS MEDICOS",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE SERVICE DELIVERY",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "PROYECTOS SERVICIOS CLINICOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROYECTOS SERVICIOS CLINICOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": "DIRECCION MEDICA CORPORATIVA",
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "PEOPLE ANALYTICS Y COMPENSACIONES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PEOPLE ANALYTICS Y COMPENSACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO FABRICA CENTRAL",
          "Unid.Organizativa 1": "LABORATORIO FABRICA CENTRAL",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS SOP",
          "Unid.Organizativa 1": "MEDICOS SOP",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": "CONVENIOS",
          "Unid.Organizativa 1": "CONVENIOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA DE SEDES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA DE SEDES",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "RECLAMOS",
          "Unid.Organizativa 1": "RECLAMOS",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 1",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "PLANNING & PMO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PLANNING & PMO",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFE MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "PREVENCION Y CONTROL DE INFECCIONES",
          "Unid.Organizativa 1": "PREVENCION Y CONTROL DE INFECCIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA DE SEDES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA DE SEDES",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA HOSPITALARIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CENTRO OBSTETRICO",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "INTERNO DE MEDICINA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "INVESTIGACION Y DOCENCIA",
          "Unid.Organizativa 1": "DOCENCIA",
          "count": "24"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 3",
          "count": "17"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA SOP",
          "count": "17"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO FABRICA CENTRAL",
          "Unid.Organizativa 1": "LABORATORIO FABRICA CENTRAL",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "GERENCIA DE CANALES Y VENTAS",
          "unidad_org_2": "CONTACT CENTER",
          "Unid.Organizativa 1": "CONTACT CENTER",
          "count": "153"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "CONTROL DE PROVEEDORES",
          "Unid.Organizativa 1": "CONTROL DE PROVEEDORES",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "PREVENCION Y CONTROL DE INFECCIONES",
          "Unid.Organizativa 1": "PREVENCION Y CONTROL DE INFECCIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CIRUGIA DE DIA",
          "count": "21"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "SERVICIOS NO CLINICOS",
          "Unid.Organizativa 1": "SERVICIOS NO CLINICOS",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNICO DE ENFERMERIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "SALA DE OPERACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO CDI",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "HEMODINAMIA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "ADMISION - HOSPITALARIO",
          "count": "22"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CLINICO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "25"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA ONCOLOGICA",
          "Unid.Organizativa 1": "AMBULATORIO ONCOLOGICO",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO / MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "EMERGENCIA ADULTO",
          "count": "58"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "unidad_org_2": "BRM FRONT OFFICE",
          "Unid.Organizativa 1": "BRM FRONT OFFICE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA ONCOLOGICA",
          "Unid.Organizativa 1": "HOSPITALARIO ONCOLOGICO",
          "count": "29"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA DE OPERACIONES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE OPERACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "MEDICOS A DOMICILIO",
          "Unid.Organizativa 1": "MEDICOS A DOMICILIO",
          "count": "23"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "ADMISION - IMAGENES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SERVICIOS CLINICOS",
          "unidad_org_2": "ADMINISTRACION DE LABORATORIO",
          "Unid.Organizativa 1": "ADMINISTRACION DE LABORATORIO",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": "GERENCIA DE COMERCIAL Y MARKETING",
          "Unid.Organizativa 1": "CONTROL DE GESTION - OPERACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "SONRISA TOTAL",
          "Unid.Organizativa 1": "SONRISA TOTAL",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DATA MEDICA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION DATA MEDICA",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "RELACIONAMIENTO DEL NEGOCIO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "RELACIONAMIENTO DEL NEGOCIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "SALA DE OPERACIONES",
          "count": "57"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE SOLUTION DELIVERY",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "CONTINUIDAD DE NEGOCIO",
          "Unid.Organizativa 1": "CONTINUIDAD DE NEGOCIO",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA JEFE DE ESPECIALIDAD",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA JEFE DE ESPECIALIDAD",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": "SERVICIO DE OPERACIONES",
          "Unid.Organizativa 1": "SOPORTE CDI",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "ENFERMERIA AMBULATORIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA CIRUGIA DIA",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "RELACIONAMIENTO DEL NEGOCIO",
          "unidad_org_2": "OCP Y HHMM",
          "Unid.Organizativa 1": "OFICINA DE CREDENCIALES Y PRIVILEGIOS",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFE MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA JEFE DE ESPECIALIDAD",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA JEFE DE ESPECIALIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "TESORERIA",
          "Unid.Organizativa 1": "TESORERIA - EGRESOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": "SERVICIO DE OPERACIONES",
          "Unid.Organizativa 1": "SERVICIOS DE PLATAFORMA Y APLICACIONES",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICINA FISICA Y REHABILITACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "43"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "SERVICIOS HOSPITALARIOS",
          "count": "37"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SECURITY & CONTROLS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SECURITY & CONTROLS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": "DATA ENGINEERING",
          "Unid.Organizativa 1": "DATA ENGINEERING",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "AUDITORIA EN SALUD",
          "Unid.Organizativa 1": "AUDITORIA EN SALUD",
          "count": "12"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE SERVICE DELIVERY",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "RELACIONAMIENTO DEL NEGOCIO",
          "unidad_org_2": "BUSINESS PARTNER",
          "Unid.Organizativa 1": "BUSINESS PARTNER",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "EMPO",
          "Unid.Organizativa 1": "EMPO",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "AUDITORIA EN SALUD",
          "Unid.Organizativa 1": "AUDITORIA EN SALUD",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "IMAGENES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "PROCESOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROCESOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICINA FISICA Y REHABILITACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "26"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA CORPORATIVA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA EMERGENCIA PEDIATRICA",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "DIVISION LEGAL Y REGULARIZACION",
          "unidad_org_3": "DIVISION LEGAL Y REGULARIZACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIVISION LEGAL Y REGULARIZACION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUB GERENCIA DE EXPERIENCIA AL PACIENTE",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE EXPERIENCIA AL PACIENTE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FACTURACION Y BACK OFFICE",
          "Unid.Organizativa 1": "FACTURACION",
          "count": "90"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "LABORATORIO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": "SERVICIO DE OPERACIONES",
          "Unid.Organizativa 1": "SOPORTE ON-SITE",
          "count": "13"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDAD DE COLUMNA Y MEDULA ESPINAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "UNIDAD DE COLUMNA Y MEDULA ESPINAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "RIESGOS Y CONTOL INTERNO",
          "unidad_org_2": "CONTROL INTERNO",
          "Unid.Organizativa 1": "CONTROL INTERNO",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FACTURACION Y BACK OFFICE",
          "Unid.Organizativa 1": "FACTURACION Y BACK OFFICE",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UME ADMINISTRATIVO",
          "Unid.Organizativa 1": "UME ADMINISTRATIVO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 6",
          "count": "17"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "PEOPLE ANALYTICS Y COMPENSACIONES",
          "unidad_org_2": "PEOPLE ANALYTICS",
          "Unid.Organizativa 1": "PEOPLE ANALYTICS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO / MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "AUDITORIA Y CALIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "MANTENIMIENTO",
          "Unid.Organizativa 1": "MANTENIMIENTO",
          "count": "27"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "ENFERMERIA HOSPITALARIA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "CONTABILIDAD",
          "Unid.Organizativa 1": "CONTABILIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIVISION LEGAL Y REGULARIZACION",
          "unidad_org_3": "CONTENCIOSO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CONTENCIOSO",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": "DATA ANALYTICS",
          "Unid.Organizativa 1": "DATA ANALYTICS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CONSULTORIO CLINICO",
          "count": "200"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "LOGISTICA DE FARMACIA",
          "Unid.Organizativa 1": "LOGISTICA DE FARMACIA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": "PLANEAMIENTO FINANCIERO",
          "Unid.Organizativa 1": "PLANEAMIENTO FINANCIERO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "PREVENCION Y CONTROL DE INFECCIONES",
          "Unid.Organizativa 1": "PREVENCION Y CONTROL DE INFECCIONES",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "nan",
          "unidad_org_2": "INFRAESTRUCTURA",
          "Unid.Organizativa 1": "INFRAESTRUCTURA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE AUDITORIA GENERAL",
          "unidad_org_3": "GERENCIA DE AUDITORIA GENERAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE AUDITORIA GENERAL",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "SEGURIDAD DEL PACIENTE",
          "Unid.Organizativa 1": "SEGURIDAD DEL PACIENTE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA NEGOCIACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 5",
          "count": "14"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "GESTION DE PTO DE VENTAS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA SOP",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO / MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "UNIDADES ESTRATEGICAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": "CONVENIOS",
          "Unid.Organizativa 1": "CONVENIOS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "unidad_org_2": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "Unid.Organizativa 1": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CANALES DIGITALES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA CANALES DIGITALES",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "GESTION DE REMU. Y ADM. DE PERSONAL",
          "unidad_org_2": "ADMINISTRACION DE PERSONAL",
          "Unid.Organizativa 1": "ADMINISTRACION DE PERSONAL",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "UCIPED",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FACTURACION Y BACK OFFICE",
          "Unid.Organizativa 1": "BACK OFFICE FACTURACION",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 2",
          "count": "29"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA UMES & MAD",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": "RENTABILIDAD",
          "Unid.Organizativa 1": "RENTABILIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "unidad_org_2": "ARQUITECTURA DE PLATAFORMA",
          "Unid.Organizativa 1": "ARQUITECTURA DE PLATAFORMA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "CREDITOS Y COBRANZAS",
          "Unid.Organizativa 1": "CREDITOS Y COBRANZAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "DESARROLLO DE NEGOCIO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DESARROLLO DE NEGOCIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "DIRECCION DE ENFERMERIA",
          "Unid.Organizativa 1": "ENFERMERIA IMAGENES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "ATRACCION DEL TALENTO",
          "unidad_org_2": "RECLUTAMIENTO ASISTENCIAL",
          "Unid.Organizativa 1": "RECLUTAMIENTO ASISTENCIAL",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": "PLANEAMIENTO ESTRATEGICO",
          "Unid.Organizativa 1": "PLANEAMIENTO ESTRATEGICO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "GESTION DE PTO DE VENTAS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": "PLANEAMIENTO FINANCIERO",
          "Unid.Organizativa 1": "PLANEAMIENTO FINANCIERO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA HOSPITALARIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUBGERENCIA DE GESTION DE VENTAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUBGERENCIA DE GESTION DE VENTAS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "SALUD OCUPACIONAL",
          "Unid.Organizativa 1": "SALUD OCUPACIONAL",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "GESTION AMBIENTAL",
          "Unid.Organizativa 1": "GESTION AMBIENTAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "ATRACCION DEL TALENTO",
          "unidad_org_2": "CAPACITACION",
          "Unid.Organizativa 1": "CAPACITACION",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CALIDAD",
          "Unid.Organizativa 1": "LABORATORIO CALIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA UMIV",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "UCIN",
          "count": "14"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICINA FISICA Y REHABILITACION",
          "unidad_org_2": "MEDICINA FISICA Y REHABILITACION",
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SERVICIOS CLINICOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SERVICIOS CLINICOS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA CIRUGIA DIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CENTRO ENDOSCOPICO",
          "count": "25"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_2": "CONTABILIDAD GENERAL",
          "Unid.Organizativa 1": "IMPUESTOS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "NEGOCIO DIGITAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "NEGOCIO DIGITAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "GESTION DE PTO DE VENTAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CLINICO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO - NOCTURNO",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CENTRO DE ESTERILIZACION",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "MEJORA CONTINUA DE LA CALIDAD",
          "Unid.Organizativa 1": "MEJORA CONTINUA DE LA CALIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": "GERENCIA GENERAL",
          "Unid.Organizativa 1": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE SOLUTION DELIVERY",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIVISION LEGAL Y REGULARIZACION",
          "unidad_org_3": "CONTENCIOSO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CONTENCIOSO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "RELACIONAMIENTO DEL NEGOCIO",
          "unidad_org_2": "RELACIONES LABORALES",
          "Unid.Organizativa 1": "RELACIONES LABORALES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": "PLANEAMIENTO FINANCIERO",
          "Unid.Organizativa 1": "PLANEAMIENTO FINANCIERO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA UMES & MAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "PLANEAMIENTO E INFRAESTRUCTURA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PLANEAMIENTO E INFRAESTRUCTURA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "RIESGOS Y CONTOL INTERNO",
          "unidad_org_2": "RIESGOS",
          "Unid.Organizativa 1": "RIESGOS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA IMAGENES",
          "Unid.Organizativa 1": "ENFERMERIA IMAGENES",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "PLANNING & PMO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PLANNING & PMO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "SEGURIDAD Y SALUD EN EL TRABAJO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SEGURIDAD Y SALUD EN EL TRABAJO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA ONCOLOGICA",
          "Unid.Organizativa 1": "AMBULATORIO ONCOLOGICO",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA ADMINISTRATIVA",
          "Unid.Organizativa 1": "FARMACIA ADMINISTRATIVA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA DE CANALES Y VENTAS",
          "unidad_org_2": "CONTACT CENTER",
          "Unid.Organizativa 1": "CONTACT CENTER",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_2": "GERENCIA DE CANALES Y VENTAS",
          "Unid.Organizativa 1": "CONTACT CENTER",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA CORPORATIVA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": "DIRECCION MEDICA CORPORATIVA",
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CLINICO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO - NOCTURNO",
          "count": "23"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE NEGOCIO DIGITAL",
          "unidad_org_2": "PLATAFORMAS DIGITALES",
          "Unid.Organizativa 1": "PLATAFORMAS DIGITALES",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS Y ATENCIONES",
          "Unid.Organizativa 1": "MEDICOS EMERGENCIOLOGOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "RELACIONAMIENTO DEL NEGOCIO",
          "unidad_org_2": "BIENESTAR SOCIAL",
          "Unid.Organizativa 1": "BIENESTAR SOCIAL",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "RIESGOS Y CONTOL INTERNO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "RIESGOS Y CONTOL INTERNO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "EMERGENCIA ADULTO",
          "count": "55"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUBGERENCIA DE GESTION DE VENTAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUBGERENCIA DE GESTION DE VENTAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "TESORERIA",
          "Unid.Organizativa 1": "TESORERIA - INGRESOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "DIRECCION DE ENFERMERIA",
          "Unid.Organizativa 1": "ENFERMERIA IMAGENES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "NEGOCIO DIGITAL",
          "unidad_org_2": "OPERACION NEGOCIO DIGITAL",
          "Unid.Organizativa 1": "OPERACION NEGOCIO DIGITAL",
          "count": "21"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CONSULTORIO CLINICO",
          "count": "14"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": "NEGOCIACION DE PRODUCTOS",
          "Unid.Organizativa 1": "NEGOCIACION DE PRODUCTOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA SOP",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_2": "ATRACCION DEL TALENTO",
          "Unid.Organizativa 1": "RECLUTAMIENTO ASISTENCIAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "MARKETING",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MARKETING",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "GESTION DE CONTROL",
          "Unid.Organizativa 1": "PLANEAMIENTO FINANCIERO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "CONTROL DE PROVEEDORES",
          "Unid.Organizativa 1": "CONTROL DE PROVEEDORES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "RECLAMOS",
          "Unid.Organizativa 1": "RECLAMOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS U.C.I. PEDIATRICA",
          "Unid.Organizativa 1": "MEDICOS U.C.I. PEDIATRICA",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FINANZAS",
          "Unid.Organizativa 1": "FINANZAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION DE ENFERMERIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS EMERGENCIOLOGOS",
          "Unid.Organizativa 1": "MEDICOS EMERGENCIOLOGOS",
          "count": "34"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "AREAS COMPLEMENTARIAS",
          "Unid.Organizativa 1": "NUTRICION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DATA MEDICA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION DATA MEDICA",
          "count": "50"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE NEGOCIO DIGITAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE NEGOCIO DIGITAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "LABORATORIO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA CORPORATIVA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 2",
          "count": "19"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFE MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "SEGURIDAD DEL PACIENTE",
          "Unid.Organizativa 1": "SEGURIDAD DEL PACIENTE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "BACK OFFICE",
          "Unid.Organizativa 1": "BACK OFFICE",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "UNIDADES ESTRATEGICAS",
          "Unid.Organizativa 1": "SONRISA TOTAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "GERENCIA DE CANALES Y VENTAS",
          "unidad_org_2": "CONTACT CENTER",
          "Unid.Organizativa 1": "CONTACT CENTER",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA DELIVERY CENTER",
          "count": "21"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "IMPUESTOS",
          "Unid.Organizativa 1": "IMPUESTOS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "LIDERAZGO, CULTURA Y COMUNICACIONES",
          "unidad_org_2": "DESARROLLO ORGANIZACIONAL Y CAPACITACION",
          "Unid.Organizativa 1": "DESARROLLO ORGANIZACIONAL Y CAPACITACION",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA UMO",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO - NOCTURNO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "ATRACCION DEL TALENTO",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "ATRACCION DEL TALENTO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "IMAGENES",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS EMERGENCIA PEDIATRICA",
          "Unid.Organizativa 1": "MEDICOS EMERGENCIA PEDIATRICA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "PROCESOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROCESOS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS U.C.I.",
          "Unid.Organizativa 1": "MEDICOS U.C.I.",
          "count": "35"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFE MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "EMPO",
          "Unid.Organizativa 1": "EMPO ADMINISTRATIVO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SERVICE DELIVERY",
          "unidad_org_2": "SERVICIO DE OPERACIONES",
          "Unid.Organizativa 1": "MESA DE AYUDA",
          "count": "10"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "unidad_org_2": "ARQUITECTURA DE REDES Y TELECOMUNICACION",
          "Unid.Organizativa 1": "ARQUITECTURA DE REDES Y TELECOMUNICACION",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "SONRISA TOTAL",
          "Unid.Organizativa 1": "SONRISA TOTAL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUB GERENCIA DE EXPERIENCIA AL PACIENTE",
          "unidad_org_2": "EXPERIENCIA AL PACIENTE",
          "Unid.Organizativa 1": "EXPERIENCIA AL PACIENTE",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO A DOMICILIO",
          "Unid.Organizativa 1": "LABORATORIO A DOMICILIO",
          "count": "15"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA DE INGENIERIA CLINICA",
          "unidad_org_2": "SOSTENIMIENTO",
          "Unid.Organizativa 1": "SOSTENIMIENTO",
          "count": "16"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "UCI",
          "count": "60"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "PROYECTOS ESTRATEGICOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "PROYECTOS ESTRATEGICOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE SERVICIOS",
          "Unid.Organizativa 1": "GESTION DE SERVICIOS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "EMERGENCIA PEDIATRICA",
          "count": "26"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE SERVICIOS",
          "Unid.Organizativa 1": "ADMISION - AMBULATORIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UNIDAD DE MEDICINA PREVENTIVA",
          "Unid.Organizativa 1": "UME ADMINISTRATIVO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "INVESTIGACION Y DOCENCIA",
          "Unid.Organizativa 1": "DOCENCIA",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "DIRECCION MEDICA CORPORATIVA",
          "Unid.Organizativa 1": "DIRECCION MEDICA JEFE DE ESPECIALIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "CONTROL DE PROVEEDORES",
          "Unid.Organizativa 1": "CONTROL DE PROVEEDORES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "unidad_org_2": "BRM BACK OFICCE",
          "Unid.Organizativa 1": "BRM BACK OFICCE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA DE INGENIERIA CLINICA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE INGENIERIA CLINICA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "DIRECCION DE UNID ESTRATEGICA DE NEGOCIO",
          "Unid.Organizativa 1": "DIRECCION DE UNID ESTRATEGICA DE NEGOCIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "FINANZAS",
          "Unid.Organizativa 1": "FINANZAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE DATA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "AREAS COMPLEMENTARIAS",
          "Unid.Organizativa 1": "SERVICIOS HOSPITALARIOS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "MARKETING",
          "unidad_org_2": "MARKETING",
          "Unid.Organizativa 1": "MARKETING",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "CREDITOS Y COBRANZAS",
          "Unid.Organizativa 1": "CREDITOS Y COBRANZAS",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO A DOMICILIO",
          "Unid.Organizativa 1": "LABORATORIO A DOMICILIO",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "EMPO",
          "Unid.Organizativa 1": "EMPO ADMINISTRATIVO",
          "count": "19"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA HOSPITALARIA",
          "count": "75"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA ONCOLOGICA",
          "Unid.Organizativa 1": "HOSPITALARIO ONCOLOGICO",
          "count": "20"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS CON ESP (e)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICINA FISICA Y REHABILITACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA CORPORATIVA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA DE ENTERPRISE ARCHITECTURE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "GESTION DE REMU. Y ADM. DE PERSONAL",
          "unidad_org_2": "ADMINISTRACION DE PERSONAL",
          "Unid.Organizativa 1": "ADMINISTRACION DE PERSONAL",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "LOGISTICA DE FARMACIA",
          "Unid.Organizativa 1": "LOGISTICA DE FARMACIA",
          "count": "15"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "LOGISTICA DE FARMACIA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO / MÉDICO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "IMAGENES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "RIESGOS Y CONTOL INTERNO",
          "unidad_org_2": "CUMPLIMIENTO",
          "Unid.Organizativa 1": "CUMPLIMIENTO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "DIRECCION DE ENFERMERIA",
          "Unid.Organizativa 1": "ENFERMERIA IMAGENES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "MANTENIMIENTO",
          "Unid.Organizativa 1": "EQUIPAMIENTO",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA UMIV",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICINA FISICA Y REHABILITACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "8"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "GESTION DE CONTROL",
          "Unid.Organizativa 1": "GESTION DE CONTROL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICINA FISICA Y REHABILITACION",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA OPERACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UNIDAD DE MEDICINA PREVENTIVA",
          "Unid.Organizativa 1": "UNIDAD DE MEDICINA PREVENTIVA",
          "count": "9"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "UNIDADES ESTRATEGICAS",
          "Unid.Organizativa 1": "UNIDAD DE MEDICINA PREVENTIVA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "ATRACCION DEL TALENTO",
          "unidad_org_2": "RECLUTAMIENTO ADMINISTRATIVO",
          "Unid.Organizativa 1": "RECLUTAMIENTO ADMINISTRATIVO",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SECURITY & CONTROLS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SECURITY & CONTROLS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "OBSTETRIZ",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CENTRO OBSTETRICO",
          "count": "24"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": "SOLUTION DELIVERY PRESTACIONAL",
          "Unid.Organizativa 1": "SOLUTION DELIVERY PRESTACIONAL",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS HOSPITALARIOS",
          "Unid.Organizativa 1": "MEDICOS HOSPITALARIOS",
          "count": "42"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "ENFERMERIA HOSPITALARIA",
          "count": "13"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA DE INGENIERIA CLINICA",
          "unidad_org_2": "EFICIENCIA",
          "Unid.Organizativa 1": "EFICIENCIA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA ONCOLOGICA",
          "Unid.Organizativa 1": "HOSPITALARIO ONCOLOGICO PEDIATRICA",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "PRACTICANTE",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA NO CRITICA",
          "Unid.Organizativa 1": "SUB DIRECCION DE ENFERMERIA",
          "count": "14"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "IMAGENES",
          "unidad_org_2": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "Unid.Organizativa 1": "CENTRO DE DIAGNOSTICO POR IMAGENES ADMIN",
          "count": "78"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CLINICO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "91"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA DERMOCENTER",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA DELIVERY CENTER",
          "count": "19"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUB GERENCIA DE EXPERIENCIA AL PACIENTE",
          "unidad_org_2": "EXPERIENCIA AL PACIENTE",
          "Unid.Organizativa 1": "EXPERIENCIA AL PACIENTE",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "Unid.Organizativa 1": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "ADMISION - AMBULATORIO",
          "count": "239"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "LABORATORIO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "SONRISA TOTAL",
          "Unid.Organizativa 1": "SONRISA TOTAL",
          "count": "25"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CENTRO ENDOSCOPICO",
          "count": "24"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO SIN ESP  (d)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": "SEGURIDAD DEL PACIENTE",
          "Unid.Organizativa 1": "SEGURIDAD DEL PACIENTE",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AUDITORIA Y CALIDAD",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "AUDITORIA Y CALIDAD",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUBGERENCIA DE GESTION DE VENTAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUBGERENCIA DE GESTION DE VENTAS",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA DE SEDES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION MEDICA DE SEDES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO FABRICA CENTRAL",
          "Unid.Organizativa 1": "LABORATORIO FABRICA CENTRAL",
          "count": "15"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": "DATA ANALYTICS",
          "Unid.Organizativa 1": "DATA ANALYTICS",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TECNÓLOGO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "GERENCIA GENERAL",
          "unidad_org_2": "DIRECCION MEDICA CORPORATIVA",
          "Unid.Organizativa 1": "MEDICINA FISICA Y REHABILITACION",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "SUB GERENCIA DE EXPERIENCIA AL PACIENTE",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE EXPERIENCIA AL PACIENTE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "RELACIONAMIENTO DE FINANCIADORAS Y PRODU",
          "unidad_org_2": "RENTABILIDAD",
          "Unid.Organizativa 1": "RENTABILIDAD",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "INVESTIGACION Y DOCENCIA",
          "Unid.Organizativa 1": "INVESTIGACION",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA DOSIS UNITARIA",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UNIDAD DE MEDICINA PREVENTIVA",
          "Unid.Organizativa 1": "UNIDAD DE MEDICINA PREVENTIVA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "TESORERIA",
          "Unid.Organizativa 1": "TESORERIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": "PLANEAMIENTO ESTRATEGICO",
          "Unid.Organizativa 1": "PLANEAMIENTO ESTRATEGICO",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "UCI",
          "count": "36"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE PLANEA. Y CONTROL FINAN",
          "unidad_org_2": "PLANEAMIENTO FINANCIERO",
          "Unid.Organizativa 1": "FINANZAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "LIDERAZGO, CULTURA Y COMUNICACIONES",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "LIDERAZGO, CULTURA Y COMUNICACIONES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "CENTRO DE EXCELENCIA DE ONCOLOGIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": "DATA CONSUMPTION",
          "Unid.Organizativa 1": "DATA CONSUMPTION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "HOSPITALARIO 4",
          "count": "17"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA OPTIMIZACION DE PROCESOS",
          "Unid.Organizativa 1": "ENFERMERIA OPTIMIZACION DE PROCESOS",
          "count": "6"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "UNIDAD DE MEDICINA PREVENTIVA",
          "Unid.Organizativa 1": "UNIDAD DE MEDICINA PREVENTIVA",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_2": "GERENCIA DE NEGOCIO DIGITAL",
          "Unid.Organizativa 1": "PLATAFORMAS DIGITALES",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE DATA",
          "unidad_org_2": "DATA GOVERNANCE",
          "Unid.Organizativa 1": "DATA GOVERNANCE",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "CIRUGIA DE DIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE DESARROLLO DE NEGOCIOS",
          "unidad_org_3": "DESARROLLO DE CUENTAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DESARROLLO DE CUENTAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO SIN ESP  (b)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "DIRECCION DE ENFERMERIA",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": "SOLUTION DELIVERY DIGITAL",
          "Unid.Organizativa 1": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE GESTION DE PERSONAS",
          "unidad_org_3": "SEGURIDAD Y SALUD EN EL TRABAJO",
          "unidad_org_2": "SEGURIDAD EN EL TRABAJO",
          "Unid.Organizativa 1": "SEGURIDAD EN EL TRABAJO",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": "SOLUTION DELIVERY ADMINISTRATIVOS",
          "Unid.Organizativa 1": "SOLUTION DELIVERY ADMINISTRATIVOS",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "GERENCIA GEST.INT. DE PTO DE VENTAS",
          "unidad_org_2": "GESTION DE PTO DE VENTAS",
          "Unid.Organizativa 1": "ADMISION - EMERGENCIA",
          "count": "46"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "CONTABILIDAD GENERAL",
          "unidad_org_2": "GESTION DE CONTROL",
          "Unid.Organizativa 1": "GESTION DE CONTROL",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "MEDICOS Y ATENCIONES",
          "unidad_org_2": "MEDICOS RESIDENTES",
          "Unid.Organizativa 1": "MEDICOS RESIDENTES",
          "count": "31"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "QUÍMICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA AMBULATORIA",
          "Unid.Organizativa 1": "FARMACIA DELIVERY CENTER",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CLINICO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO CLINICO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_2": null,
          "Unid.Organizativa 1": null,
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": "TESORERIA",
          "Unid.Organizativa 1": "TESORERIA - EGRESOS",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "FARMACIA HOSPITALARIA",
          "Unid.Organizativa 1": "FARMACIA CIRUGIA DIA",
          "count": "2"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "MEDICOS A DOMICILIO",
          "Unid.Organizativa 1": "MEDICOS A DOMICILIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE SOLUTION DELIVERY",
          "unidad_org_2": "SOLUTION DELIVERY DIGITAL",
          "Unid.Organizativa 1": "SISTEMAS LEGADOS - SATELITES",
          "count": "5"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EJECUTIVO",
          "gerencia": "GERENCIA ADMINISTRACION Y FINANZAS",
          "unidad_org_3": "SUB GERENCIA DE FINANZAS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "SUB GERENCIA DE FINANZAS",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "NUTRICIONISTA",
          "gerencia": "GERENCIA DE OPERACIONES",
          "unidad_org_3": "SUB GERENCIA OPERACIONES",
          "unidad_org_2": "SERVICIO DE NUTRICION",
          "Unid.Organizativa 1": "SERVICIO DE NUTRICION",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "MÉDICOS SIN ESP (f)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "UNIDADES ESTRATEGICAS",
          "unidad_org_2": "EMPO",
          "Unid.Organizativa 1": "EMPO",
          "count": "13"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE PROYECTOS E INFRAESTRUCTURA",
          "unidad_org_3": "ARQUITECTURA DE PROYECTOS",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "ARQUITECTURA DE PROYECTOS",
          "count": "4"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "CIRUGIA DE DIA",
          "count": "16"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO HOSPITALARIO CON ESP (a)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA HOSPITALARIA",
          "Unid.Organizativa 1": "UCIN",
          "count": "7"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "JEFATURAS",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "FARMACIA",
          "unidad_org_2": "LOGISTICA DE FARMACIA",
          "Unid.Organizativa 1": "LOGISTICA DE FARMACIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO ASISTENCIAL",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "LABORATORIO",
          "unidad_org_2": "LABORATORIO",
          "Unid.Organizativa 1": "LABORATORIO CLINICO",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "TÉCNICO DE FARMACIA",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_2": "FARMACIA",
          "Unid.Organizativa 1": "FARMACIA AMBULATORIA",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "ENFERMERO AMBULATORIO CON ESP (c)",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "DIRECCION DE ENFERMERIA",
          "unidad_org_2": "ENFERMERIA AMBULATORIA",
          "Unid.Organizativa 1": "INMUNIZACIONES",
          "count": "10"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE COMERCIAL Y MARKETING",
          "unidad_org_3": "NEGOCIO DIGITAL",
          "unidad_org_2": null,
          "Unid.Organizativa 1": "NEGOCIO DIGITAL",
          "count": "3"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "DIRECCION MEDICA CORPORATIVA",
          "unidad_org_3": "AREAS COMPLEMENTARIAS",
          "unidad_org_2": "INVESTIGACION Y DOCENCIA",
          "Unid.Organizativa 1": "ADMISION - AMBULATORIO",
          "count": "1"
      },
      {
          "medico_perfil": "ASISTENCIAL",
          "PUESTO ARCHIVO": "EMPLEADO",
          "gerencia": "GERENCIA DE TECNOLOGIA DE LA INFORMACION",
          "unidad_org_3": "SUB GERENCIA DE BRM & DIGIT INNOVATION",
          "unidad_org_2": "BRM MIDDLE OFFICE",
          "Unid.Organizativa 1": "BRM MIDDLE OFFICE",
          "count": "1"
      }
  ]


export default array_asistencial;