import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
/**TABLE */
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGrid,
  GridToolbar,
  esES,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { Etiqueta } from "./utils";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

function ModalBajasMasivas({ data, show, onHide }) {
  console.log("====================================");
  console.log(data);
  console.log("====================================");

  const [loggedUser, setLoggedUser] = useState(null);
  const [validated, setValidated] = useState(false);

  // useEffect para obtener el usuario logueado
  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      let user = JSON.parse(localStorage.getItem("user"));
      setLoggedUser(user);
    }
  }, []);

  const handleProcesarBajas = async () => {
    const dataOnly = data?.data?.data_details.filter((item) => {
      return item.detalle === "PARA INACTIVAR";
    });

    console.log(dataOnly);

    try {
      //si no hay nada para inactivar no se hace nada y solo se cierra el modal
      if (dataOnly.length === 0) {
        Swal.fire({
          icon: "info",
          title: "No hay registros para inactivar",
          showConfirmButton: false,
          confirmButtonColor: "#329fe7",
        });
        onHide();
        return;
      } else {
        let confir_down = await axios.post(
          "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/carga-masiva-bajas-confirmacion",
          {
            data_send: dataOnly,
            perfil_send: data?.perfil,
            tipoCarga_send: data?.tipoCarga,
            usuario_send: loggedUser.name,
          }
        );

        console.log(confir_down);

        Swal.fire({
          icon: "success",
          title: `${loggedUser?.name}`,
          html: `<p>Se inactivara ${dataOnly.length} de ${data?.data?.data_details.length} registros</p>`,
          showConfirmButton: true,
          confirmButtonColor: "#329fe7",
        });
        onHide();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error al realizar las bajas",
        showConfirmButton: false,
        confirmButtonColor: "#dc3545",
      });
    }
  };

  //tema de la tabla español
  const theme = createTheme(
    {
      palette: {
        primary: { main: "#329fe7" },
      },
    },
    esES
  );

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Bajas Masivas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label className="text-muted">
                <strong>Resumen: </strong>
              </Form.Label>
            </Col>
          </Row>
          {/* Tabla de resumen */}
          <Row>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>Perfil: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.perfil}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>Accion: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.tipoCarga}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          {/* Tabla de resumen */}
          <Row>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>Total de registros: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.data?.count_total}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>Para inactivar: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.data?.count_para_inactivar}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>Ya se encuentran inactivos: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.data?.count_ya_inactivos}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>No se encontraron: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.data?.count_no_encontrados}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Label className="text-muted">
                  <strong>Estan de licencia: </strong>
                </Form.Label>
                <Form.Text className="text-muted ml-2">
                  {" "}
                  {data?.data?.count_en_licencia}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <hr />
          {/* Tabla de detalle */}
          <Row>
            <Col>
              <Form.Label>Tabla de datos: </Form.Label>
            </Col>
          </Row>

          <Row>
            <Col>
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  bgcolor: "background.paper",
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    slots={{ toolbar: CustomToolbar }}
                    rows={data?.data?.data_details}
                    columns={[
                      { field: "id", headerName: "#", width: 70 },
                      { field: "T.Doc.", headerName: "T.Doc.", width: 100 },
                      {
                        field: "N°Documento",
                        headerName: "N°Documento",
                        width: 150,
                      },
                      {
                        field: "Apellidos y Nombres",
                        headerName: "Apellidos y Nombres",
                        width: 250,
                      },
                      {
                        field: "Situacion",
                        headerName: "Situacion",
                        width: 150,
                      },
                      {
                        field: "Criticidad",
                        headerName: "Criticidad",
                        width: 150,
                      },
                      {
                        field: "detalle",
                        headerName: "Detalle",
                        width: 250,
                        renderCell: (params) => {
                          return (
                            <div>
                              <Etiqueta estado={params.value} />
                            </div>
                          );
                        },
                      },
                    ]}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </ThemeProvider>
              </Box>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleProcesarBajas}>
            Procesar Bajas
          </Button>
          <Button variant="secondary" onClick={onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalBajasMasivas;
