import { useEffect } from "react";

const ProcessLoading = ({ label }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Se están procesando un archivo. ¿Seguro que deseas salir?';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (!label) return;
  return ("")
}

export default ProcessLoading;





