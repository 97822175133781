import React, { useEffect, useState } from 'react'
import { Button, Container, Form, InputGroup, Modal, Row, Col, Badge } from 'react-bootstrap'
import MenuPrincipal from '../components/navbar'
import { useParams, useNavigate } from 'react-router-dom';
//import documentService from '../services/documentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Swal from "sweetalert2";
import moment from 'moment/moment';
import axios from 'axios';
import VisorDocumentosModal from './visorDocumentoModal';
import { verificarAcceso } from "../components/utils";

/**TABLE */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
/**TABLE */

const filterBy = (bool, cr, data) =>
  bool ? data : data.filter((item) => item.status_archivo === cr);

function ListaDocumentos() {
  const MAX_FILE_SIZE = 100 * 1024 * 1024;
  const TIEMPO_ESPERA = 40 * 60 * 60 * 1000
  const EXT_NEW_TAB = ["PDF", "XLS", "XLSX", "DOC", "DOCX", "PPT", "PPTX"];
  const { idReg } = useParams();
  const [dataPersonal, setDataPersonal] = useState()
  const [dataArchivos, setDataArchivos] = useState()
  const [TArchivos, setTArchivos] = useState([])
  const [archivosNoid, setArchivosNoid] = useState()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowUploadModal, setIsShowUploadModal] = useState(false)
  const [refFile, setRefFile] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()
  const [accesosAdicionales, setAccesosAdicionales] = useState({})
  //const [regViewing, setRegViewing] = useState(0)

  const [fileIdx, setFileIdx] = useState(0)
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const navigate = useNavigate();
  //const [tipoDocOptions, setTipoDocOptions] = useState()

  /**table */
  const [viewInfo, setViewInfo] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(100);
  /** */
  const [tabSelected, setTabSelected] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  /**Notificacion X Revisar */
  const [isRegPendRev, setIsRegPendRev] = useState(false)

  const [pdftime, setPdfTime] = useState(false)
  /** */

  /**Filter and sorting */
  //const [filterTable, setFilterTable] = useState()
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("estado")
  const [urlVisitadas, setUrlVisitadas] = useState([])
  /** */

  /**filtros */
  const [soloCamp, setSoloCamp] = useState(false)
  const [dataArchivosFiltrado, setDataArchivosFiltrado] = useState()
  const [archivosNoidFiltrado, setArchivosNoidFiltrado] = useState()
  /** */

  const columns = [
    { id: 'Revisado', label: 'Rev.', minWidth: "4vw", align: "center" },
    { id: 'cod', label: 'Cod.', minWidth: "2vw" },
    { id: 'fuente', label: 'Fuente', minWidth: "2vw" },
    { id: 'documento_requerido', label: 'Documento', minWidth: "11vw" },
    { id: 'pagina', label: 'Página', minWidth: "6vw", align: "center" },
    { id: 'estado', label: 'Estado', minWidth: "10.25vw", align: "center" }
  ];

  const columns2 = [
    { id: 'documento_requerido', label: 'Documento', minWidth: 70, align: "left" }
  ];

  /**tabla */
  const handleChangePage = (event, newPage, ord) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, ord) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage2 = (event, newPage, ord) => {
    setPage2(newPage)
  };

  const handleChangeRowsPerPage2 = (event, ord) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const compare = (a, b) => {
    const pa = a.status_archivo.toUpperCase()
    const pb = b.status_archivo.toUpperCase()
    if (pa < pb) {
      return order === "desc" ? -1 : 1
    }
    if (pa > pb) {
      return order === "desc" ? 1 : -1
    }
    return 0
  }

  const sortTable = () => {
    setOrder(order === "asc" ? "desc" : "asc")
    dataArchivos.sort(compare)
  }
  /** */

  const handleTabs = (event, newValue) => {
    setTimeout(() => { setPdfTime(false); setRefFile(null); setTabSelected(newValue); }, 180);
  };

  const getName = (l) => {
    if (l.length < 6) return
    let arr = l.split("/");
    return arr[arr.length - 1];
  }

  const getUri = (l) => {
    if (l.length < 6) return
    let arr = ""
    if (l.includes("s3://clinica-internacional-2023/")) arr = l.split("s3://clinica-internacional-2023/")
    else arr = l.split("s3://ci-gestor-documental-2023/")
    return arr[arr.length - 1];
  }

  const getLabelTA = (l, arr) => {
    let idx = arr.findIndex((e) => { return e.value === l })
    if (idx < 0) return { label: l, exp: false }
    else return { label: arr[idx].label, exp: arr[idx].exp }
  }

  /**
   * @description Obtiene la fuenta de carga de un archivo.
   * @argument fl Objeto File
   * @return Inicial | Campaña | otros 
   */
  const getFuente = (fl) => {
    if (fl.observacion === 'auto-verificado') return 'automatico'
    else if (fl.fuente_carga) {
      return fl.fuente_carga
    } else if (fl.ruta_origen) {
      if (fl.ruta_origen?.includes("s3://ci-gestor-documental-2023/")) return "campaña"
      else return "inicial"
    }
    return ""
  }

  useEffect(() => {
    if (!selectedFile) {
      setPreview(null);
      return;
    }
    const urlAux = URL.createObjectURL(selectedFile);
    setPreview(urlAux);
    return () => URL.revokeObjectURL(urlAux);
  }, [selectedFile]);

  useEffect(() => {
    if (dataArchivos?.length > 0) setDataArchivosFiltrado(filterBy(!soloCamp, "POR REVISAR", dataArchivos));
    if (archivosNoid?.length > 0) setArchivosNoidFiltrado(filterBy(!soloCamp, "POR REVISAR", archivosNoid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soloCamp]);

  /**Requests*/
  const getArchId = async () => {
    try {
      const res1 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/info-by-tipo-nro-doc", JSON.stringify({ "ci_is_null": "false", "ci_nro_doc": idReg }))
      return res1.data;
    } catch (error) {
      throw error;
    }
  }
  const getArchNoId = async () => {
    try {
      const res3 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/info-by-tipo-nro-doc", JSON.stringify({ "ci_is_null": "true", "ci_nro_doc": idReg }))
      return res3.data;
    } catch (error) {
      throw error;
    }
  }
  const getArchTypes = async () => {
    try {
      let res0 = null
      if (idReg === "revision") res0 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/puesto-archivo-by-medico", JSON.stringify({ "ci_nro_documento": "99999999" }))
      else res0 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/puesto-archivo-by-medico", JSON.stringify({ "ci_nro_documento": idReg }))
      return res0.data;
    } catch (error) {
      throw error;
    }
  }
  const getMedicos = async () => {
    try {
      const res2 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/medicos", JSON.stringify({ ci_valor: idReg }))
      return res2.data;
    } catch (error) {
      throw error;
    }
  }

  const getPorRevisar = async () => {
    try {
      const res2 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/get-Revisiones", {});
      return res2.data;
    } catch (error) {
      throw error;
    }
  }

  const getPorRevisarNotif = async () => {
    try {
      const res2 = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/get-Revisione-init", {});
      return res2.data;
    } catch (error) {
      throw error;
    }
  }
  /***/

  const refreshData = async () => {
    try {
      if (idReg !== "revision") {
        const [dArchivo, dArchivosNoId, dRevisarNotif] = await Promise.all([
          getArchId(),
          getArchNoId(),
          getPorRevisarNotif()
        ]);
        if (dArchivo?.data?.count > 0) {
          setDataArchivos(dArchivo?.data?.data)
          setDataArchivosFiltrado(filterBy(!soloCamp, "POR REVISAR", dArchivo?.data?.data));
        } else { setDataArchivos([]); setDataArchivosFiltrado([]) }
        if (dArchivosNoId?.data?.count > 0) {
          setArchivosNoid(dArchivosNoId?.data?.data);
          setArchivosNoidFiltrado(filterBy(!soloCamp, "POR REVISAR", dArchivosNoId?.data?.data))
        } else { setArchivosNoid([]); setArchivosNoidFiltrado([]) }
        if (dRevisarNotif?.data?.count > 0) {
          setIsRegPendRev(true);
        } else setIsRegPendRev(false);
      } else {
        const [dArchivo, dRevisarNotif] = await Promise.all([
          getPorRevisar(),
          getPorRevisarNotif()
        ]);
        if (dArchivo?.data?.count > 0) {
          setDataArchivos(dArchivo?.data?.data)
          setDataArchivosFiltrado(dArchivo?.data?.data)
        } else { setDataArchivos([]); setDataArchivosFiltrado([]) }
        if (dRevisarNotif?.data?.count > 0) {
          setIsRegPendRev(true);
        } else setIsRegPendRev(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const capitalize = (str) => {
    if (!str) return false
    if (str.length < 2) return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  useEffect(() => {
    if (idReg) {
      (async () => {
        try {
          let accesos = await verificarAcceso(user.ext)
          if (user.rol !== 1 && !accesos?.adm && !accesos?.v1) {
            navigate("/", { replace: true });
            return
          }
          setAccesosAdicionales(accesos)
          if (idReg === "revision") {
            const [dTArchivos, dArchivo] = await Promise.all([
              getArchTypes(),
              getPorRevisar()
            ]);
            if (dTArchivos?.data?.count > 0) {
              let arr = dTArchivos?.data?.data.map(opt => ({ label: opt.nombre_archivo, value: opt.codigo_archivo, exp: opt.seguimiento_vigencia }));
              setTArchivos(arr);
            }
            if (dArchivo?.data?.count > 0) {
              setDataArchivos(dArchivo?.data?.data)
              setDataArchivosFiltrado(dArchivo?.data?.data)
            }
          } else {
            let idTab = 0;
            const [dTArchivos, dArchivo, dPersonal, dArchivosNoId, dRevisarNotif] = await Promise.all([
              getArchTypes(),
              getArchId(),
              getMedicos(),
              getArchNoId(),
              getPorRevisarNotif()
            ]);
            if (dTArchivos?.data?.count > 0) {
              let arr = dTArchivos?.data?.data.map(opt => ({ label: opt.nombre_archivo, value: opt.codigo_archivo, exp: opt.seguimiento_vigencia }));
              setTArchivos(arr);
            }
            if (dArchivo?.data?.count > 0) {
              let filtered = dArchivo?.data?.data?.filter(objeto => objeto.ruta_origen !== "null" && objeto.ruta_origen !== null);
              setDataArchivos(filtered)
              setDataArchivosFiltrado(filtered);
            } else idTab = 1
            if (dPersonal?.data?.count > 0) {
              setDataPersonal(dPersonal?.data?.data[0]);
              //console.log(dPersonal?.data?.data[0])
            }
            if (dArchivosNoId?.data?.count > 0) {
              let filtered2 = dArchivosNoId?.data?.data?.filter(objeto => objeto.ruta_origen !== "null" && objeto.ruta_origen !== null);
              setArchivosNoid(filtered2);
              setArchivosNoidFiltrado(filtered2)
            } else idTab = 0

            if (dRevisarNotif?.data?.count > 0) {
              setIsRegPendRev(true);
            } else setIsRegPendRev(false);
            setTabSelected(idTab)
          }
          setIsPageLoading(false)
        } catch (error) {
          console.log(error)
          setIsPageLoading(false)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        Swal.fire({
          icon: "info",
          title: "Archivo demasiado grande",
          text: "El archivo seleccionado supera el tamaño máximo permitido (100 MB).",
        });
      } else {
        setSelectedFile(selectedFile);
      }
    }
  };

  /*const showUploadModal = () => {
    setIsShowUploadModal(true)
  }*/

  const closeUploadModal = () => {
    setPreview(null)
    setSelectedFile(null);
    setIsShowUploadModal(false)
  }

  const uploadFile = () => {
    setIsLoading(true)
    setIsShowUploadModal(false)
    setIsLoading(false)
  }

  const closeVisor = async (onlyclose) => {
    setRefFile(null)
    if (onlyclose) {
      const dRevisarNotif = await getPorRevisarNotif()
      if (dRevisarNotif?.data?.count > 0) {
        setIsRegPendRev(true);
      } else setIsRegPendRev(false);
    }
    //await releaseAccess()
    setPdfTime(false)
  }

  const getFromS3 = async (uri, mode) => {
    try {
      let response = null
      if (mode === 1) response = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/url-prefirmada", JSON.stringify({ "key": uri }))
      else response = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/url-prefirmada-2", JSON.stringify({ "key": uri }))
      let dS3 = response.data;
      if (dS3?.data?.count > 0) {
        return dS3?.data?.data;
      }
      return false
    } catch (error) {
      console.log(error)
      return false
    }
  }

  /*const checkAccess = async (idReg) => {
    try {
      setRegViewing(idReg)
      const response = await axios.post(process.env.REACT_APP_AP+"persona/useDocument", { idReg, user: user.ext });
      if (response?.status == 200) return true;
      return false;
    } catch (error) {
      if (error?.response?.status == 403) return false;
      console.error(error);
      return false;
    }
  }

  const releaseAccess = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_AP+"persona/leaveDocument", { idReg: regViewing, user: user.ext });
    } catch (error) {
      console.error(error);
    }
  };*/

  const openVisor = async (input) => {
    setPdfTime(false)
    setIsPageLoading(true)
    /*let ca = await checkAccess(file.id_registro)
    if (!ca) {
      Swal.fire({
        icon: "info",
        text: "En uso",
      });
      setIsPageLoading(false)
      return
    };*/
    let file = input
    let url = [...urlVisitadas]
    file.uri = getUri(file.ruta_origen)
    file.new = false
    if (file.uri.length < 6) return
    let i = url.findIndex((e) => e.id_registro === file.id_registro)
    let tT = 0;
    if (i > -1) tT = Date.now() - url[i].time;
    if (tT === 0 || tT > TIEMPO_ESPERA) {
      if (file.ruta_origen.includes("s3://clinica-internacional-2023/")) file.url = await getFromS3(file.uri, 1)
      else { file.url = await getFromS3(file.uri, 2); file.new = true }
      if (!file.url) return
      let arr = file.uri.split(".")
      let st = arr[arr.length - 1]
      file.tp = st.toUpperCase()
      file.name = file.nombre_archivo || getName(file.ruta_origen)
      file.time = Date.now()
      if (i > -1) url.splice(i, 1);
      url.push(file)
      setUrlVisitadas(url)
    } else { file.url = url[i].url; file.new = url[i].new; file.tp = url[i].tp; file.name = url[i].name; file.time = url[i].time; }
    let aux = getLabelTA(file.cod_tipo_doc, TArchivos)
    file.nomTDoc = aux.label
    file.vigencia_archivo = file.vigencia_archivo || aux.exp
    setRefFile(file)
    setPdfTime(true)
    setFileIdx(file.id_registro)
    setIsPageLoading(false)
  }

  return (
    <>
      <MenuPrincipal isLoading={isPageLoading} notifPendientes={isRegPendRev} accesos={accesosAdicionales} />
      <Container className='page-content'>
        {dataPersonal && <Row>
          <Row xs={"auto"} className='align-items-center'>
            <Col><h6 style={{ marginBottom: 0 }}>{dataPersonal["Apellidos y Nombres"]}</h6></Col>
            <Col><Button onClick={() => { setViewInfo(!viewInfo) }} size='sm' style={{ maxWidth: 70, fontSize: ".8rem" }} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}><FontAwesomeIcon icon={viewInfo ? faEyeSlash : faEye} /></Button></Col>
            {idReg !== "revision" && <Col><Button onClick={() => { setSoloCamp(!soloCamp) }} size='sm' style={{ fontSize: ".8rem" }}><span style={{ fontWeight: 500 }}>{!soloCamp ? "Solo Campaña" : "Mostrar Todo"}</span></Button></Col>}
          </Row>
          <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none' }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={handlePopoverClose} disableRestoreFocus style={{ maxWidth: "60vw" }} >
            <>
              <Row>
                <Col><p><b>Documento:</b> {dataPersonal["T.Doc."]} {dataPersonal["N°Documento"]}</p></Col>
                <Col><p><b>Puesto:</b> {dataPersonal["Desc. Posición"]}</p></Col>
              </Row>
              <Row>
                <Col><p><b>F. Ingreso:</b> {dataPersonal["Fec. Ingreso"] ? moment(dataPersonal["Fec. Ingreso"]).format("DD/MM/YYYY") : ""}</p></Col>
                <Col><p><b>Situación:</b> {dataPersonal["Situación"] || "Activo"}</p></Col>
              </Row>
              <Row><Col><p><b>Archivo:</b> {dataPersonal["PUESTO ARCHIVO"]}</p></Col></Row>
            </>
          </Popover>

        </Row>}
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} className="">
          <Tabs value={tabSelected} onChange={handleTabs} centered>
            {dataArchivos && dataArchivos.length > 0 && <Tab value={0} label={"Documentos identificados (" + (dataArchivos?.length || 0) + ")"} />}
            {archivosNoid && archivosNoid.length > 0 && <Tab value={1} label={"Documentos no identificados (" + (archivosNoid?.length || 0) + ")"} />}
          </Tabs>
        </Box>
        {tabSelected === 0 && dataArchivos && dataArchivos.length > 0 && <>
          <Row className="mt-2">
            <Paper sx={{ width: '50%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: "calc(100vh - 240px)" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth, fontWeight: 600 }}
                        >
                          {column.id === "estado" ? <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={() => sortTable()}
                            disabled={orderBy !== column.id}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'Descendente' : 'Ascendente'}
                              </Box>
                            ) : null}
                          </TableSortLabel> : <>{column.label}</>}

                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataArchivosFiltrado
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((file, idx) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={idx} onClick={() => { openVisor(file) }} className={fileIdx === file.id_registro ? "file-selected" : ""}>
                            <TableCell key={"#"} align={"center"}>
                              <Form.Check type={"checkbox"} checked={file.id_usuario && file.id_usuario !== ""} id={"check-" + idx} disabled className='revs' />
                            </TableCell>
                            <TableCell key={"CA"} align={"left"}><b>{file.cod_tipo_doc || ""}</b></TableCell>
                            <TableCell key={"FA"} align={"left"}><b>{getFuente(file) || ""}</b></TableCell>
                            <TableCell key={"A"} align={"left"}>
                              {file.nombre_archivo || getName(file.ruta_origen)}
                              {/*getLabelTA(file.cod_tipo_doc, TArchivos).label*/}
                            </TableCell>
                            <TableCell key={"C"} align={"center"}>
                              {!file.pagina || file.pagina === "" ? "1" : file.pagina}
                            </TableCell>
                            <TableCell key={"F"} align={"center"}>
                              {file.status_archivo?.toLowerCase() === "verificado" ? <Badge bg="success">Verificado</Badge> : ((file.ruta_origen || file.ruta_nueva) ? <Badge bg="dark" className={file.status_archivo?.toLowerCase() || ""}>{capitalize(file.status_archivo?.toLowerCase()) || "No verificado"}</Badge> : <Badge bg="secondary">Faltante</Badge>)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={dataArchivosFiltrado?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <Col>
              {pdftime && <VisorDocumentosModal onHide={closeVisor} documentRef={refFile} tipoDocOptions={TArchivos} personal={dataPersonal} onRefresh={refreshData} PageLoading={setIsPageLoading} />}
              <Container id="containerImg" style={refFile && EXT_NEW_TAB.indexOf(refFile?.tp) !== -1 ? { display: "none" } : { padding: "0", height: "calc(100vh - 270px)" }} />
            </Col>
          </Row></>}
        {tabSelected === 1 && archivosNoid && archivosNoid.length > 0 && <>
          <Row className="mt-2">
            <Paper sx={{ width: '45%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: "calc(100vh - 240px)" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns2.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {archivosNoidFiltrado
                      .slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                      .map((file, idx) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={idx} onClick={() => { openVisor(file) }} className={fileIdx === file.id_registro ? "file-selected" : ""}>
                            <TableCell key={"A"}>
                              {file.nombre_archivo || getName(file.ruta_origen)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={archivosNoidFiltrado?.length || 0}
                rowsPerPage={rowsPerPage2}
                page={page2}
                onPageChange={handleChangePage2}
                onRowsPerPageChange={handleChangeRowsPerPage2}
              />
            </Paper>
            <Col>
              {pdftime && <VisorDocumentosModal onHide={closeVisor} documentRef={refFile} tipoDocOptions={TArchivos} personal={dataPersonal} onRefresh={refreshData} PageLoading={setIsPageLoading} />}
              <div id="containerImg" className="container" style={(refFile?.tp === "PDF") ? { display: "none" } : { padding: "0", height: "calc(100vh - 270px)" }} />
            </Col>
          </Row ></>}
      </Container >
      <Modal
        show={isShowUploadModal}
        onHide={closeUploadModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Subir Archivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <InputGroup className="mt-4 d-block">
              <p className="peso">Peso máximo de 1GB</p>
              <Form.Control
                style={{ width: "100%" }}
                placeholder="Seleccionar archivo"
                type="file"
                onChange={onSelectFile}
              />
            </InputGroup>
            <Row className="mt-4">
              <Col md={6}>
                {preview && (
                  <video
                    key={preview}
                    controls
                    style={{ width: "450px", borderRadius: "16px" }}
                  >
                    <source src={preview} type="video/mp4" />
                  </video>
                )}
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={uploadFile} disabled={isLoading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ListaDocumentos