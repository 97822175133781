import { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import ModalUpdated from "./modalUpdate";

function TableUpdate({
  columns,
  rows,
  refIdButton,
  classButton,
  iconButton,
  isRRHH,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedRow, setSelectedRow] = useState(null); // Nuevo estado

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleButtonClick = (row) => {
    setSelectedRow(row);
    setModalShow(true);
  };

  return (
    <>
      <Paper sx={{ width: "calc(100vw - 55px)", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 400px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      {columns.map((column, idxc) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {columns.length - 1 !== idxc ? (
                              column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )
                            ) : (
                              <Button
                                variant="primary"
                                className={classButton || ""}
                                onClick={() => handleButtonClick(row)}
                              >
                                <FontAwesomeIcon
                                  icon={iconButton[0] || faEye}
                                />
                              </Button>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {selectedRow && (
        <ModalUpdated
          data={selectedRow}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setSelectedRow(null);
          }}
        />
      )}
    </>
  );
}

export default TableUpdate;
