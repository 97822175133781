import { useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function StickyHeadTable({ columns, rows, refIdButton, classButton, iconButton, isRRHH, permisoVerificador }) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: 'calc(100vw - 55px)', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: "calc(100vh - 400px)" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, idxc) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {permisoVerificador && (columns.length - 1 !== idxc) ? column.label : "RRHH / Verificador"}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {columns.map((column, idxc) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {columns.length - 1 !== idxc ? (
                            column.format && typeof value === 'number'
                              ? column.format(value)
                              : value) : <>
                            {isRRHH && <Button variant="primary" className={classButton || ""} onClick={() => { navigate(`/dashboardRRHH/${row[refIdButton || ""]}`) }}>
                              <FontAwesomeIcon icon={iconButton[0] || faEye} />
                            </Button>}
                            {(!isRRHH || permisoVerificador) && <Button variant="primary" className={classButton || ""} onClick={() => { navigate(`/docList/${row[refIdButton || ""]}`) }} style={{ margin: "0 10px 0 10px" }}>
                              <FontAwesomeIcon icon={iconButton[0] || faEye} />
                            </Button>}
                          </>}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default StickyHeadTable