import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
  Navbar,
  Nav,
  Spinner,
} from "react-bootstrap";
import logo from "../img/logo-login-ci.png";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCircleExclamation,
  faCirclePlay,
  faCircleQuestion,
  faEye,
  faFile,
  faHome,
  faLock,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import Popover from "@mui/material/Popover";
import BackupIcon from "@mui/icons-material/Backup";
import HelpIcon from "@mui/icons-material/Help";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ChecklistIcon from "@mui/icons-material/Checklist";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { Card, Text, Flex, ProgressBar, Grid, Title } from "@tremor/react";
import axios from "axios";
import {
  messageAlert,
  limpiarTexto,
  getFromS3,
  TIEMPO_ESPERA,
  calcularVigencia,
} from "../components/utils";
import { Link, useNavigate, useParams } from "react-router-dom";

/**TABLE */
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LoadingPage from "../components/loadingPage";
/**componentes */
import MenuPrincipal from "../components/navbar";

function DashboardRRHH() {
  const MAX_FILE_SIZE = 20 * 1024 * 1024; //20mb
  const EXT_ALLOWED = ["png", "jpg", "jpeg", "pdf"];
  const COD_VACUNAS = [
    "2.1.2.3",
    "2.1.2",
    "2.1.2.4",
    "2.1.3",
    "2.1.2.1",
    "2.1.2.5",
    "2.1.2.2",
  ];
  const { idReg } = useParams();
  const [keyDocNum, setKeyDocNum] = useState();
  const [dataPersonal, setDataPersonal] = useState();
  const [dataArchivos, setDataArchivos] = useState();
  const [dataArchivosProc, setDataArchivosProc] = useState();
  const [dataArchivosProcFilter, setDataArchivosProcFilter] = useState([]);
  const [dataArchivosProcPreFilter, setDataArchivosProcPreFilter] = useState(
    []
  );
  const [dataArchivosVerificados, setDataArchivosVerificados] = useState();
  const [dataArchivosMedicos, setDataArchivosMedicos] = useState();
  const [TArchivos, setTArchivos] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const [isShowInfoModal, setIsShowInfoModal] = useState(false);
  const [controlModal, setControlModal] = useState(false);
  const [dataValidacion, setDataValidacion] = useState("");
  const [isShowWelcomeModal, setIsShowWelcomeModal] = useState(false);

  const [isViewSoporte, setIsViewSoporte] = useState(false);
  const [isViewSoporte2, setIsViewSoporte2] = useState(false);

  const [loggedUser, setLoggedUser] = useState();
  /**table */
  const [textPopover, setTextPopover] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(100);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(100);

  const [page4, setPage4] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(100);
  /** */
  const [isShowDetalleLS, setIsShowDetalleLS] = useState(false);
  const [TipoCapa, setTipoCapa] = useState("");
  /**data documento */
  const [tDoc, setTdoc] = useState("");
  const [tDocLabel, setTDocLabel] = useState("");
  const [tDocDescription, setTDocDescription] = useState("");
  const [aplicaVigencia, setAplicaVigencia] = useState("NO");
  const [vigencia, setVigencia] = useState("");
  const [vigenteAuditoria, setVigenteAuditoria] = useState("SI");
  const [urlVisitadas, setUrlVisitadas] = useState([]);
  /** */
  const [tabSelected, setTabSelected] = useState(0);
  const [arrFiles, setArrFiles] = useState([]);

  const [isTableRevView, setIsTableRevView] = useState(false);
  const [selectedListIndex, setSelectedListIndex] = useState(0);
  const [order, setOrder] = useState("desc");

  /** descripcion documento */
  const [arrDescripciones, setArrDescripciones] = useState({});
  const [arrDescripcionesCarga, setArrDescripcionesCarga] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 700;

  /**GRAFICA KPI*/
  const [dataKPI, setDataKPI] = useState([]);

  let navigate = useNavigate();

  const handlePopoverOpen = (event, cod) => {
    let text = arrDescripciones[cod];
    if (cod === "2.2.21" || text === "" || !text) {
      setTextPopover("");
      setAnchorEl(null);
      return;
    }
    setAnchorEl(event.currentTarget);
    setTextPopover(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (index) => {
    setSelectedListIndex(index);
    setIsTableRevView(true);
    let arr1 = dataArchivosProcPreFilter[index];
    let arrS = arr1?.arr?.sort(compare);
    setDataArchivosProcFilter(arrS);
  };

  const handleListItemClose = () => {
    setSelectedListIndex(0);
    setIsTableRevView(false);
  };

  const open = Boolean(anchorEl);

  const colorBadge = (key) => {
    let badge = "";
    switch (key) {
      case "verificado":
        badge = "success";
        break;
      case "faltante":
        badge = "secondary";
        break;
      case "por verificar":
        badge = "warning";
        break;
      default:
        badge = "dark";
        break;
    }
    return badge;
  };

  /** */

  const columns = [
    {
      id: "documento_requerido",
      label: "Documento Requerido",
      fontWeight: 700,
    },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    {
      id: "accion",
      label: "Cargar Documento",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
  ];

  const columns2 = [
    { id: "tipoCarga", label: "Periodo Carga", fontWeight: 700 },
    { id: "Archivo", label: "Archivo", fontWeight: 700 },
    { id: "Obs", label: "Observación", fontWeight: 700 },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    {
      id: "accion",
      label: "Ver Documento",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
  ];

  const columns3 = [
    { id: "Documento", label: "Documento Requerido", fontWeight: 700 },
    //{ id: 'Archivo', label: 'Archivo temporal', fontWeight: 700, color: "white" },
    { id: "Archivo", label: "Responsable", fontWeight: 700, color: "white" },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    /*{ id: 'accion', label: 'Ver Documento', align: "center", minWidth: "10.20vw", fontWeight: 700 },*/
  ];

  const columnsClinica = [
    {
      id: "documento_requerido",
      label: "Documento Requerido",
      fontWeight: 700,
    },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    {
      id: "accion",
      label: "Cargar Documento",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
  ];

  const capitalize = (str) => {
    if (!str) return false;
    if (str.length < 2) return str;
    return str.replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    });
  };

  const compare = (a, b) => {
    const pa = a.ruta_origen.toUpperCase();
    const pb = b.ruta_origen.toUpperCase();
    if (pa < pb) {
      return order === "desc" ? -1 : 1;
    }
    if (pa > pb) {
      return order === "desc" ? 1 : -1;
    }
    return 0;
  };

  const verificar = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let aux = dataValidacion?.replace(/^0+/, "");
      const dPersonal = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/compare-hash-and-access-app",
        JSON.stringify({ ci_password_documento: aux })
      );
      if (dPersonal?.data?.data?.count > 0) {
        let nombre =
          dPersonal?.data?.data?.data[0]["Apellidos y Nombres"] || "";
        nombre = capitalize(nombre?.toLowerCase());
        const res = await Swal.fire({
          title: "Verificación",
          text: `Este perfil pertenece a ${nombre} ¿Desea continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#329fe7",
          cancelButtonColor: "#6c757d",
          confirmButtonText: "Sí, continuar",
          cancelButtonText: "Cancelar",
        });
        if (res.isConfirmed) {
          setKeyDocNum(dPersonal?.data?.data?.data[0]["N°Documento"]);
          closeControlModal();
          await loadData(dPersonal?.data?.data?.data[0]["N°Documento"]);
        } else {
          setDataPersonal(null);
        }
      } else {
        await messageAlert(
          "Alerta",
          "No se reconoce el documento de identidad ingresado.",
          2200
        );
      }
    } catch (error) {
      console.log(error);
      await messageAlert(
        "Error",
        "Se produjo un error al efectuar la acción.",
        1100
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**tabla */
  const handleChangePage = (event, newPage, ord) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, ord) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage2 = (event, newPage, ord) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event, ord) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };

  const handleChangePage3 = (event, newPage, ord) => {
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = (event, ord) => {
    setRowsPerPage3(+event.target.value);
    setPage3(0);
  };

  const handleChangePage4 = (event, newPage, ord) => {
    setPage4(newPage);
  };

  const handleChangeRowsPerPage4 = (event, ord) => {
    setRowsPerPage4(+event.target.value);
    setPage4(0);
  };

  const handleTabs = async (event, newValue) => {
    if (newValue === 1) {
      await loadDataProcesada();
    }
    setTimeout(() => {
      setTabSelected(newValue);
    }, 180);
  };
  /** */

  const getLabelTA = (l, arr) => {
    let idx = arr.findIndex((e) => {
      return e.value === l;
    });
    if (idx < 0) return { label: l, exp: false };
    else return { label: arr[idx].label, exp: arr[idx].exp };
  };

  const getName = (l) => {
    if (l.length < 6) return;
    let arr = l.split("/");
    return arr[arr.length - 1];
  };

  const getUri = (l) => {
    if (l.length < 6) return;
    let arr = "";
    if (l.includes("s3://clinica-internacional-2023/"))
      arr = l.split("s3://clinica-internacional-2023/");
    else arr = l.split("s3://ci-gestor-documental-2023/");
    return arr[arr.length - 1];
  };

  const getTipoCarga = (evn) => {
    if (evn.includes("s3://clinica-internacional-2023/")) return "INICIAL";
    return "CAMPAÑA";
  };

  const closeInfoModal = () => {
    setIsShowInfoModal(false);
  };

  const closeControlModal = () => {
    setControlModal(false);
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  /**Requests*/
  const getArchId = async (key) => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/missing-file-by-doctor",
        JSON.stringify({ ci_nro_doc: key || keyDocNum })
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  };
  const getArchPend = async (key) => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/info-by-tipo-nro-doc",
        JSON.stringify({ ci_is_null: "false", ci_nro_doc: key || keyDocNum })
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  };

  const getArchTypes = async (key) => {
    try {
      const res0 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/puesto-archivo-by-medico",
        JSON.stringify({ ci_nro_documento: key || keyDocNum })
      );
      return res0.data;
    } catch (error) {
      throw error;
    }
  };
  const getArchCargaText = async () => {
    try {
      const res0 = await axios.get(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/texto-carga-informativa"
      );
      return res0.data;
    } catch (error) {
      throw error;
    }
  };
  const getMedicos = async (key) => {
    try {
      const res2 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/medicos",
        JSON.stringify({ ci_valor: key || keyDocNum })
      );
      return res2.data;
    } catch (error) {
      throw error;
    }
  };

  const orderAlpha = (arr) => {
    arr.sort((a, b) => {
      /*let pa = a.nombre_archivo.toUpperCase();
      let pb = b.nombre_archivo.toUpperCase();
      if (pa < pb) return -1;
      if (pa > pb) return 1;
      return 0;*/
      if (a.documento_indispensable < b.documento_indispensable) return 1;
      if (a.documento_indispensable > b.documento_indispensable) return -1;
      return a.nombre_archivo.localeCompare(b.nombre_archivo);
    });
    return arr;
  };
  /***/

  const refreshData = async () => {
    try {
      let KPIRRHH = { ...dataKPI[0] };
      let KPICuidador = { ...dataKPI[1] };
      const [dArchivo] = await Promise.all([getArchId()]);
      if (dArchivo?.data?.count > 0) {
        let arr = dArchivo?.data?.data?.archivos_faltantes || [];
        let arr1 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === "RRHH"
        );
        let arr2 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === "CUIDADOR"
        );
        let arrV = dArchivo?.data?.data?.archivos_validados || [];
        arr1 = orderAlpha(arr1);
        arr2 = orderAlpha(arr2);
        let idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.21");
        if (idx >= 0) {
          let aux = arr1[idx];
          arr1.splice(idx, 1);
          arr1.push(aux);
        }
        idx = arr2.findIndex((e) => e.codigo_archivo === "2.2.21");
        if (idx >= 0) {
          let aux = arr2[idx];
          arr2.splice(idx, 1);
          arr2.push(aux);
        }
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.1.2");
        if (idx >= 0) arr1.splice(idx, 1);
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.4");
        if (idx >= 0) arr1.splice(idx, 1);
        setDataArchivos(arr1);
        setTabSelected(arr?.length < 1 ? 1 : 0);
        setDataArchivosMedicos(arr2);
        setDataArchivosVerificados(arrV);
        KPIRRHH.metric =
          arr1?.filter((o) => o.documento_indispensable === "SI").length || 0;
        KPICuidador.metric =
          arr2?.filter((o) => o.documento_indispensable === "SI").length || 0;
      }
      KPIRRHH.value = Math.round(
        ((KPIRRHH.target - KPIRRHH.metric) / KPIRRHH.target) * 100
      );
      KPICuidador.value = Math.round(
        ((KPICuidador.target - KPICuidador.metric) / KPICuidador.target) * 100
      );
      if (KPIRRHH.value > 65) KPIRRHH.color = "teal";
      else if (KPIRRHH.value > 30) KPIRRHH.color = "amber";
      else KPIRRHH.color = "red";
      if (KPICuidador.value > 65) KPICuidador.color = "teal";
      else if (KPICuidador.value > 30) KPICuidador.color = "amber";
      else KPICuidador.color = "red";
      setDataKPI([KPIRRHH, KPICuidador]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async (key) => {
    try {
      setIsPageLoading(true);
      let KPIRRHH = { title: "RRHH" };
      let KPICuidador = { title: "Personal" };
      const [dTArchivos, dArchCargaText, dArchivo, dPersonal, dPend] =
        await Promise.all([
          getArchTypes(key),
          getArchCargaText(),
          getArchId(key),
          getMedicos(key),
          getArchPend(key),
        ]);
      if (dPend?.data?.count > 0) {
        setDataArchivosProc(dPend?.data?.data);
      } else {
        setDataArchivosProc([]);
      }
      if (dTArchivos?.data?.count > 0) {
        let arr = [];
        let aux = {};
        let r = 0,
          c = 0;
        dTArchivos?.data?.data.forEach((opt) => {
          aux[(opt.codigo_archivo || "").trim()] = opt.detalle_documento || "";
          if (
            opt.encargado_subir_archivo === "CUIDADOR" &&
            opt.documento_indispensable === "SI"
          )
            c = c + 1;
          else if (
            opt.encargado_subir_archivo === "RRHH" &&
            opt.documento_indispensable === "SI"
          )
            r = r + 1;
          arr.push({
            label: opt.nombre_archivo,
            value: opt.codigo_archivo,
            exp: opt.seguimiento_vigencia,
          });
        });
        KPIRRHH.target = r;
        KPICuidador.target = c;
        setArrDescripciones(aux);
        setTArchivos(arr);
      }
      if (dArchCargaText?.data?.count > 0) {
        let aux = {};
        dArchCargaText?.data?.data.forEach((opt) => {
          aux[(opt.codigo_archivo || "").trim()] = opt.detalle || "";
        });
        setArrDescripcionesCarga(aux);
      }
      if (dArchivo?.data?.count > 0) {
        let arr = dArchivo?.data?.data?.archivos_faltantes || [];
        let arr1 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === "RRHH"
        );
        let arr2 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === "CUIDADOR"
        );
        let arrV = dArchivo?.data?.data?.archivos_validados || [];
        arr1 = orderAlpha(arr1);
        arr2 = orderAlpha(arr2);
        let idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.21");
        if (idx >= 0) {
          let aux = arr1[idx];
          arr1.splice(idx, 1);
          arr1.push(aux);
        }
        idx = arr2.findIndex((e) => e.codigo_archivo === "2.2.21");
        if (idx >= 0) {
          let aux = arr2[idx];
          arr2.splice(idx, 1);
          arr2.push(aux);
        }
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.1.2");
        if (idx >= 0) arr1.splice(idx, 1);
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.4");
        if (idx >= 0) arr1.splice(idx, 1);
        setDataArchivos(arr1);
        setTabSelected(arr?.length < 1 ? 1 : 0);
        setDataArchivosMedicos(arr2);
        setDataArchivosVerificados(arrV);
        KPIRRHH.metric =
          arr1?.filter((o) => o.documento_indispensable === "SI").length || 0;
        KPICuidador.metric =
          arr2?.filter((o) => o.documento_indispensable === "SI").length || 0;
      }
      if (dPersonal?.data?.count > 0) {
        setDataPersonal(dPersonal?.data?.data[0]);
      }
      KPIRRHH.value = Math.round(
        ((KPIRRHH.target - KPIRRHH.metric) / KPIRRHH.target) * 100
      );
      KPICuidador.value = Math.round(
        ((KPICuidador.target - KPICuidador.metric) / KPICuidador.target) * 100
      );
      if (KPIRRHH.value > 65) KPIRRHH.color = "teal";
      else if (KPIRRHH.value > 30) KPIRRHH.color = "amber";
      else KPIRRHH.color = "red";
      if (KPICuidador.value > 65) KPICuidador.color = "teal";
      else if (KPICuidador.value > 30) KPICuidador.color = "amber";
      else KPICuidador.color = "red";
      setDataKPI([KPIRRHH, KPICuidador]);
      setIsPageLoading(false);
      //setTimeout(() => { setIsShowWelcomeModal(true) }, 300);
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  const loadDataProcesada = async () => {
    try {
      setIsPageLoading(true);
      const [dPend] = await Promise.all([getArchPend()]);
      if (dPend?.data?.count > 0) {
        setDataArchivosProc(dPend?.data?.data);
        let aux = {};
        let counter = 0;
        TArchivos.forEach((e) => {
          if (counter >= dPend?.data?.data.length) return;
          let a = dPend?.data?.data.filter(
            (obj) => obj.cod_tipo_doc === e.value
          );
          if (a.length > 0) aux[e.value] = { label: e.label, arr: a };
          counter = counter + a.length;
        });
        setDataArchivosProcPreFilter(Object.values(aux));
      }
      setIsPageLoading(false);
    } catch (err) {
      console.log(err);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("user") !== null) {
        let user = JSON.parse(localStorage.getItem("user"));
        setLoggedUser(user);
        if (user.rol !== 2) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
        }
        //verificar(idReg)
        setKeyDocNum(idReg);
        //closeControlModal()

        await loadData(idReg);
        //setControlModal(true)
        //setIsPageLoading(false)
      } else navigate("/login", { replace: true });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showUploadModal = (file) => {
    setTdoc(file.codigo_archivo);
    setTDocLabel(file.nombre_archivo);
    setTDocDescription(arrDescripciones[file.codigo_archivo] || "");
    setAplicaVigencia(file.seguimiento_vigencia);
    setVigencia("");
    setIsShowUploadModal(true);
  };

  const closeUploadModal = () => {
    setTdoc("");
    setTDocLabel("");
    setAplicaVigencia("NO");
    setVigencia("");
    setIsShowUploadModal(false);
    setArrFiles([]);
  };

  const viewDocument = async (file) => {
    setIsLoading(true);
    let urlArr = [...urlVisitadas];
    let uri = getUri(file?.ruta_origen);
    if (file?.uri?.length < 6) return;
    let i = urlArr.findIndex((e) => e.id_registro === file?.id_registro);
    let tT = 0;
    if (i > -1) tT = Date.now() - urlArr[i].time;
    if (tT === 0 || tT > TIEMPO_ESPERA) {
      if (file?.ruta_origen.includes("s3://clinica-internacional-2023/"))
        file.url = await getFromS3(uri, 1);
      else file.url = await getFromS3(uri, 2);
      setIsLoading(false);
      if (!file.url) return;
      file.time = Date.now();
      if (i > -1) urlArr.splice(i, 1);
      urlArr.push(file);
      setUrlVisitadas(urlArr);
    } else file = urlArr[i];
    setIsLoading(false);
    if (file.url) window.open(file.url, "_blank");
  };

  const uploadFile = async (selectedFile, i) => {
    if (!selectedFile) {
      return;
    }
    let reg = {};
    let time = moment().format("x");
    let arr = selectedFile.name.split(".");
    let ext = arr[arr.length - 1];
    reg.ci_tb_general_ruta_origen = `VERIFICADO/${dataPersonal["T.Doc."]}_${dataPersonal["N°Documento"]
      }/${time}${i}_${limpiarTexto(
        dataPersonal["Apellidos y Nombres"]
      )}_${tDoc}.${ext}`;
    reg.ci_tb_general_vigencia_archivo_2024 = vigenteAuditoria;
    reg.ci_tb_general_cod_tipo_doc = tDoc;
    reg.ci_tb_general_status_archivo = "VERIFICADO";
    reg.ci_tb_general_fecha_fin_vigencia_archivo = vigencia;
    reg.ci_tb_general_id_usuario = loggedUser?.ext || "";
    reg.ci_tb_general_pagina = 1;
    reg.ci_tb_general_observacion = "";
    reg.ci_tb_general_ruta_nueva = "";
    reg.ci_tb_general_doc_identidad_medico = dataPersonal["N°Documento"];
    try {
      let payload = { key: reg.ci_tb_general_ruta_origen };
      payload.key_nuevo = `${dataPersonal["T.Doc."]}_${dataPersonal["N°Documento"]
        }/VERIFICADO/${dataPersonal["T.Doc."]}_${dataPersonal["N°Documento"]
        }_${limpiarTexto(dataPersonal["Apellidos y Nombres"])}_${tDoc}.${ext}`;
      const urlPut = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/url-put-object",
        { key: reg.ci_tb_general_ruta_origen, content_type: selectedFile.type }
      );
      let urlData = urlPut.data?.data?.data;
      await axios.put(urlData, selectedFile, {
        headers: { "Content-Type": selectedFile.type },
      });
      reg.ci_tb_general_ruta_origen =
        "s3://ci-gestor-documental-2023/" + reg.ci_tb_general_ruta_origen;
      let resM = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/moved-file-s3-2",
        JSON.stringify(payload)
      );
      let dMove = resM.data;
      if (dMove?.data?.statusCode === 200) {
        reg.ci_tb_general_ruta_nueva = dMove?.data?.ruta_s3;
        let up = await axios.post(
          "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/insert-tb-general",
          JSON.stringify(reg)
        );
        let dSave = up.data;
        if (dSave?.data?.count > 0) {
        }
      }
    } catch (error) {
      throw error;
    }
  };

  /**Nuevo */
  const removeFile = (index) => {
    const rows = [...arrFiles];
    rows.splice(index, 1);
    setArrFiles(rows);
  };

  const checkSize = (file) => {
    if (file.size > MAX_FILE_SIZE) return false;
    return true;
  };
  const checkExtension = (filename) => {
    let arr = filename.split(".");
    let extension = arr[arr.length - 1];
    extension = extension?.toLowerCase();
    if (EXT_ALLOWED.indexOf(extension) === -1) return false;
    return true;
  };

  const selectFiles = (event) => {
    if (!(event.target.files && event.target.files[0])) return;
    let aux = [],
      e = 0,
      t = 0;
    for (const el of event.target.files) {
      if (!checkSize(el)) t = t + 1;
      else if (!checkExtension(el.name)) e = e + 1;
      else aux.push(el);
    }
    if (e > 0) {
      if (t > 0)
        messageAlert(
          "Alerta",
          "Se detectó documentos que no cumplen las restricciones de tamaño y extensión.",
          2000
        );
      else
        messageAlert(
          "Alerta",
          "Se detectó documentos que no cumplen las restricciones de extensión.",
          2000
        );
    } else if (t > 0) {
      messageAlert(
        "Alerta",
        "Se detectó documentos que no cumplen las restricciones de tamaño.",
        2000
      );
    }
    let files = [...arrFiles];
    let arr = [...files, ...aux];
    setArrFiles(arr);
    event.target.value = null;
  };

  const uploadMultipleFiles = async () => {
    try {
      //console.log("toup", arrFiles)
      setIsLoading(true);
      setIsPageLoading(true);
      await Promise.all(arrFiles.map((file, i) => uploadFile(file, i)));
      await refreshData();
      setIsPageLoading(false);
      closeUploadModal();
      await messageAlert("¡Perfecto!", "El documento se cargó correctamente.");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
      setIsLoading(false);
    }
  };

  /** */

  return (
    <>
      {isPageLoading && <LoadingPage />}
      <MenuPrincipal isLoading={isPageLoading} />
      {/* 
      <Navbar
        collapseOnSelect
        expand="lg"
        className="shadow bg-body-tertiary mb-3"
      >
        <Container fluid className="mx-4">
          <Navbar.Brand href={loggedUser?.rol === 2 ? "/" : ""}>
            <Image
              src={logo}
              className="img-fluid me-auto"
              style={{ maxWidth: 120 }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="align-items-center" style={{ fontSize: 14 }}>
              <Nav.Link
                as={Link}
                to="/"
                style={{ fontWeight: "700" }}
                className="mx-5"
              >
                <FontAwesomeIcon icon={faHome} style={{ marginRight: "5px" }} />
                Inicio
              </Nav.Link>
              {dataPersonal && (
                <>
                  <b>{dataPersonal["Apellidos y Nombres"]}</b>{" "}
                  <Button
                    onClick={() => {
                      setIsShowInfoModal(true);
                    }}
                    size="sm"
                    style={{ maxWidth: 70, marginLeft: "8px" }}
                    variant="link"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
 */}
      <Row className="mx-4">
        {dataPersonal && (
          //mover todo hacia la izquierda
          <div className="d-flex align-items-center justify-content-end">
            <b>{dataPersonal["Apellidos y Nombres"]}</b>
            <Button
              onClick={() => {
                setIsShowInfoModal(true);
              }}
              size="sm"
              style={{ maxWidth: 70, marginLeft: "8px" }}
              variant="link"
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </div>
        )}
      </Row>

      <Modal show={isShowInfoModal} onHide={closeInfoModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Información</Modal.Title>
        </Modal.Header>
        {dataPersonal && (
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  <b>Documento:</b> {dataPersonal["T.Doc."]}{" "}
                  {dataPersonal["N°Documento"]}
                </p>
              </Col>
              <Col>
                <p>
                  <b>Puesto:</b> {dataPersonal["Desc. Posición"]}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b>F. Ingreso:</b>{" "}
                  {dataPersonal["Fec. Ingreso"]
                    ? moment(dataPersonal["Fec. Ingreso"]).format("DD/MM/YYYY")
                    : ""}
                </p>
              </Col>
              <Col>
                <p>
                  <b>Situación:</b> {dataPersonal["Situación"] || "Activo"}
                </p>
              </Col>
            </Row>
            <p>
              <b>Archivo:</b> {dataPersonal["PUESTO ARCHIVO"]}
            </p>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={closeInfoModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isShowWelcomeModal}
        onHide={() => {
          setIsShowWelcomeModal(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1.25rem" }}>
            Completa tu file en simples pasos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video
            key={"1"}
            controls
            style={{ width: "100%", borderRadius: "6px" }}
          >
            <source
              src={"https://clinicainternacional.doctorfile.pe/cdn/instructivo.mp4"}
              type="video/mp4"
            />
          </video>
        </Modal.Body>
      </Modal>
      <Modal
        show={controlModal || isViewSoporte2}
        centered
        onHide={
          isViewSoporte2
            ? () => {
              setIsViewSoporte2(!isViewSoporte2);
            }
            : () => { }
        }
      >
        <Modal.Header closeButton={isViewSoporte2}>
          <Modal.Title>{isViewSoporte2 ? "Ayuda" : "Verificador"}</Modal.Title>
          {!isViewSoporte2 && (
            <Button
              variant={"secondary"}
              style={{
                backgroundColor: "var(--bs-btn-hover-bg)",
                borderColor: "var(--bs-btn-hover-border-color)",
              }}
              onClick={() => {
                setIsViewSoporte(!isViewSoporte);
              }}
              disabled={isLoading}
            >
              {!isViewSoporte ? (
                <>
                  ¿Tienes dudas? {width < breakpoint ? "" : "Haz click aquí"}{" "}
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faAngleLeft} /> Regresar
                </>
              )}
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          {isViewSoporte || isViewSoporte2 ? (
            <>
              <ul>
                <li>
                  Si el File pertenece a un <b>personal no médico</b> y tienes
                  dudas con algún documento puedes comunicarte a los números{" "}
                  <a
                    style={{ color: "#000" }}
                    href="https://wa.me/51955308140"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +51 955308140
                  </a>
                  ,{" "}
                  <a
                    style={{ color: "#000" }}
                    href="https://wa.me/51933927427"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +51 933927427
                  </a>{" "}
                  ,{" "}
                  <a
                    style={{ color: "#000" }}
                    href="https://wa.me/51960120682"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +51 960120682
                  </a>{" "}
                  y/o enviar un correo a{" "}
                  <a
                    style={{ color: "#000" }}
                    href="mailto:documentos.personal@cinternacional.com.pe"
                  >
                    documentos.personal@cinternacional.com.pe
                  </a>
                </li>
                <li className="mt-2">
                  Si el File pertenece a un <b>personal médico</b> y tienes
                  dudas con algún documento puedes enviar un correo a{" "}
                  <a
                    style={{ color: "#000" }}
                    href="mailto:ocp@cinternacional.com.pe"
                  >
                    ocp@cinternacional.com.pe
                  </a>
                </li>
              </ul>
            </>
          ) : (
            <Form className="mb-3">
              <p>Documento de identidad</p>
              <Form.Control
                placeholder="Ingresar el doc. identidad"
                type="text"
                value={dataValidacion}
                onChange={(e) => {
                  setDataValidacion(e.target.value);
                }}
              />
              <Row
                xs="auto"
                className="align-items-center     justify-content-center"
              >
                <Button
                  type="submit"
                  variant="dark"
                  className="mt-3"
                  onClick={verificar}
                  disabled={isLoading || dataValidacion?.length < 8}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <>
                      Verificar <FontAwesomeIcon icon={faLock} />
                    </>
                  )}
                </Button>
              </Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={isShowDetalleLS}
        onHide={() => {
          setIsShowDetalleLS(false);
        }}
        centered
        id="comunicado"
      >
        <Modal.Header closeButton>
          <p className="mb-0">
            <b>Detalle {TipoCapa}</b>
          </p>
        </Modal.Header>
        <div className="text-center mb-3">
          <p className="py-3 mb-0">
            {TipoCapa === "PALS" &&
              "Aplica para: Emergencia pediátrica, Pediatría, Neonatología, Anestesiología pediátrica, Cardiología pediátrica, UCI pediátrica y Neumología pediátrica"}
            {TipoCapa === "ACLS" &&
              "Aplica para: Anestesiología, Cardiología, UCI, Emergencia, Médico hospitalario, Medicina Interna, Neumología y Terapia del dolor"}
            {TipoCapa === "BLS" &&
              "Aplica para especialidades no incluidas en PALS y ACLS"}
          </p>
        </div>
      </Modal>
      <Container className="page-content dash-med">
        {!controlModal && !isPageLoading && (
          <Button
            id="welcome"
            variant="success"
            size="sm"
            onClick={() => setIsShowWelcomeModal(true)}
            disabled={isShowWelcomeModal}
          >
            <FontAwesomeIcon className="me-1" icon={faCirclePlay} />{" "}
            Instrucciones
          </Button>
        )}
        {!controlModal && (
          <Box
            sx={{ width: "100%", bgcolor: "background.paper" }}
            className="mt-3"
          >
            <Tabs
              value={tabSelected}
              onChange={handleTabs}
              centered={width < breakpoint ? false : true}
              variant={width < breakpoint ? "scrollable" : "standard"}
              scrollButtons={width < breakpoint ? "auto" : false}
            >
              <Tab
                value={0}
                disabled={!(dataArchivos && dataArchivos.length > 0)}
                icon={
                  <>
                    <BackupIcon />
                  </>
                }
                label={"Documentos pendientes"}
              />
              <Tab
                value={1}
                icon={
                  <>
                    <PublishedWithChangesIcon />
                  </>
                }
                label={"Documentos cargados"}
              />
              <Tab
                value={2}
                icon={<ChecklistIcon />}
                label={"Tipo Documento Verificados"}
              />
            </Tabs>
          </Box>
        )}
        {
          <>
            <h6 className="text-center mt-3">Avance Doc. Indispensables</h6>
            <Grid numItemsSm={4} className="gap-4 mb-2">
              {width > breakpoint && <div></div>}
              {dataKPI.map((item) => (
                <Card key={item.title}>
                  <Text className="mb-1">
                    <span style={{ fontWeight: 600, color: "#212529" }}>
                      {item.title}
                    </span>
                  </Text>
                  <Title className="mb-2">{item.metric} Pendientes</Title>
                  <Flex>
                    <Text className="truncate mb-1">{`${item.value}% (${item.target - item.metric
                      })`}</Text>
                    <Text className="mb-1">Total: {item.target}</Text>
                  </Flex>
                  <ProgressBar
                    value={item.value}
                    color={item.color}
                    className="mt-1"
                  />
                </Card>
              ))}
              {width > breakpoint && <div></div>}
            </Grid>
          </>
        }
        {tabSelected === 0 && dataArchivos && dataArchivos.length > 0 && (
          <>
            <Row className="mt-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <p
                  className=""
                  style={width < breakpoint ? { textAlign: "center" } : {}}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} /> = Documentos
                  Indispensables
                </p>
                <p>
                  <b>Documentos Pendientes de RRHH</b>
                </p>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontWeight: column.fontWeight,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataArchivos
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((file, idx) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={idx}
                            >
                              <TableCell
                                key={"A"}
                                align={"left"}
                                style={{ fontWeight: 500 }}
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(
                                    e,
                                    file.codigo_archivo.trim()
                                  )
                                }
                                onMouseLeave={handlePopoverClose}
                              >
                                {file.nombre_archivo}{" "}
                                {file.documento_indispensable === "SI" ? (
                                  <FontAwesomeIcon icon={faCircleExclamation} />
                                ) : (
                                  ""
                                )}
                                {file.codigo_archivo === "1.1.16" && (
                                  <Button
                                    className="ms-2 link-especial"
                                    variant="link"
                                    href="https://www.empleosperu.gob.pe/CertificadoUnicoLaboral/irIndex.html;jsessionid=-jYMcfXqgh6WAbI0F5NUJUyCJ6fr7ThxjV8U-nu5.srv-cjl"
                                    target="_blank"
                                  >
                                    Obtener
                                  </Button>
                                )}
                                {file.codigo_archivo === "5.1.7" && (
                                  <Button
                                    className="ms-2 link-especial"
                                    variant="link"
                                    onClick={() => {
                                      setIsShowDetalleLS(true);
                                      setTipoCapa("BLS");
                                    }}
                                  >
                                    Ver más
                                  </Button>
                                )}
                                {file.codigo_archivo === "3.1.5" && (
                                  <Button
                                    className="ms-2 link-especial"
                                    variant="link"
                                    onClick={() => {
                                      setIsShowDetalleLS(true);
                                      setTipoCapa("PALS");
                                    }}
                                  >
                                    Ver más
                                  </Button>
                                )}
                                {file.codigo_archivo === "3.1.4" && (
                                  <Button
                                    className="ms-2 link-especial"
                                    variant="link"
                                    onClick={() => {
                                      setIsShowDetalleLS(true);
                                      setTipoCapa("ACLS");
                                    }}
                                  >
                                    Ver más
                                  </Button>
                                )}
                                {COD_VACUNAS.indexOf(file.codigo_archivo) !==
                                  -1 && (
                                    <Button
                                      className="ms-2 link-especial"
                                      variant="link"
                                      href="https://clinicainternacional.doctorfile.pe/cdn/Vacunas%20perfil%20laboral.pdf"
                                      target="_blank"
                                    >
                                      Ver Esquema
                                    </Button>
                                  )}
                              </TableCell>
                              <TableCell
                                key={"B"}
                                align={"center"}
                                style={{ fontWeight: 500 }}
                              >
                                {dataArchivosProc?.some(
                                  (obj) =>
                                    obj.cod_tipo_doc === file.codigo_archivo &&
                                    (obj.status_archivo === "POR REVISAR" ||
                                      obj.status_archivo === null)
                                ) ? (
                                  <Badge bg="danger" className="proceso">
                                    En proceso
                                  </Badge>
                                ) : (
                                  <Badge
                                    bg={
                                      file.documento_indispensable !== "SI"
                                        ? "secondary"
                                        : "danger"
                                    }
                                  >
                                    {file.documento_indispensable !== "SI"
                                      ? "Opcional"
                                      : "Pendiente"}
                                  </Badge>
                                )}
                              </TableCell>
                              <TableCell key={"C"} align={"center"}>
                                <Button
                                  id="upload"
                                  variant="primary"
                                  size="sm"
                                  onClick={() => showUploadModal(file)}
                                  disabled={isLoading}
                                >
                                  <FontAwesomeIcon icon={faFile} /> Cargar
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 100]}
                  component="div"
                  count={dataArchivos?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Popover
                  id="mouse-over-popover"
                  sx={{ pointerEvents: "none" }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  style={{ maxWidth: "40vw" }}
                >
                  {textPopover}
                </Popover>
              </Paper>
            </Row>
            {columnsClinica && columnsClinica.length > 0 && (
              <Row className="my-5">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <p>
                    <b>Documentos Pendientes de Personal</b>
                  </p>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columnsClinica.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                fontWeight: column.fontWeight,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataArchivosMedicos
                          .slice(
                            page4 * rowsPerPage4,
                            page4 * rowsPerPage4 + rowsPerPage4
                          )
                          .map((file, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={idx}
                              >
                                <TableCell
                                  key={"A"}
                                  align={"left"}
                                  style={{ fontWeight: 500 }}
                                  onMouseEnter={(e) =>
                                    handlePopoverOpen(
                                      e,
                                      file.codigo_archivo.trim()
                                    )
                                  }
                                  onMouseLeave={handlePopoverClose}
                                >
                                  {file.nombre_archivo}{" "}
                                  {file.documento_indispensable === "SI" ? (
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {file.codigo_archivo === "1.1.16" && (
                                    <Button
                                      className="ms-2 link-especial"
                                      variant="link"
                                      href="https://www.empleosperu.gob.pe/CertificadoUnicoLaboral/irIndex.html;jsessionid=-jYMcfXqgh6WAbI0F5NUJUyCJ6fr7ThxjV8U-nu5.srv-cjl"
                                      target="_blank"
                                    >
                                      Obtener
                                    </Button>
                                  )}
                                  {file.codigo_archivo === "5.1.7" && (
                                    <Button
                                      className="ms-2 link-especial"
                                      variant="link"
                                      onClick={() => {
                                        setIsShowDetalleLS(true);
                                        setTipoCapa("BLS");
                                      }}
                                    >
                                      Ver más
                                    </Button>
                                  )}
                                  {file.codigo_archivo === "3.1.5" && (
                                    <Button
                                      className="ms-2 link-especial"
                                      variant="link"
                                      onClick={() => {
                                        setIsShowDetalleLS(true);
                                        setTipoCapa("PALS");
                                      }}
                                    >
                                      Ver más
                                    </Button>
                                  )}
                                  {file.codigo_archivo === "3.1.4" && (
                                    <Button
                                      className="ms-2 link-especial"
                                      variant="link"
                                      onClick={() => {
                                        setIsShowDetalleLS(true);
                                        setTipoCapa("ACLS");
                                      }}
                                    >
                                      Ver más
                                    </Button>
                                  )}
                                  {COD_VACUNAS.indexOf(file.codigo_archivo) !==
                                    -1 && (
                                      <Button
                                        className="ms-2 link-especial"
                                        variant="link"
                                        href="https://clinicainternacional.doctorfile.pe/cdn/Vacunas%20perfil%20laboral.pdf"
                                        target="_blank"
                                      >
                                        Ver Esquema
                                      </Button>
                                    )}
                                </TableCell>
                                <TableCell
                                  key={"B"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {dataArchivosProc?.some(
                                    (obj) =>
                                      obj.cod_tipo_doc ===
                                      file.codigo_archivo &&
                                      (obj.status_archivo === "POR REVISAR" ||
                                        obj.status_archivo === null)
                                  ) ? (
                                    <Badge bg="danger" className="proceso">
                                      En proceso
                                    </Badge>
                                  ) : (
                                    <Badge
                                      bg={
                                        file.documento_indispensable !== "SI"
                                          ? "secondary"
                                          : "danger"
                                      }
                                    >
                                      {file.documento_indispensable !== "SI"
                                        ? "Opcional"
                                        : "Pendiente"}
                                    </Badge>
                                  )}
                                </TableCell>
                                <TableCell key={"C"} align={"center"}>
                                  <Button
                                    id="upload"
                                    variant="primary"
                                    size="sm"
                                    onClick={() => showUploadModal(file)}
                                    disabled={isLoading}
                                  >
                                    <FontAwesomeIcon icon={faFile} /> Cargar
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={dataArchivosMedicos?.length || 0}
                    rowsPerPage={rowsPerPage4}
                    page={page4}
                    onPageChange={handleChangePage4}
                    onRowsPerPageChange={handleChangeRowsPerPage4}
                  />
                </Paper>
              </Row>
            )}
          </>
        )}
        {tabSelected === 1 &&
          dataArchivosProc &&
          dataArchivosProc.length > 0 && (
            <>
              <Row className="mt-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <List
                    component="nav"
                    aria-label="main folders"
                    style={isTableRevView ? { display: "none" } : {}}
                  >
                    {dataArchivosProcPreFilter.map((TArchivo, idx) => (
                      <ListItemButton
                        key={idx}
                        selected={selectedListIndex === 0}
                        onClick={() => handleListItemClick(idx)}
                      >
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary={TArchivo.label} />
                      </ListItemButton>
                    ))}
                  </List>
                  {isTableRevView && (
                    <>
                      <Button variant="primary" onClick={handleListItemClose}>
                        Regresar
                      </Button>
                      {dataArchivosProcFilter?.length > 0 && (
                        <p className="mt-4 mb-2" style={{ fontWeight: 500 }}>
                          {
                            getLabelTA(
                              dataArchivosProcFilter[0].cod_tipo_doc,
                              TArchivos
                            ).label
                          }
                        </p>
                      )}
                      <TableContainer sx={{ maxHeight: "calc(100vh - 320px)" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns2.map((column, idx) =>
                                width > breakpoint ||
                                  (width < breakpoint && idx !== 1) ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      fontWeight: column.fontWeight,
                                    }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ) : (
                                  ""
                                )
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataArchivosProcFilter
                              .slice(
                                page2 * rowsPerPage2,
                                page2 * rowsPerPage2 + rowsPerPage2
                              )
                              .map((file, idx) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={idx}
                                  >
                                    <TableCell
                                      key={"/"}
                                      align={"left"}
                                      style={{ fontWeight: 500 }}
                                    >
                                      {getTipoCarga(file.ruta_origen)}
                                    </TableCell>
                                    <TableCell
                                      key={"A"}
                                      align={"left"}
                                      style={{ fontWeight: 500 }}
                                    >
                                      {getName(file.ruta_origen)}
                                    </TableCell>
                                    {width > breakpoint && (
                                      <TableCell
                                        key={"AO"}
                                        align={"left"}
                                        style={{ fontWeight: 500 }}
                                      >
                                        {file.observacion &&
                                          file.observacion?.trim() !== ""
                                          ? file.observacion +
                                          (file.pagina
                                            ? " - Pag. " + file.pagina
                                            : "")
                                          : ""}
                                      </TableCell>
                                    )}
                                    <TableCell
                                      key={"B"}
                                      align={"center"}
                                      style={{ fontWeight: 500 }}
                                    >
                                      <Badge
                                        bg={colorBadge(
                                          file.status_archivo?.toLowerCase()
                                        )}
                                      >
                                        {file.status_archivo
                                          ? capitalize(
                                            file.status_archivo?.toLowerCase()
                                          )
                                          : "Por Revisar"}
                                      </Badge>
                                    </TableCell>
                                    <TableCell key={"C"} align={"center"}>
                                      <Button
                                        id="upload"
                                        variant="primary"
                                        size="sm"
                                        onClick={() => viewDocument(file)}
                                        disabled={isLoading}
                                      >
                                        {isLoading ? (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <>
                                            <FontAwesomeIcon icon={faEye} /> Ver
                                          </>
                                        )}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 20, 100]}
                        component="div"
                        count={dataArchivosProcFilter?.length || 0}
                        rowsPerPage={rowsPerPage2}
                        page={page2}
                        onPageChange={handleChangePage2}
                        onRowsPerPageChange={handleChangeRowsPerPage2}
                      />
                    </>
                  )}
                </Paper>
              </Row>
            </>
          )}
        {tabSelected === 2 &&
          dataArchivosVerificados &&
          dataArchivosVerificados.length > 0 && (
            <>
              <Row className="mt-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <p
                    className="mb-0 pt-1"
                    style={width < breakpoint ? { textAlign: "center" } : {}}
                  >
                    <FontAwesomeIcon icon={faCircleExclamation} /> = Documentos
                    Indispensables
                  </p>
                  <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns3.map(
                            (column, idx) =>
                              (width > breakpoint ||
                                (width < breakpoint && idx !== 1)) && (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    fontWeight: column.fontWeight,
                                    color: column.color,
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataArchivosVerificados
                          .slice(
                            page3 * rowsPerPage3,
                            page3 * rowsPerPage3 + rowsPerPage3
                          )
                          .map((file, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={idx}
                              >
                                <TableCell
                                  key={"#"}
                                  align={"left"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {file.nombre_archivo}{" "}
                                  {file.documento_indispensable === "SI" ? (
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                {width > breakpoint && (
                                  <TableCell
                                    key={"A"}
                                    align={"left"}
                                    style={{
                                      fontWeight: 500,
                                      opacity: ".5",
                                      color: "white",
                                    }}
                                  >
                                    {file.encargado_subir_archivo}
                                  </TableCell>
                                )}
                                <TableCell
                                  key={"B"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  <Badge bg="success">Verificado</Badge>
                                </TableCell>
                                <TableCell key={"C"} align={"center"}>
                                  {false && (
                                    <Button
                                      id="upload"
                                      variant="primary"
                                      size="sm"
                                      onClick={() => viewDocument(file)}
                                      disabled={isLoading}
                                    >
                                      {isLoading ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <>
                                          <FontAwesomeIcon icon={faEye} /> Ver
                                        </>
                                      )}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={dataArchivosVerificados?.length || 0}
                    rowsPerPage={rowsPerPage3}
                    page={page3}
                    onPageChange={handleChangePage3}
                    onRowsPerPageChange={handleChangeRowsPerPage3}
                  />
                </Paper>
              </Row>
            </>
          )}
        {!isViewSoporte2 && !controlModal && !isPageLoading && (
          <Fab
            size="medium"
            variant="extended"
            onClick={() => setIsViewSoporte2(true)}
          >
            ¿Tienes dudas? {width < breakpoint ? "" : "Haz click aquí"}
            <HelpIcon sx={{ ml: 1 }} />
          </Fab>
        )}
      </Container>
      {!isPageLoading && (
        <Modal
          show={isShowUploadModal}
          onHide={closeUploadModal}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Carga de documentos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <Row>
                <Col>
                  <p style={{ marginBottom: 10 }}>
                    <b>{tDocLabel}</b>
                  </p>
                  {tDocDescription !== "" && (
                    <p style={{ marginBottom: 5 }}>
                      <span style={{ fontWeight: 500 }}>Detalle: </span>
                      {tDoc === "1.1.16" ? (
                        <>
                          Para descargar ingresa{" "}
                          <a
                            className="link-descripcion"
                            href="https://www.empleosperu.gob.pe/CertificadoUnicoLaboral/irIndex.html;jsessionid=-jYMcfXqgh6WAbI0F5NUJUyCJ6fr7ThxjV8U-nu5.srv-cjl"
                            target="_blank"
                            rel="noreferrer"
                          >
                            aquí
                          </a>
                        </>
                      ) : (
                        tDocDescription
                      )}
                    </p>
                  )}
                  {arrDescripcionesCarga[tDoc] && (
                    <p>
                      {arrDescripcionesCarga[tDoc]}{" "}
                      {COD_VACUNAS.indexOf(tDoc) !== -1 && (
                        <a
                          className="link-descripcion"
                          href="https://clinicainternacional.doctorfile.pe/cdn/Vacunas%20perfil%20laboral.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          aquí
                        </a>
                      )}
                    </p>
                  )}
                  {tDoc === "3.1.5" && (
                    <p>
                      Aplica para: Emergencia pediátrica, Pediatría,
                      Neonatología, Anestesiología pediátrica, Cardiología
                      pediátrica, UCI pediátrica y Neumología pediátrica
                    </p>
                  )}
                  {tDoc === "3.1.4" && (
                    <p>
                      Aplica para: Anestesiología, Cardiología, UCI, Emergencia,
                      Médico hospitalario, Medicina Interna, Neumología y
                      Terapia del dolor
                    </p>
                  )}
                  {tDoc === "5.1.7" && (
                    <p>
                      Aplica para especialidades no incluidas en PALS y ACLS
                    </p>
                  )}
                  {false && (
                    <i>
                      Recuerde verificar que los documentos cuenten con la
                      legibilidad, nitidez y vigencia correctas. Evite incluir
                      hojas en blanco o documentos que no cumplan con los
                      lineamientos establecidos. ¡Gracias por su colaboración!
                    </i>
                  )}
                  <InputGroup className="mt-3">
                    <Form.Control
                      style={{ width: "100%" }}
                      placeholder="Seleccionar archivo"
                      multiple
                      accept="image/*, application/pdf"
                      type="file"
                      onChange={selectFiles}
                    />
                    <p className="mt-2">
                      * Solo se permite subir documentos en formato PDF, JPG o
                      PNG. Máximo archivos de 20 MB.
                    </p>
                  </InputGroup>
                </Col>
              </Row>
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                flexWrap="wrap"
                className="mt-2"
              >
                {arrFiles.map((file, idx) => {
                  return (
                    <Chip
                      key={idx}
                      label={file.name}
                      onClick={() => removeFile(idx)}
                      onDelete={() => removeFile(idx)}
                    />
                  );
                })}
              </Stack>
              {aplicaVigencia?.toUpperCase() !== "NO" && (
                <Row>
                  <Col>
                    <p className="mt-4">Asignar Vigencia</p>
                    <InputGroup className="d-block">
                      <Form.Control
                        placeholder="yyyy-mm-dd"
                        type="date"
                        style={{ width: "50%" }}
                        value={vigencia}
                        onChange={(e) => {
                          setVigencia(e.target.value);
                        }}
                      />
                      {vigencia?.length > 0 && (
                        <Button
                          variant="link"
                          onClick={() => {
                            setVigencia("");
                          }}
                        >
                          <FontAwesomeIcon
                            className="text-dark"
                            icon={faClose}
                          />
                        </Button>
                      )}
                    </InputGroup>
                  </Col>
                  <Col>
                    <p className="mt-4">¿Vigente hasta el 31 de marzo?</p>
                    <InputGroup className="d-block">
                      <Form.Select
                        name="Vigente Enero 2024"
                        style={{ width: "70%" }}
                        onChange={(e) => {
                          setVigenteAuditoria(e.target.value);
                        }}
                        value={vigenteAuditoria}
                      >
                        <option key={1} value={"SI"}>
                          Si
                        </option>
                        <option key={2} value={"NO"}>
                          No
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </Col>
                </Row>
              )}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={uploadMultipleFiles}
              disabled={
                isLoading ||
                arrFiles.length < 1 ||
                (aplicaVigencia?.toUpperCase() !== "NO" &&
                  (!vigencia || vigencia === ""))
              }
            >
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DashboardRRHH;
