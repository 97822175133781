import React, { useState } from "react";
import { Form, Button, InputGroup, FormControl, Alert, Spinner, Row, Col } from "react-bootstrap";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import logo from "../img/logo-login-ci.png";

function Login() {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [badLogin, setBadLogin] = useState(false);
  const [connError, setConnError] = useState(false);
  const [badMailLostPwd, setBadMailLostPwd] = useState(false);
  const [lostPasword, setLostPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  let navigate = useNavigate();

  const onChangeUser = (e) => {
    setBadLogin(false);
    setConnError(false);
    setUser(e.target.value);
  };

  const onChangePass = (e) => {
    setBadLogin(false);
    setConnError(false);
    setPass(e.target.value);
  };

  const onSubmitLostPassword = (e) => {
    e.preventDefault();
    setUser("");
    setIsLoading(true);
    axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/lost-pass", JSON.stringify({ ci_email: user }))
      .then((response) => {
        console.log(response?.data?.data?.success, response?.data?.data)
        if (response?.data?.data?.success) {
          Swal.fire({
            icon: "success",
            title: "¡Listo!",
            confirmButtonColor: "#329fe7",
            html: "<p>Se ha enviado un link de recuperacion al correo ingresado.</p>",
          }).then((result) => {
            if (result.isConfirmed) {
              setLostPassword(false);
              setConnError(false);
              setBadMailLostPwd(false);
            }
          });
        } else {
          setBadMailLostPwd(true);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setBadMailLostPwd(true);
        } else if (e.response.status === 500) {
          setConnError(true);
        }
        setIsLoading(false);
      });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    let mail = false
    let payload = {}
    if (user.includes("@")) mail = true
    payload = mail ? { ci_email: user, ci_password: pass } : { ci_doc_apellido: user, ci_password_documento: pass }
    try {
      const { data } = await axios.post(mail ? "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/login" : "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/compare-hash-and-access-app", JSON.stringify(payload))
      if (data?.data?.count > 0) {
        const user = {
          name: mail ? data?.data?.data[0].nombre + " " + data?.data?.data[0].apellido : data?.data?.data[0]["Apellidos y Nombres"],
          mail: mail ? data?.data?.data[0].email : data?.data?.data[0]["Correo electrónico"],
          ext: mail ? data?.data?.data[0].id : data?.data?.data[0]["N°Documento"],
          adm: data?.data?.data[0].is_admin
        };
        user.rol = mail ? data?.data?.data[0].rol_id : data?.data?.data[0]["cod_role"];
        localStorage.setItem("user", JSON.stringify(user));
        //user.rol === 3 ? navigate("/login", { replace: true }) : navigate("/", { replace: true });
        if (user.rol !== 3) navigate("/", { replace: true })
      } else {
        setBadLogin(true);
      }
      setIsLoading(false)
    } catch (error) {
      if (e.response.status === 500) {
        setConnError(true);
        console.log(e);
      }
      setIsLoading(false)
    }
  };

  if (localStorage.getItem("user") !== null) {
    navigate("/", { replace: true });
  }

  return (
    <div className="container-fluid login">
      <div className="center">
        <div className="login-form">
          <div className="center">
            <img src={logo} className="img-fluid mb-5" alt="logo" width="250px" />
          </div>
          {!lostPasword && <>
            <Form onSubmit={onSubmit}>
              <InputGroup className="mb-3" onChange={onChangeUser}>
                <FormControl placeholder="Nombre de usuario" autoComplete="username" />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text>
              </InputGroup>
              <InputGroup className="mb-3" onChange={onChangePass}>
                <FormControl type="password" placeholder="Contraseña" autoComplete="current-password" />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faLock} />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" type="submit" className="form-control" disabled={isLoading}>
                {isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : "Iniciar sesión"}
              </Button>
              <Button
                variant="link"
                className="text-center d-block mx-auto"
                style={{ color: "#329fe7", fontWeight: 500 }}
                onClick={(e) => {
                  setLostPassword(true);
                  setConnError(false);
                  setBadLogin(false);
                }}>
                ¿Olvidaste tu contraseña?
              </Button>
              <Alert show={badLogin} key={"bad"} variant={"danger"} className="mt-3 mb-0 text-center">
                Revisa tu usuario y contraseña
              </Alert>
              <Alert show={connError} key={"conn"} variant={"danger"} className="mt-3 mb-0 text-center">
                Se experimentan dificultades técnicas, intenta más tarde
              </Alert>
            </Form>
            <Row className="mt-3 justify-content-center" xs={"auto"}><Col>¿Deseas completar tu file?</Col></Row>
            <Row className="mt-1 justify-content-center" xs={"auto"}><Col><Button variant="dark" className="form-control mt-2" onClick={() => { navigate("/completarfile", { replace: true }) }} disabled={isLoading}>Click aqui</Button></Col></Row>
          </>}
          {lostPasword && (
            <Form onSubmit={onSubmitLostPassword}>
              <p>Recuperar Contraseña</p>
              <InputGroup className="mb-3" onChange={onChangeUser}>
                <FormControl type="email" placeholder="Email" autoComplete="emaillostpwd" />
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" type="submit" className="form-control" disabled={isLoading}>
                {isLoading === true ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Solicitar"}
              </Button>
              <Button
                variant="link"
                className="text-center d-block mt-3 mx-auto"
                style={{ color: "#329fe7", fontWeight: 500 }}
                onClick={(e) => {
                  setLostPassword(false);
                  setConnError(false);
                  setBadMailLostPwd(false);
                }}>
                Regresar
              </Button>
              <Alert show={badMailLostPwd} key={"bad"} variant={"danger"} className="mt-3 mb-0 text-center">
                No se encuentra el correo ingresado
              </Alert>
              <Alert show={connError} key={"conn"} variant={"danger"} className="mt-3 mb-0 text-center">
                Se experimentan dificultades tecnicas, intenta mas tarde
              </Alert>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
