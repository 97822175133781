import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import array_asistencial from "../data/jerarquia_update_asistencial";
import array_staff from "../data/jerarquia_update_staff";
import axios from "axios";
import Swal from "sweetalert2";
//utils
import {
  validateTypeNumberDoc,
  validateLengthNumberDoc,
  cleanBlankSpacesAndToUpperCase,
} from "./utils";

function ModalRegistro({ show, onHide }) {
  const [loggedUser, setLoggedUser] = useState(null);
  const [array_posicion_asistencial, setArray_posicion_asistencial] =
    useState(array_asistencial);
  const [array_posicion_staff, setArray_posicion_staff] = useState(array_staff);
  const [validated, setValidated] = useState(false);

  // useEffect para obtener el usuario logueado
  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      let user = JSON.parse(localStorage.getItem("user"));
      setLoggedUser(user);
    }
  }, []);

  //ASISTENCIAL
  /* 
  ---JERARQUIA DE PUESTOS 

 SELECT medico_perfil,"PUESTO ARCHIVO", gerencia, unidad_org_3, unidad_org_2, "Unid.Organizativa 1", count(1) 
 FROM public.medicos
 where medico_perfil ='ASISTENCIAL'
 group by medico_perfil,"PUESTO ARCHIVO", gerencia, unidad_org_3, unidad_org_2, "Unid.Organizativa 1"

  */
  const unique_gerencia_asistencial = () => {
    //obtener los valores de la gerencia unicos y ordenados
    const unique = [
      ...new Set(array_posicion_asistencial.map((item) => item.gerencia)),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_org_3 = (gerencia) => {
    //obtener los valores de la unidad organizativa 3 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter((item) => item.gerencia === gerencia)
          .map((item) => item.unidad_org_3)
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_org_2 = (gerencia, unidad_org_3) => {
    //obtener los valores de la unidad organizativa 2 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter(
            (item) =>
              item.gerencia === gerencia && item.unidad_org_3 === unidad_org_3
          )
          .map((item) => item.unidad_org_2)
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_org_1 = (gerencia, unidad_org_3, unidad_org_2) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter(
            (item) =>
              item.gerencia === gerencia &&
              item.unidad_org_3 === unidad_org_3 &&
              item.unidad_org_2 === unidad_org_2
          )
          .map((item) => item["Unid.Organizativa 1"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_puesto_archivo = (
    gerencia,
    unidad_org_3,
    unidad_org_2,
    unidad_org_1
  ) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter(
            (item) =>
              item.gerencia === gerencia &&
              item.unidad_org_3 === unidad_org_3 &&
              item.unidad_org_2 === unidad_org_2 &&
              item["Unid.Organizativa 1"] === unidad_org_1
          )
          .map((item) => item["PUESTO ARCHIVO"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  //STAFF

  /* 
  SELECT medico_perfil,"PUESTO ARCHIVO", "Desc. Posición", "Desc. Área Personal", count(1) 
  FROM public.medicos
  where medico_perfil ='STAFF'
  group by medico_perfil,"PUESTO ARCHIVO", "Desc. Posición", "Desc. Área Personal"

*/

  const unique_gerencia_staff = () => {
    //obtener los valores de la gerencia unicos y ordenados
    const unique = [
      ...new Set(array_posicion_staff.map((item) => item["Desc. Posición"])),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_area_personal = (desc_posicion) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_staff
          .filter((item) => item["Desc. Posición"] === desc_posicion)
          .map((item) => item["Desc. Área Personal"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_puesto_archivo = (desc_posicion, desc_area_personal) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_staff
          .filter(
            (item) =>
              item["Desc. Posición"] === desc_posicion &&
              item["Desc. Área Personal"] === desc_area_personal
          )
          .map((item) => item["PUESTO ARCHIVO"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const [registro, setRegistro] = useState({
    "Cód.Trabajador": "", //PARA STAFF Y PLANILLA
    "Apellidos y Nombres": "",
    "T.Doc.": "DNI",
    "N°Documento": "",
    "Desc. Posición": "", //PARA STAFF
    CRITICIDAD: "", //PARA STAFF Y PLANILLA
    "Fec. Ingreso": "", //PARA STAFF Y PLANILLA
    "Desc. Área Personal": "", //PARA STAFF ES EL AREA
    gerencia: "", //PARA PLANILLA ES LA GERENCIA
    unidad_org_3: "", //PARA PLANILLA ES LA UNIDAD ORGANIZATIVA
    unidad_org_2: "", //PARA PLANILLA ES LA UNIDAD ORGANIZATIVA
    "Unid.Organizativa 1": "", //PARA PLANILLA ES LA UNIDAD ORGANIZATIVA
    Situación: "ACTIVO", //PARA STAFF Y PLANILLA
    "PUESTO ARCHIVO": "", //PARA STAFF Y PLANILLA
    cod_role: 3, //PARA STAFF Y PLANILLA
    medico_perfil: "", //PARA STAFF Y PLANILLA
    user: "",
  });

  const handleChange = (e) => {
    setRegistro({
      ...registro,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, complete todos los campos.",
        confirmButtonColor: "#dc3545",
      });
    } else {
      //el N°Documento debe tener entre 8 y 11 caracteres
      if (validateLengthNumberDoc(registro["N°Documento"]) === false) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El N°Documento debe tener entre 8 y 11 caracteres.",
          confirmButtonColor: "#dc3545",
        });
        return;
      }
      //el N°Documento deben ser solo numeros
      if (validateTypeNumberDoc(registro["N°Documento"]) === false) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El N°Documento deben ser solo números.",
          confirmButtonColor: "#dc3545",
        });
        return;
      }

      const data = {
        "Cód.Trabajador": registro["medico_perfil"],
        "Apellidos y Nombres": registro["Apellidos y Nombres"],
        "T.Doc.": registro["T.Doc."],
        "N°Documento": registro["N°Documento"],
        "Desc. Posición": registro["Desc. Posición"],
        CRITICIDAD: registro["CRITICIDAD"],
        "Fec. Ingreso": moment(registro["Fec. Ingreso"]).format("YYYY-MM-DD"),
        "Desc. Área Personal": registro["Desc. Área Personal"],
        gerencia: registro["gerencia"],
        unidad_org_3: registro["unidad_org_3"],
        unidad_org_2: registro["unidad_org_2"],
        "Unid.Organizativa 1": registro["Unid.Organizativa 1"],
        Situación: registro["Situación"],
        "PUESTO ARCHIVO": registro["PUESTO ARCHIVO"],
        cod_role: 3,
        medico_perfil: registro["medico_perfil"],
        user: loggedUser.name,
      };

      data["N°Documento"] = cleanBlankSpacesAndToUpperCase(data["N°Documento"]);
      data["Apellidos y Nombres"] = cleanBlankSpacesAndToUpperCase(
        data["Apellidos y Nombres"]
      );

      try {
        const res = await axios.post(
          "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/register-medicos",
          JSON.stringify(data)
        );
        if (!res?.data?.data?.success) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res?.data?.data?.message,
            confirmButtonColor: "#dc3545",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Éxito",
            text: res?.data?.data?.message,
            confirmButtonColor: "#329fe7",
          });
          //limpiar campos
          setRegistro({
            "Cód.Trabajador": "", //PARA STAFF Y PLANILLA
            "Apellidos y Nombres": "",
            "T.Doc.": "DNI",
            "N°Documento": "",
            "Desc. Posición": "", //PARA STAFF
            CRITICIDAD: "", //PARA STAFF Y PLANILLA
            "Fec. Ingreso": "", //PARA STAFF Y PLANILLA
            "Desc. Área Personal": "", //PARA STAFF ES EL AREA
            gerencia: "", //PARA PLANILLA ES LA GERENCIA
            unidad_org_3: "", //PARA PLANILLA ES LA UNIDAD ORGANIZATIVA
            unidad_org_2: "", //PARA PLANILLA ES LA UNIDAD ORGANIZATIVA
            "Unid.Organizativa 1": "", //PARA PLANILLA ES LA UNIDAD ORGANIZATIVA
            Situación: "ACTIVO", //PARA STAFF Y PLANILLA
            "PUESTO ARCHIVO": "", //PARA STAFF Y PLANILLA
            cod_role: 3, //PARA STAFF Y PLANILLA
            medico_perfil: "", //PARA STAFF Y PLANILLA
          });

          onHide();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al agregar el registro.",
          confirmButtonColor: "#dc3545",
        });
      }
    }
    setValidated(true);
  };


  //AVISOS ANTES DE CERRAR EL MODAL
  const closeModalRegister = () => {
    if (
      registro["medico_perfil"] !== "" ||
      registro["medico_perfil"] !== "Seleccione un perfil"
    ) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Si cierras el modal se perderán los datos ingresados.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#329fe7",
        cancelButtonColor: "#dc3545",
        confirmButtonText: "Si, cerrar",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          onHide();
        }
      });
    } else {
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={closeModalRegister} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Agregar Cuidador/Médico
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="pt-2" controlId="formTDoc">
                <Form.Label>T.Doc.:</Form.Label>
                <Form.Select
                  name="T.Doc."
                  value={registro["T.Doc."]}
                  onChange={handleChange}
                  autoFocus={true}
                  required
                >
                  <option defaultValue>DNI</option>
                  <option value="CEX">CEX</option>
                </Form.Select>
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="pt-2" controlId="formNDocumento">
                <Form.Label>Número de Documento:</Form.Label>
                <Form.Control
                  type="text"
                  name="N°Documento"
                  value={registro["N°Documento"]}
                  onChange={handleChange}
                  maxLength="11"
                  minLength="8"
                  required
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="pt-2" controlId="formApellidosNombres">
                <Form.Label>Apellidos y Nombres:</Form.Label>
                <Form.Control
                  type="text"
                  name="Apellidos y Nombres"
                  value={registro["Apellidos y Nombres"]}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="pt-2" controlId="formFecIngreso">
                <Form.Label>Fec. Ingreso:</Form.Label>
                {/* datepicker */}
                <Form.Control
                  type="date"
                  name="Fec. Ingreso"
                  value={registro["Fec. Ingreso"]}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="pt-2" controlId="formMedicoPerfil">
                <Form.Label>Medico Perfil:</Form.Label>
                <Form.Select
                  name="medico_perfil"
                  value={registro["medico_perfil"]}
                  onChange={handleChange}
                  required
                >
                  <option value="">Seleccione un perfil</option>
                  <option value="ASISTENCIAL">PLANILLA</option>
                  <option value="STAFF">STAFF</option>
                </Form.Select>
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>
            </Col>

            {registro["medico_perfil"] === "" ||
              registro["medico_perfil"] === "Seleccione un perfil" ? (
              <Col md={6}>
                <p>Seleccione un perfil</p>
              </Col>
            ) : registro["medico_perfil"] === "ASISTENCIAL" ? (
              <Col md={6}>
                <Form.Group className="mt-2" controlId="formGerencia">
                  <Form.Label>Gerencia:</Form.Label>
                  <Form.Select
                    name="gerencia"
                    value={registro["gerencia"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_gerencia_asistencial().map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formUnidadOrg3">
                  <Form.Label>U.O 3:</Form.Label>
                  <Form.Select
                    name="unidad_org_3"
                    value={registro["unidad_org_3"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_unidad_org_3(registro["gerencia"]).map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formUnidadOrg2">
                  <Form.Label>U.O 2:</Form.Label>
                  <Form.Select
                    name="unidad_org_2"
                    value={registro["unidad_org_2"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_unidad_org_2(
                      registro["gerencia"],
                      registro["unidad_org_3"]
                    ).map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formUnidadOrg1">
                  <Form.Label>U.O 1:</Form.Label>
                  <Form.Select
                    name="Unid.Organizativa 1"
                    value={registro["Unid.Organizativa 1"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_unidad_org_1(
                      registro["gerencia"],
                      registro["unidad_org_3"],
                      registro["unidad_org_2"]
                    ).map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formPuestoArchivo">
                  <Form.Label>PUESTO ARCHIVO:</Form.Label>
                  <Form.Select
                    name="PUESTO ARCHIVO"
                    value={registro["PUESTO ARCHIVO"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_unidad_puesto_archivo(
                      registro["gerencia"],
                      registro["unidad_org_3"],
                      registro["unidad_org_2"],
                      registro["Unid.Organizativa 1"]
                    ).map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formSituacion">
                  <Form.Label>Situación:</Form.Label>
                  <Form.Select
                    name="Situación"
                    value={registro["Situación"]}
                    onChange={handleChange}
                    required
                  >
                    <option defaultValue>Seleccione</option>
                    <option value="ACTIVO">ACTIVO</option>
                    <option value="INACTIVO">INACTIVO</option>
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formCriticicidad">
                  <Form.Label>Criticidad:</Form.Label>
                  <Form.Select
                    name="CRITICIDAD"
                    value={registro["CRITICIDAD"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Col>
            ) : (
              <Col md={6}>
                <Form.Group className="mt-2" controlId="formDescPosicion">
                  <Form.Label>Especialidad: </Form.Label>
                  <Form.Select
                    name="Desc. Posición"
                    value={registro["Desc. Posición"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_gerencia_staff().map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formDescAreaPersonal">
                  <Form.Label>Área:</Form.Label>
                  <Form.Select
                    name="Desc. Área Personal"
                    value={registro["Desc. Área Personal"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_area_personal(registro["Desc. Posición"]).map(
                      (item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formPuestoArchivo">
                  <Form.Label>PUESTO ARCHIVO:</Form.Label>
                  <Form.Select
                    name="PUESTO ARCHIVO"
                    value={registro["PUESTO ARCHIVO"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    {unique_puesto_archivo(
                      registro["Desc. Posición"],
                      registro["Desc. Área Personal"]
                    ).map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formSituacion">
                  <Form.Label>Situación:</Form.Label>
                  <Form.Select
                    name="Situación"
                    value={registro["Situación"]}
                    onChange={handleChange}
                    required
                  >
                    <option defaultValue>Seleccione</option>
                    <option value="ACTIVO">ACTIVO</option>
                    <option value="INACTIVO">INACTIVO</option>
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2" controlId="formCriticicidad">
                  <Form.Label>Criticidad:</Form.Label>
                  <Form.Select
                    name="CRITICIDAD"
                    value={registro["CRITICIDAD"]}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Seleccione</option>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </Form.Select>
                  <Form.Control.Feedback></Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          </Row>
          <hr />
          <Row className="mt-4" >
            <Col className="d-flex justify-content-end" >
              <Button variant="primary" type="submit">
                Agregar Registro
              </Button>
              <Button variant="secondary" className="ms-2" onClick={closeModalRegister}>
                Cancelar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalRegistro;
