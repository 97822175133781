import React, { useEffect, useState } from "react";
import MenuPrincipal from "../components/navbar";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRefresh,
  faChevronLeft,
  faFileExcel,
  faCheckCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import Excel from "exceljs";
import Swal from "sweetalert2";
import LoadingPage from "../components/loadingPage";
import { verificarAcceso } from "../components/utils";

import ModalBajasMasivas from "../components/modalBajasMasivas";
import ModalAltasMasivas from "../components/modalAltasMasivas";


function DashboardMasivo() {
  // useState para manejar los datos editados
  const [loggedUser, setLoggedUser] = useState(null);
  const [formCargaMasiva, setFormCargaMasiva] = useState({
    perfil: "",
    tipoCarga: "",
  });
  const [previewModificado, setPreviewModificado] = useState(null);
  const [unidadesOrganizativas, setUnidadesOrganizativas] = useState([]);
  const [fileSelected, setFileSelected] = useState(null);
  const [accesosAdicionales, setAccesosAdicionales] = useState()
  const [fileError, setFileError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [gif, setGif] = useState(null);
  const navigate = useNavigate();

  //giphy


  // useEffect para obtener el usuario logueado
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        if (localStorage.getItem("user") !== null) {
          let user = JSON.parse(localStorage.getItem("user"));
          setLoggedUser(user);
          let accesos = await verificarAcceso(user.ext)
          if (!accesos?.adm && !accesos?.v2) {
            navigate("/", { replace: true });
            return
          }
          setAccesosAdicionales(accesos)
          //obtener las unidades organizativas
          let response = await axios.get(
            "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/get-unidades-organizativas"
          );
          let data = response.data.data;
          setUnidadesOrganizativas(data);
        }
        setIsLoading(false)
      } catch (error) {
        navigate("/", { replace: true });
        setIsLoading(false)
        console.log(error);
      }
    })()
  }, []);

  const handledRefresh = () => {
    window.location.reload();
  };

  const handledChange = (e) => {
    setFormCargaMasiva({
      ...formCargaMasiva,
      [e.target.name]: e.target.value,
    });
  };

  const handledSubmitPreview = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, complete todos los campos.",
        confirmButtonColor: "#dc3545",
      });
      setIsLoading(false);
      setValidated(true);
      return false;
    } else {
      //escenarios dependiendo del tipo de carga
      if (formCargaMasiva["tipoCarga"] === "BAJAS") {
        /*    if (fileSelected.name == "CESADOS_MES_AÑO.xlsx") {
         */

        //escenario de bajas
        if (fileSelected) {
          let formData = new FormData();
          formData.append("file", fileSelected);
          formData.append("perfil", formCargaMasiva["perfil"]);
          formData.append("tipoCarga", formCargaMasiva["tipoCarga"]);

          //validar que el tipo de archivo sea excel xlsx
          if (fileSelected.name.split(".").pop() !== "xlsx") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no es un archivo excel.",
              confirmButtonColor: "#dc3545",
            });

            setIsLoading(false);
            setModalShow(false);
          }

          let workbook = new Excel.Workbook();

          //validar que que exista la hoja CESADOS y que contenga datos

          let validate_hoja = await workbook.xlsx.load(fileSelected);
          let datos_hoja = validate_hoja.getWorksheet("CESADOS");

          if (datos_hoja === undefined) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene la hoja CESADOS.",
              confirmButtonColor: "#dc3545",
            });

            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          let hoja_filas = validate_hoja.rowCount;
          let hoja_columnas = validate_hoja.columnCount;
          let columnas = datos_hoja.getRow(1).values;
          let row = 2;

          if (hoja_filas === 0 || hoja_columnas === 0) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene datos.",
              confirmButtonColor: "#dc3545",
            });

            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          if (columnas[1] !== "T.Doc." || columnas[2] !== "N°Documento") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene las columnas necesarias.",
              confirmButtonColor: "#dc3545",
            });
            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          if (datos_hoja.getRow(row).values[1] === undefined) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene datos.",
              confirmButtonColor: "#dc3545",
            });
            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          let data_send = [];

          while (datos_hoja.getRow(row).values[1] !== undefined) {
            data_send.push({
              "T.Doc.": datos_hoja.getRow(row).values[1],
              "N°Documento": datos_hoja.getRow(row).values[2],
            });
            row++;
          }

          //enviar los datos al backend
          let back_response = await axios.post(
            "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/carga-masiva-bajas",
            {
              data_send: data_send,
              perfil_send: formCargaMasiva["perfil"],
              tipoCarga_send: formCargaMasiva["tipoCarga"],
              usuario_send: loggedUser.name,
            }
          );

          let backend_data_preview = back_response.data.data;

          if (backend_data_preview.data.count_total > 0) {
            setPreviewModificado(backend_data_preview);
            setModalShow(true);
            setIsLoading(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene datos.",
              confirmButtonColor: "#dc3545",
            });
            setIsLoading(false);
            setModalShow(false);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Por favor, seleccione un archivo.",
            confirmButtonColor: "#dc3545",
          });
          setIsLoading(false);
        }

        /* 

        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "El archivo seleccionado no es el correcto.",
            confirmButtonColor: "#dc3545",
          });
          setIsLoading(false);
        } */
      } else {
        //escenario de altas
        /*     console.log("====================================");
        console.log("ESCEANRIO DE ALTAS");
        console.log("===================================="); */

        /*     if (fileSelected.name == "INGRESOS_MES_AÑO.xlsx") {


 */
        //escenario de altas
        if (fileSelected) {
          let formData = new FormData();
          formData.append("file", fileSelected);
          formData.append("perfil", formCargaMasiva["perfil"]);
          formData.append("tipoCarga", formCargaMasiva["tipoCarga"]);

          //validar que el tipo de archivo sea excel xlsx
          if (fileSelected.name.split(".").pop() !== "xlsx") {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no es un archivo excel.",
              confirmButtonColor: "#dc3545",
            });

            setIsLoading(false);
            setModalShow(false);
          }

          let workbook = new Excel.Workbook();

          //validar que que exista la hoja CESADOS y que contenga datos

          let validate_hoja = await workbook.xlsx.load(fileSelected);
          let datos_hoja = validate_hoja.getWorksheet("INGRESOS");

          if (datos_hoja === undefined) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene la hoja INGRESOS.",
              confirmButtonColor: "#dc3545",
            });

            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          let hoja_filas = validate_hoja.rowCount;
          let hoja_columnas = validate_hoja.columnCount;
          let columnas = datos_hoja.getRow(1).values;
          let row = 2;

          if (hoja_filas === 0 || hoja_columnas === 0) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene datos.",
              confirmButtonColor: "#dc3545",
            });

            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          if (
            columnas[1] !== "Tipo Documento" ||
            columnas[2] !== "Numero Documento" ||
            columnas[3] !== "Apellidos y Nombres" ||
            columnas[4] !== "Desc. Posición" ||
            columnas[5] !== "Gerencia" ||
            columnas[6] !== "Unid.Organizativa 3" ||
            columnas[7] !== "Unid.Organizativa 2" ||
            columnas[8] !== "Unid.Organizativa 1" ||
            columnas[9] !== "ESPECIALIDAD" ||
            columnas[10] !== "DEPARTAMENTO" ||
            columnas[11] !== "AREA" ||
            columnas[12] !== "Criticidad" ||
            columnas[13] !== "Fec. Ingreso" ||
            columnas[14] !== "Perfil" ||
            columnas[15] !== "Puesto Archivo"
          ) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene el formato correcto.",
              confirmButtonColor: "#dc3545",
            });
            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          if (datos_hoja.getRow(row).values[1] === undefined) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene datos.",
              confirmButtonColor: "#dc3545",
            });
            setIsLoading(false);
            setModalShow(false);

            return false;
          }

          let data_send = [];

          console.log("====================================");
          console.log("PERFIL", formCargaMasiva["perfil"]);
          console.log("====================================");

          //validar que las unidades organizativas deacuerdo al perfil existan
          while (datos_hoja.getRow(row).values[1] !== undefined) {
            if (formCargaMasiva["perfil"] === "ASISTENCIAL") {
              let gerencia = unidadesOrganizativas.data.lista_gerencias.find(
                (gerencia) =>
                  gerencia.gerencia === datos_hoja.getRow(row).values[5]
              );

              let unidad_org_3 =
                unidadesOrganizativas.data.lista_unidad_org_3.find(
                  (unidad_org_3) =>
                    unidad_org_3.unidad_org_3 ===
                    datos_hoja.getRow(row).values[6]
                );
              let unidad_org_2 =
                unidadesOrganizativas.data.lista_unidad_org_2.find(
                  (unidad_org_2) =>
                    unidad_org_2.unidad_org_2 ===
                    datos_hoja.getRow(row).values[7]
                );
              let unidad_org_1 =
                unidadesOrganizativas.data.lista_unidad_org_1.find(
                  (unidad_org_1) =>
                    unidad_org_1["Unid.Organizativa 1"] ===
                    datos_hoja.getRow(row).values[8]
                );

              let puesto_archivo =
                unidadesOrganizativas.data.lista_puestos_archivo_asistencial.find(
                  (puesto_archivo) =>
                    puesto_archivo["PUESTO ARCHIVO"] ===
                    datos_hoja.getRow(row).values[15]
                );

              if (!gerencia) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE LA GERENCIA",
                });
              } else if (!unidad_org_3) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE LA UNIDAD ORGANIZATIVA 3",
                });
              } else if (!unidad_org_2) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE LA UNIDAD ORGANIZATIVA 2",
                });
              } else if (!unidad_org_1) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE LA UNIDAD ORGANIZATIVA 1",
                });
              } else if (!puesto_archivo) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE EL PUESTO ARCHIVO",
                });
              } else {
                //formato de fecha de ingreso a dd/mm/yyyy
                let fecha_ingreso = new Date(datos_hoja.getRow(row).values[13]);
                fecha_ingreso.setHours(fecha_ingreso.getHours() + 5);
                let fecha_ingreso_format = new Date(fecha_ingreso).toLocaleString();

                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": fecha_ingreso_format,
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "OK",
                });
              }

              row++;
            } else {

              console.log("====================================");
              console.log(unidadesOrganizativas.data.lista_especialidades);
              console.log("====================================");
              let especialidad =
                unidadesOrganizativas.data.lista_especialidades.find(
                  (especialidad) =>
                    especialidad["Desc. Posición"] ===
                    datos_hoja.getRow(row).values[9]
                );

              let departamento = unidadesOrganizativas.data.lista_areas.find(
                (departamento) =>
                  departamento["Desc. Área Personal"] ===
                  datos_hoja.getRow(row).values[10]
              );
              let puesto_archivo =
                unidadesOrganizativas.data.lista_puestos_archivo_staff.find(
                  (puesto_archivo) =>
                    puesto_archivo["PUESTO ARCHIVO"].trim() ===
                    datos_hoja.getRow(row).values[15].trim()
                );

              if (!especialidad) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE LA ESPECIALIDAD",
                });
              } else if (!departamento) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE EL DEPARTAMENTO",
                });
              } else if (!puesto_archivo) {
                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": datos_hoja.getRow(row).values[13],
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "NO EXISTE EL PUESTO ARCHIVO",
                });
              } else {
                //formato de fecha de ingreso a dd/mm/yyyy
                let fecha_ingreso = new Date(datos_hoja.getRow(row).values[13]);
                fecha_ingreso.setHours(fecha_ingreso.getHours() + 5)
                let fecha_ingreso_format = new Date(fecha_ingreso).toLocaleString()
                console.log("====================================");
                console.log("FECHA INGRESO", fecha_ingreso.toLocaleDateString());
                console.log("====================================");

                data_send.push({
                  "T.Doc.": datos_hoja.getRow(row).values[1],
                  "N°Documento": datos_hoja.getRow(row).values[2],
                  "Apellidos y Nombres": datos_hoja.getRow(row).values[3],
                  "Desc. Posición": datos_hoja.getRow(row).values[4],
                  gerencia: datos_hoja.getRow(row).values[5],
                  unidad_org_3: datos_hoja.getRow(row).values[6],
                  unidad_org_2: datos_hoja.getRow(row).values[7],
                  "Unid.Organizativa 1": datos_hoja.getRow(row).values[8],
                  ESPECIALIDAD: datos_hoja.getRow(row).values[9],
                  DEPARTAMENTO: datos_hoja.getRow(row).values[10],
                  AREA: datos_hoja.getRow(row).values[11],
                  CRITICIDAD: datos_hoja.getRow(row).values[12],
                  "Fec. Ingreso": fecha_ingreso_format,
                  medico_perfil: datos_hoja.getRow(row).values[14],
                  "PUESTO ARCHIVO": datos_hoja.getRow(row).values[15],
                  detalle: "OK",
                });
              }

              row++;
            }
          }

          console.log("====================================");
          console.log("DATA SEND", data_send);
          console.log("====================================");

          //enviar los datos al backend
          let back_response = await axios.post(
            "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/carga-masiva-altas",
            {
              data_send: data_send,
              perfil_send: formCargaMasiva["perfil"],
              tipoCarga_send: formCargaMasiva["tipoCarga"],
              usuario_send: loggedUser.name,
            }
          );

          let backend_data_preview = back_response.data.data;

          if (backend_data_preview.data.count_total > 0) {
            setPreviewModificado(backend_data_preview);
            setModalShow(true);
            setIsLoading(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "El archivo seleccionado no contiene datos.",
              confirmButtonColor: "#dc3545",
            });
            setIsLoading(false);
            setModalShow(false);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Por favor, seleccione un archivo.",
            confirmButtonColor: "#dc3545",
          });
          setIsLoading(false);
        }
      }
    }
  };

  const goBack = () => {
    navigate("/dashboardUpdate");
  };

  const propetiesTimeline = [
    {
      title: "1. Seleccione el tipo de carga",
      description: "Seleccione el tipo de carga que desea realizar alta o baja",
      icon: <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
    },
    {
      title: "2. Seleccione el perfil",
      description:
        "Seleccione el perfil que desea realizar planilla o staff",
      icon: <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
    },
    {
      title: "3. Seleccione el archivo",
      description:
        "Seleccione el archivo que desea realizar la carga el mismo debe ser un archivo excel",
      icon: <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
    },
    {
      title: "4. Cargar",
      description: "Presione el botón cargar para iniciar el proceso de carga",
      icon: <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
    },
    {
      title: "5. Previsualizar",
      description: "Previsualice los datos a cargar y confirme",
      icon: <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
    },
    {
      title: "6. Confirmar",
      description: "Confirme los datos a cargar y presione el botón confirmar",
      icon: <FontAwesomeIcon icon={faCheckCircle} size="2x" />,
    },
  ];

  return (
    accesosAdicionales &&
    <div>
      <MenuPrincipal accesos={accesosAdicionales} />
      <Container fluid>
        <Row col={12} className="mb-4">
          <Row className="d-flex ">
            <Col xs={12} className="d-flex justify-content-between">
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon
                  onClick={goBack}
                  icon={faChevronLeft}
                  size="2x"
                  className="mr-2 cursor-pointer"
                />
                <h3>
                  Carga Masiva
                  <span className="ml-3" onClick={handledRefresh}>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      size="xs"
                      color="#00A884"
                      icon={faRefresh}
                      id="refresh"
                    />
                  </span>
                </h3>
              </div>
              <div>
                {/* <p className="text-muted">
                  Usuario: {loggedUser ? loggedUser.name : ""}
                </p> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md={3} className="mb-3">
              <Form
                noValidate
                validated={validated}
                onSubmit={handledSubmitPreview}
              >
                <Col md={5} className="m-3">
                  <Form.Group controlId="formTipoCarga">
                    <Form.Label>
                      <b>Tipo de Carga</b>
                    </Form.Label>
                    <Form.Select
                      name="tipoCarga"
                      value={formCargaMasiva["tipoCarga"]}
                      onChange={handledChange}
                      required
                    >
                      <option value="">Seleccione...</option>
                      <option value="BAJAS">BAJAS</option>
                      <option value="ALTAS">ALTAS</option>
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={5} className="m-3">
                  <Form.Group controlId="formPerfil">
                    <Form.Label>
                      <b>Perfil</b>
                    </Form.Label>
                    <Form.Select
                      name="perfil"
                      value={formCargaMasiva["perfil"]}
                      onChange={handledChange}
                      required
                    >
                      <option value="">Seleccione...</option>
                      <option value="ASISTENCIAL">PLANILLA</option>
                      <option value="STAFF">STAFF</option>
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={10} className="m-3">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      <b>Archivo</b>
                    </Form.Label>
                    <Form.Control
                      required
                      type="file"
                      onChange={(e) => {
                        setFileSelected(e.target.files[0]);
                      }}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={10} className="m-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100"
                  >
                    Cargar
                  </Button>
                </Col>
              </Form>
            </Col>
            <Col md={8} className="m-3" id="detalleCargaMasiva" style={{ position: "relative" }}>
              {/* Colocar un gif de un medico  con la linea giphy  imagen : https://image.freepik.com/vector-gratis/linda-ilustracion-medico-dibujos-animados-estilo-handrawn_53734-77.jpg*/}

              <div id="imgDoctor">
                <img
                  src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExd2ltMG55eGt6Zm00dWw1bW9udmE3aTR0ajFodTJ3eTY4bDQwNnh3aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/A9MftKr3J3lra/giphy.gif"
                  alt="Carga Masiva"
                  width="340px"
                />
              </div>

              <Row className="mb-3">
                <Col md={12}>
                  <h4>Proceso para la carga masiva</h4>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {propetiesTimeline.map((item, index) => {
                    return (
                      <Row key={index} className="mb-3">
                        <Col md={1} id="icono">
                          <div className="d-flex justify-content-center">
                            <div
                              id="iconoInterno"
                              className="d-flex justify-content-center align-items-center"
                            >
                              {item.icon}
                            </div>
                          </div>
                        </Col>
                        <Col md={11}>
                          <Row>
                            <Col md={12}>
                              <h5>{item.title}</h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <p className="text-muted">{item.description}</p>
                              {index === 2 ? (
                                <p className="text-muted">
                                  Puede descargar el formato de archivo excel
                                  para la carga masiva desde los siguientes
                                  enlaces:
                                </p>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          {index === 2 ? (
                            <Row>
                              <Col md={12}>
                                <p className="text-muted">
                                  <FontAwesomeIcon
                                    icon={faFileExcel}
                                    color="#00A884"
                                    className="mr-2"
                                  />
                                  <a href="https://clinicainternacional.doctorfile.pe/reportes/INGRESOS_MES_AÑO.xlsx">
                                    Formato de archivo excel para Altas
                                  </a>
                                </p>
                                <p className="text-muted">
                                  <FontAwesomeIcon
                                    icon={faFileExcel}
                                    color="#00A884"
                                    className="mr-2"
                                  />
                                  <a href="https://clinicainternacional.doctorfile.pe/reportes/CESADOS_MES_AÑO.xlsx">
                                    Formato de archivo excel para Bajas
                                  </a>
                                </p>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
        {isLoading ? <LoadingPage /> : ""}
      </Container>
      {formCargaMasiva["tipoCarga"] === "ALTAS" ? (
        <ModalAltasMasivas
          data={previewModificado}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      ) : (
        <ModalBajasMasivas
          data={previewModificado}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      )}
    </div>
  );
}

export default DashboardMasivo;
