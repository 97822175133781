import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { fa1, fa2, fa3, faChevronLeft, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import MenuPrincipal from '../../components/navbar';
import axios from 'axios';
import { verificarAcceso } from '../../components/utils';
import ProcessLoading from '../../components/processLoading';
import moment from 'moment/moment';

function DashboardReportes() {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [accesosAdicionales, setAccesosAdicionales] = useState()
  const workbook = new Excel.Workbook();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const propetiesTimeline = [
    {
      description: "Haz clic en uno de los 4 informes que se muestran a continuación.",
      icon: <FontAwesomeIcon icon={fa1} />,
    },
    {
      description:
        "La descarga del informe puede tardar unos minutos debido a la cantidad de información que contiene.",
      icon: <FontAwesomeIcon icon={fa2} />,
    },
    {
      description:
        "Como ayuda adicional, la fecha y hora de actualización del informe se reflejan en el nombre del archivo XLSX descargado.",
      icon: <FontAwesomeIcon icon={fa3} />,
    }
  ];
  const propetiesTimeline2 = [
    {
      description: <><b>Completo:</b> Muestra a todos los Cuidadores ACTIVOS de ASISTENCIAL, mostrando sus datos, las áreas organizativas a la cual pertenecen, y el estado de los tipos de documentos asociados al Cuidador.</>,
      icon: <FontAwesomeIcon icon={fa1} />,
    },
    {
      description: <><b>Resumen:</b> Muestra a todos los Cuidadores ACTIVOS de ASISTENCIAL, mostrando los indicadores de tipo de documentos que han sido subidos por el Cuidador y por RRHH.</>,
      icon: <FontAwesomeIcon icon={fa2} />,
    }
  ];
  const propetiesTimeline3 = [
    {
      description: <><b>Completo:</b> Muestra a todos los Médicos ACTIVOS de STAFF, mostrando sus datos, las áreas organizativas (especialidad y departamento) y el estado de los tipos de documentos asociados al Médico.</>,
      icon: <FontAwesomeIcon icon={fa1} />,
    },
    {
      description: <><b>Resumen:</b> Muestra a todos los Médicos ACTIVOS de ASISTENCIAL, mostrando los indicadores de tipo de documentos que han sido subidos por el Médico y por RRHH.</>,
      icon: <FontAwesomeIcon icon={fa2} />,
    }
  ];
  const reportes = [{ h: "Planilla", v: "Planilla" }, { h: "Staff", v: "Staff" }, { h: "Planilla", v: "Resumen Planilla" }, { h: "Staff", v: "Resumen Staff" }]

  const handleExportExcel = async (mod) => {
    let m = moment();
    let d = m.format("DD-MM-YYYY");
    let h = m.format("_HH_mm");
    try {
      setIsPageLoading(true)
      setIsLoading(true)
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://servicios.doctorfile.pe/apiv2/',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ ci_reporte: mod.toUpperCase() })
      };
      const { data } = await axios.request(config)
      if (data?.count > 0) {
        let excelData = data?.data;
        const fileName = ("Reporte " + mod + " " + d + h).trim();
        let conf1 = { bold: true, color: { argb: 'FFFFFF' } };
        let conf2 = (column, idx) => {
          column.width = 20;
          column.alignment = { horizontal: "center" };
        };
        let conf3 = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        /**HOJA PRINCIPAL**/
        const worksheet = workbook.addWorksheet(mod + " " + d);
        let columns = []
        //let columFilter = []
        switch (mod) {
          case "Planilla":
            columns = [
              { header: "T.doc", key: "T.doc" },
              { header: "N° Documento", key: "N°Documento" },
              { header: "Apellidos y Nombres", key: "Apellidos y Nombres" },
              { header: "Fec. Ingreso", key: "Fec. Ingreso" },
              { header: "Desc. Posición", key: "Desc. Posición" },
              { header: "Gerencia", key: "gerencia" },
              { header: "Unid. Organizativa 3", key: "Unid.Organizativa 3" },
              { header: "Unid. Organizativa 2", key: "Unid.Organizativa 2" },
              { header: "Unid. Organizativa 1", key: "Unid.Organizativa 1" },
              { header: "Criticidad", key: "CRITICIDAD" },
              { header: "Perfil", key: "medico_perfil" },
              { header: "Codigo de documento", key: "Codigo de documento" },
              { header: "Documento Indispensable", key: "documento_indispensable" },
              { header: "Encargado", key: "encargado_subir_archivo" },
              { header: "Tipos de documento", key: "Tipos de documento" },
              { header: "Estado Archivo", key: "status_archivo" },
              { header: "Conteo", key: "count", numeric: true }
            ];
            break;
          case "Staff":
            columns = [
              { header: "T.doc", key: "T.doc" },
              { header: "N° Documento", key: "N°Documento" },
              { header: "Apellidos y Nombres", key: "Apellidos y Nombres" },
              { header: "Fec. Ingreso", key: "Fec. Ingreso" },
              { header: "Especialidad", key: "especialidad" },
              { header: "Área", key: "area_personal_departamento" },
              { header: "Criticidad", key: "CRITICIDAD" },
              { header: "Perfil", key: "medico_perfil" },
              { header: "Codigo de documento", key: "Codigo de documento" },
              { header: "Documento indispensable", key: "documento_indispensable" },
              { header: "Encargado", key: "encargado_subir_archivo" },
              { header: "Tipos de documento", key: "Tipos de documento" },
              { header: "Estado Archivo", key: "status_archivo" },
              { header: "Conteo", key: "count", numeric: true }
            ];
            break;
          case "Resumen Planilla":
            columns = [
              { header: "T. Doc", key: "T.doc" },
              { header: "N° Documento", key: "N°Documento" },
              { header: "Apellidos y Nombres", key: "Apellidos y Nombres" },
              { header: "Desc. Posición", key: "Desc. Posición" },
              { header: "Critico", key: "CRITICIDAD" },
              { header: "Gerencia", key: "gerencia" },
              { header: "Unid. Organizativa 3", key: "Unid.Organizativa 3" },
              { header: "Unid. Organizativa 2", key: "Unid.Organizativa 2" },
              { header: "Unid. Organizativa 1", key: "Unid.Organizativa 1" },
              { header: "Doc. completados cuidador", key: "completados_cuidador", numeric: true },
              { header: "Doc. requeridos cuidador", key: "requeridos_cuidador", numeric: true },
              { header: "% Avance Cuidador", key: "porcentaje_avance_cuidador", percent: true },
              { header: "Doc. completados RRHH", key: "completados_rrhh", numeric: true },
              { header: "Doc. requeridos RRHH", key: "requeridos_rrhh", numeric: true },
              { header: "% Avance RRHH", key: "porcentaje_avance_rrhh", percent: true },
              { header: "Doc. completados general", key: "completados_general", numeric: true },
              { header: "Total Doc. requerido", key: "total_requerido", numeric: true },
              { header: "% Avance general", key: "porcentaje_avance_general", percent: true }
            ]
            break;
          case "Resumen Staff":
            columns = [
              { header: "T. Doc", key: "T.doc" },
              { header: "N° Documento", key: "N°Documento" },
              { header: "Apellidos y Nombres", key: "Apellidos y Nombres" },
              { header: "Critico", key: "CRITICIDAD" },
              { header: "Especialidad", key: "especialidad" },
              { header: "Departamento", key: "area_personal" },
              { header: "Doc. completados por cuidador", key: "completados_cuidador", numeric: true },
              { header: "Doc. requeridos a cuidador", key: "requeridos_cuidador", numeric: true },
              { header: "% Avance cuidador", key: "porcentaje_avance_cuidador", percent: true },
              { header: "Doc. completados por RRHH", key: "completados_rrhh", numeric: true },
              { header: "Doc. requeridos a RRHH", key: "requeridos_rrhh", numeric: true },
              { header: "% Avance RRHH", key: "porcentaje_avance_rrhh", percent: true },
              { header: "Doc. completados en general", key: "completados_general", numeric: true },
              { header: "Total Doc. requerido", key: "total_requerido", numeric: true },
              { header: "% Avance general", key: "porcentaje_avance_general", percent: true }
            ]
            break;
          default:
            break;
        }
        //columns.forEach((e) => columFilter.push({ name: e.header, filterButton: true }))
        worksheet.columns = columns;
        worksheet.getRow(1).font = conf1;
        worksheet.columns.forEach(conf2);
        excelData.forEach((singleData) => {
          worksheet.addRow(singleData);
        });
        worksheet.eachRow({ includeEmpty: true }, (row, idxRow) => {
          const currentCell = row._cells;
          currentCell.forEach((singleCell, idx) => {
            const cellAddress = singleCell._address;
            let cell = worksheet.getCell(cellAddress)
            cell.border = conf3;
            if (idxRow % 2 === 0) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E1F0DA' } };
            if (idxRow === 1) cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99BC85' } }
            else {
              if (columns[idx].percent) {
                cell.value = parseFloat(cell.value);
                cell.numFmt = '0%';
              } else if (columns[idx].numeric) cell.value = parseFloat(cell.value)
            }
          });
        });
        /**HOJA TABLA */
        /*let rowsT = [];
        const tabStyle = { theme: "TableStyleMedium2", showRowStripes: true };
        worksheet.eachRow({ includeEmpty: true }, (row, idx) => {
          if (idx > 1) {
            rowsT.push(row.values.slice(1));
          }
        });
        const worksheetT = workbook.addWorksheet("Resumen");
        worksheetT.addTable({
          name: "Resumen",
          ref: "A1",
          style: tabStyle,
          columns: columFilter,
          rows: rowsT,
        });
        workbook.removeWorksheet(mod);*/
        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      }
      setIsLoading(false);
      setIsPageLoading(false)
    } catch (e) {
      setIsLoading(false);
      setIsPageLoading(false)
      console.log(e.message);
    } finally {
      workbook.removeWorksheet(mod + " " + d);
    }
  };

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          let accesos = await verificarAcceso(user.ext)
          if (!accesos?.adm && !accesos?.v4) {
            navigate("/", { replace: true });
            return
          }
          setAccesosAdicionales(accesos)
          setIsPageLoading(false)
        } catch (error) {
          console.log(error)
          setIsPageLoading(false)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const CCol = (val, key) => <Col key={key}><Button style={{ minWidth: 100 }} onClick={() => handleExportExcel(val?.v)} disabled={isLoading}>{isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : <>{val?.h}<FontAwesomeIcon icon={faFileExcel} className='ms-2' /></>}</Button></Col>

  return (
    accesosAdicionales && <>
      <MenuPrincipal isLoading={isPageLoading} accesos={accesosAdicionales} />
      <Container fluid>
        <Row className="d-flex ">
          <Col xs={12} className="d-flex justify-content-between">
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon onClick={() => navigate("/", { replace: true })} icon={faChevronLeft} size="2x" className="mr-2 cursor-pointer" />
              <h3>Zona de reportes</h3>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col lg={4} className="mb-3 px-4">
            <p style={{ maxWidth: 800 }}>Selecciona el reporte que necesites: Planilla o Staff.</p>
            <p style={{ fontWeight: 500 }}>Completo:</p>
            <Row xs={"auto"} className='mb-3'>{reportes.filter((e) => !e.v.includes("Resumen")).map(CCol)}</Row>
            <p style={{ fontWeight: 500 }}>Resumen:</p>
            <Row xs={"auto"} className='mb-3'>{reportes.filter((e) => e.v.includes("Resumen")).map(CCol)}</Row>
          </Col>
          <Col lg={8} id="PasosReportesDwl" style={{ position: "relative" }}>
            <div className="m-3" style={{ position: "relative", height: "auto" }}>
              <div id="imgDoctor">
                <img alt="imgDoctor" width="340px" src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExd2ltMG55eGt6Zm00dWw1bW9udmE3aTR0ajFodTJ3eTY4bDQwNnh3aSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/A9MftKr3J3lra/giphy.gif" />
              </div>
              <Row className="mb-1">
                <Col md={12}>
                  <h5>¿Cómo descargar el reporte?</h5>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md={12}>
                  {propetiesTimeline.map((item, index) => {
                    return (
                      <Row key={index} className="mb-2">
                        <Col md={1} id="icono">
                          <div className="d-flex justify-content-center">
                            <div id="iconoInterno2" className="d-flex justify-content-center align-items-center">
                              {item.icon}
                            </div>
                          </div>
                        </Col>
                        <Col md={11} className='d-flex align-items-center'>
                          <p className="text-muted">{item.description}</p>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <Row className="mb-1">
                <Col md={12}>
                  <h5>¿Qué reportes ofrece la plataforma?</h5>
                </Col>
              </Row>
              <p style={{ fontWeight: 500 }}>Para Planilla:</p>
              <Row className='spaceImage'>
                <Col md={12}>
                  <ul style={{ listStyle: "square", fontSize: 15 }}>
                    {propetiesTimeline2.map((item, index) => <li className="text-muted" key={index}>{item.description}</li>)}
                  </ul>
                </Col>
              </Row>
              <p style={{ fontWeight: 500 }}>Para Staff:</p>
              <Row className='spaceImage'>
                <Col md={12}>
                  <ul style={{ listStyle: "square", fontSize: 15, marginBottom: 0 }}>
                    {propetiesTimeline3.map((item, index) => <li className="text-muted" key={index}>{item.description}</li>)}
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {isPageLoading && <ProcessLoading label={isPageLoading} />}
    </>
  )
}

export default DashboardReportes