import axios from "axios";
import Swal from "sweetalert2";

var mapaTildes = {
  á: "a",
  é: "e",
  í: "i",
  ó: "o",
  ú: "u",
  ü: "u",
  ñ: "n",
  Á: "A",
  É: "E",
  Í: "I",
  Ó: "O",
  Ú: "U",
  Ü: "U",
  Ñ: "N",
};

const calcularVigencia = (l) => {
  if (!l || l.length < 8) return "SI";
  let arr1 = l.split("/");
  let v = arr1.findIndex((e) => Number(e) > 2023);
  let arr2 = l.split("-");
  let v2 = arr2.findIndex((e) => Number(e) > 2023);
  return v > -1 || v2 > -1 ? "SI" : "NO";
};

function limpiarTexto(texto) {
  var textoSinTildes = texto.replace(
    /[áéíóúüñÁÉÍÓÚÜÑ]/g,
    function (letraConTilde) {
      return mapaTildes[letraConTilde];
    }
  );
  var textoModificado = textoSinTildes.replace(/'/g, "");
  return textoModificado;
}

const uploadF = async (file, onUploadProgress) => {
  console.log(file);
};

const messageAlert = async (title, text, timer) => {
  let timerInterval;
  Swal.fire({
    title: title,
    text: text,
    timer: timer || 1000,
    timerProgressBar: true,
    didOpen: () => {
      Swal.showLoading();
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  });
};

const getFromS3 = async (uri, mode) => {
  try {
    let response = null;
    if (mode === 1)
      response = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/url-prefirmada",
        JSON.stringify({ key: uri })
      );
    else
      response = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/url-prefirmada-2",
        JSON.stringify({ key: uri })
      );
    let dS3 = response.data;
    if (dS3?.data?.count > 0) {
      return dS3?.data?.data;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

//el numero de documento debe tener 8 digitos u 11 digitos
const validateLengthNumberDoc = (nro_doc) => {
  if (nro_doc.length < 8 || nro_doc.length > 11) {
    return false;
  }
  return true;
};

const validateTypeNumberDoc = (nro_doc) => {
  if (!/^[0-9]+$/.test(nro_doc)) {
    return false;
  }
  return true;
};

const cleanBlankSpacesAndToUpperCase = (text) => {
  return text.trim().toUpperCase();
};


//generar cetiqueta de estado de carga
const Etiqueta = ({ estado }) => {
  // YA INACTIVO , NO ENCONTRADO , PARA INACTIVAR, VACIO
  if (estado === 'YA INACTIVO' || estado === 'YA EXISTE') {
    return (
      <span className="badge bg-success text-white">{estado}</span>
    )
  }
  if (estado === 'NO ENCONTRADO') {
    return (
      <span className="badge bg-danger text-white">{estado}</span>
    )
  }
  if (estado === 'PARA INACTIVAR' || estado === 'DAR DE ALTA') {
    return (
      <span className="badge bg-warning text-dark">{estado}</span>
    )
  }
  if (estado === 'LICENCIA') {
    return (
      <span className="badge bg-info text-white">{estado}</span>
    )
  }
  if (estado === 'VACIO') {
    return (
      <span className="badge bg-secondary text-white">{estado}</span>
    )
  }
  return (
    <span className="badge bg-secondary text-white">{estado}</span>
  )
}

const verificarAcceso = async (id) => {
  try {
    let aux = {}
    const res = await axios.post(
      "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/obtener-permisos",
      JSON.stringify({ ci_id: id })
    );
    if (res.data?.data?.count > 0) {
      res.data?.data?.data.forEach(el => {
        if (el.full) aux["adm"] = true
        else aux["v" + el.id_vista] = true;
      });
      return aux
    }
    return false
  } catch (error) {
    throw error;
  }
}

const TIEMPO_ESPERA = 40 * 60 * 60 * 1000;

export {
  calcularVigencia,
  limpiarTexto,
  uploadF,
  messageAlert,
  getFromS3,
  TIEMPO_ESPERA,
  validateLengthNumberDoc,
  validateTypeNumberDoc,
  cleanBlankSpacesAndToUpperCase,
  Etiqueta,
  verificarAcceso
};
