import React, { useEffect, useState } from "react";
import MenuPrincipal from "../components/navbar";
import {
  Button,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
  Navbar,
  Nav,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faListCheck,
  faSearch,
  faPencilAlt,
  faUserPlus,
  faTable,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
//marco
import TableUpdate from "../components/tableUpdate";
import ModalRegistro from "../components/modalRegister";
import { verificarAcceso } from "../components/utils";
import axios from "axios";

function DashboardUpdate() {
  const [dataSearch, setDataSearch] = useState("");
  const [perfilSelected, setPerfilSelected] = useState("ASISTENCIAL");
  const [accesosAdicionales, setAccesosAdicionales] = useState()
  const [isSearched, setIsSearchead] = useState(false);
  const [arrPersonal, setArrPersonal] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")) || {};

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const [isRegPendRev, setIsRegPendRev] = useState(false);

  const columns_asis = [
    { id: "T.Doc.", label: "T. Doc", minWidth: 70 },
    { id: "N°Documento", label: "Nro. Documento", minWidth: 100 },
    { id: "Apellidos y Nombres", label: "Nombre", minWidth: 170 },
    { id: "CRITICIDAD", label: "Criticidad", minWidth: 100 },
    { id: "gerencia", label: "Gerencia", minWidth: 170 },
    { id: "medico_perfil", label: "Perfil", minWidth: 100 },
    { id: "Situación", label: "Estado", minWidth: 100 },
    { id: "accion", label: "Acción", minWidth: 70 },
  ];

  const columns_staff = [
    { id: "T.Doc.", label: "T. Doc", minWidth: 70 },
    { id: "N°Documento", label: "Nro. Documento", minWidth: 100 },
    { id: "Apellidos y Nombres", label: "Nombre", minWidth: 170 },
    { id: "Desc. Posición", label: "Especialidad", minWidth: 170 },
    { id: "medico_perfil", label: "Perfil", minWidth: 100 },
    { id: "Situación", label: "Estado", minWidth: 100 },
    { id: "accion", label: "Acción", minWidth: 70 },
  ];

  const handledSelect = (e) => {
    setPerfilSelected(e.target.value);
    searchPersonal(e, e.target.value);
  };

  const handleDataSearch = (e) => {
    setDataSearch(e.target.value);
  };

  const searchPersonal = async (e, perfilOpc) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let aux = dataSearch?.trim().replace(/^0+/, "");
      let perfil = perfilOpc || perfilSelected;

      //const res = await personalService.searchPersonal(dataSearch)
      const { data } = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/medicos-carga-masiva",
        JSON.stringify({ ci_valor: aux, ci_perfil: perfil })
      );

      // console.log(data);

      if (data?.data?.count > 0) {
        setArrPersonal(data?.data?.data);
      } else {
        setArrPersonal([]);
      }
      setIsSearchead(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleButtonAdd = () => {
    setModalShow(true);
  };

  useEffect(() => {
    (async () => {
      setIsPageLoading(true)
      try {
        let accesos = await verificarAcceso(user.ext)
        if (!accesos?.adm && !accesos?.v2) {
          navigate("/", { replace: true });
          return
        }
        setAccesosAdicionales(accesos)
        setIsPageLoading(false)
      } catch (error) {
        navigate("/", { replace: true });
        setIsPageLoading(false)
        console.log(error);
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    accesosAdicionales && <>
      <MenuPrincipal isLoading={isPageLoading} accesos={accesosAdicionales} />
      {/* Contenedor */}
      <Container fluid className="m-4">
        <Row col={12} className="mb-4">
          <Row className="d-flex ">
            <Col xs={12} className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  onClick={goBack}
                  icon={faChevronLeft}
                  size="2x"
                  className="mr-2 cursor-pointer"
                />
                <h3 className="ml-2">Actualizar Personal</h3>
              </div>
              <div>
                {/* Botom actualizacion 1 a 1 */}
                <Button
                  variant="primary"
                  className="m-2"
                  onClick={handleButtonAdd}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                  <span className="ms-2">Agregar Personal</span>
                </Button>
                {/* Botom actualizacion masiva */}
                <Button
                  variant="primary"
                  className="m-2"
                  onClick={() => {
                    navigate("/dashboardMasivo");
                  }}
                >
                  <FontAwesomeIcon icon={faTable} />
                  <span className="ms-2">Carga Masiva</span>
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-between">
            <Col xs={12} md={2} className="align-self-center">
              <Form.Label>Perfil:</Form.Label>
              <Form.Select
                className="m-2"
                aria-label="Default select example"
                onChange={handledSelect}
              >
                <option value="ASISTENCIAL">PLANILLA</option>
                <option value="STAFF">STAFF</option>
              </Form.Select>
            </Col>
            <Col xs={12} md={2}>
              <Form className="m-2">
                <InputGroup className="rm-input-text">
                  <Form.Control
                    placeholder="Buscar por nombre, doc. identidad, año, puesto"
                    type="text"
                    value={dataSearch}
                    onChange={handleDataSearch}
                  />
                  {dataSearch?.length > 0 && (
                    <InputGroup.Text className="rm-icon">
                      <Button
                        variant="link"
                        onClick={() => {
                          setDataSearch("");
                        }}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    </InputGroup.Text>
                  )}
                  <InputGroup.Text>
                    <button
                      type="submit"
                      className="btn"
                      onClick={searchPersonal}
                      disabled={isLoading}
                    >
                      Buscar <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </InputGroup.Text>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            {arrPersonal.length > 0 ? (
              <TableUpdate
                columns={
                  perfilSelected === "ASISTENCIAL"
                    ? columns_asis
                    : columns_staff
                }
                rows={arrPersonal}
                refIdButton={"N°Documento"}
                iconButton={[faPencilAlt]}
                isRRHH={user.rol === 2}
              />
            ) : (
              ""
            )}
            {isSearched && arrPersonal?.length < 1 && (
              <p className="text-center">No se encontraron datos</p>
            )}
          </Col>
        </Row>
      </Container>
      <ModalRegistro
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </>
  );
}

export default DashboardUpdate;
