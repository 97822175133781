import React, { useEffect, useState } from "react";
import {
  Title,
  Text,
  Card,
  Grid,
  Flex,
  Bold,
  Select,
  SelectItem,
  TabGroup,
  TabPanel,
  TabPanels,
  ProgressBar,
} from "@tremor/react";
import axios from "axios";
import { Container, Spinner } from "react-bootstrap";
import { Box } from "@mui/material";

const opcionesCritico = [
  { key: "", name: "Todos" },
  { key: "SI", name: "Critico" },
  { key: "NO", name: "No Critico" },
];

const opcionesIndispensable = [
  { key: "NO", name: "Indispensables" },
  { key: "SI", name: "Todos" },
];

const opcionesEncargado = [
  { key: "", name: "Todos" },
  { key: "C", name: "Cuidador" },
  { key: "R", name: "RRHH" },
];

const sortData = (data) =>
  data.sort((a, b) => {
    if (a.grupo < b.grupo) return -1;
    if (a.grupo > b.grupo) return 1;
    return 0;
  });

const filterByCriticidad = (cr, data) =>
  cr === "" ? data?.todo : cr === "SI" ? data?.crit : data?.nocrit;

function PanelDahboard() {
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedCritico, setSelectedCritico] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  //avance
  const [encargado, setEncargado] = useState("");
  const [isOpcional, setIsOpcional] = useState("NO");
  const [dataGeneral, setDataGeneral] = useState({});
  const [filteredDataPL, setFilteredDataPL] = useState([]);
  const [filteredDataSTF, setFilteredDataSTF] = useState([]);

  const breakpoint = 700;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (isOpcional === "SI") {
      setFilteredDataPL(
        filterByCriticidad(selectedCritico, dataGeneral["OPC-PLANILLA"])
      );
      setFilteredDataSTF(
        filterByCriticidad(selectedCritico, dataGeneral["OPC-STAFF"])
      );
    } else {
      setFilteredDataPL(
        filterByCriticidad(selectedCritico, dataGeneral["PLANILLA"])
      );
      setFilteredDataSTF(
        filterByCriticidad(selectedCritico, dataGeneral["STAFF"])
      );
    }
  }, [selectedCritico, isOpcional, dataGeneral]);

  /*const participacion = async (mode) => {
    try {
      const res = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/reporte-participacion", JSON.stringify({ ci_reporte: mode }))
      return res.data;
    } catch (error) {
      throw error;
    }
  }*/

  const avance = async (mode, opc) => {
    try {
      let payload = { ci_reporte: mode };
      if (opc) payload.ci_opcionales = opc;
      const res = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/reporte-avance",
        JSON.stringify(payload)
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  /*const fileCompleto = async (mode) => {
    try {
      const res = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/reporte-participacion", JSON.stringify({ ci_reporte: mode, ci_completado: "SI" }))
      return res.data;
    } catch (error) {
      throw error;
    }
  }*/

  const sumAndFilter = (arr, mode) => {
    let ng = mode === "PLANILLA" ? "gerencia" : "especialidad";
    const res = {};
    const resC = {};
    const resNC = {};
    arr.forEach((item) => {
      let tr = parseInt(item.total_requerido, 10);
      let tt = parseInt(item.completados_general, 10);
      let trc = parseInt(item.requeridos_cuidador, 10);
      let ttc = parseInt(item.completados_cuidador, 10);
      let trr = parseInt(item.requeridos_rrhh, 10);
      let ttr = parseInt(item.completados_rrhh, 10);
      if (res[item[ng]]) {
        res[item[ng]] = {
          grupo: item[ng],
          total_requerido: tr + res[item[ng]].total_requerido,
          completados_general: tt + res[item[ng]].completados_general,
          porcentaje:
            (tt + res[item[ng]].completados_general) /
            (tr + res[item[ng]].total_requerido),
          total_requerido_c: trc + res[item[ng]].total_requerido_c,
          completados_general_c: ttc + res[item[ng]].completados_general_c,
          porcentaje_c:
            (ttc + res[item[ng]].completados_general_c) /
            (trc + res[item[ng]].total_requerido_c),
          total_requerido_r: trr + res[item[ng]].total_requerido_r,
          completados_general_r: ttr + res[item[ng]].completados_general_r,
          porcentaje_r:
            (ttr + res[item[ng]].completados_general_r) /
            (trr + res[item[ng]].total_requerido_r),
        };
      } else {
        res[item[ng]] = {
          grupo: item[ng],
          total_requerido: tr,
          completados_general: tt,
          porcentaje: tt / tr,
          total_requerido_c: trc,
          completados_general_c: ttc,
          porcentaje_c: ttc / trc,
          total_requerido_r: trr,
          completados_general_r: ttr,
          porcentaje_r: ttr / trr,
        };
      }
      if (item.CRITICIDAD === "SI") {
        if (resC[item[ng]]) {
          resC[item[ng]] = {
            grupo: item[ng],
            total_requerido: tr + resC[item[ng]].total_requerido,
            completados_general: tt + resC[item[ng]].completados_general,
            porcentaje:
              (tt + resC[item[ng]].completados_general) /
              (tr + resC[item[ng]].total_requerido),
            total_requerido_c: trc + resC[item[ng]].total_requerido_c,
            completados_general_c: ttc + resC[item[ng]].completados_general_c,
            porcentaje_c:
              (ttc + resC[item[ng]].completados_general_c) /
              (trc + resC[item[ng]].total_requerido_c),
            total_requerido_r: trr + resC[item[ng]].total_requerido_r,
            completados_general_r: ttr + resC[item[ng]].completados_general_r,
            porcentaje_r:
              (ttr + resC[item[ng]].completados_general_r) /
              (trr + resC[item[ng]].total_requerido_r),
          };
        } else {
          resC[item[ng]] = {
            grupo: item[ng],
            total_requerido: tr,
            completados_general: tt,
            porcentaje: tt / tr,
            total_requerido_c: trc,
            completados_general_c: ttc,
            porcentaje_c: ttc / trc,
            total_requerido_r: trr,
            completados_general_r: ttr,
            porcentaje_r: ttr / trr,
          };
        }
      } else {
        if (resNC[item[ng]]) {
          resNC[item[ng]] = {
            grupo: item[ng],
            total_requerido: tr + resNC[item[ng]].total_requerido,
            completados_general: tt + resNC[item[ng]].completados_general,
            porcentaje:
              (tt + resNC[item[ng]].completados_general) /
              (tr + resNC[item[ng]].total_requerido),
            total_requerido_c: trc + resNC[item[ng]].total_requerido_c,
            completados_general_c: ttc + resNC[item[ng]].completados_general_c,
            porcentaje_c:
              (ttc + resNC[item[ng]].completados_general_c) /
              (trc + resNC[item[ng]].total_requerido_c),
            total_requerido_r: trr + resNC[item[ng]].total_requerido_r,
            completados_general_r: ttr + resNC[item[ng]].completados_general_r,
            porcentaje_r:
              (ttr + resNC[item[ng]].completados_general_r) /
              (trr + resNC[item[ng]].total_requerido_r),
          };
        } else {
          resNC[item[ng]] = {
            grupo: item[ng],
            total_requerido: tr,
            completados_general: tt,
            porcentaje: tt / tr,
            total_requerido_c: trc,
            completados_general_c: ttc,
            porcentaje_c: ttc / trc,
            total_requerido_r: trr,
            completados_general_r: ttr,
            porcentaje_r: ttr / trr,
          };
        }
      }
    });
    return {
      todo: Object.values(res),
      crit: Object.values(resC),
      nocrit: Object.values(resNC),
    };
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let dataG = { PLANILLA: [], STAFF: [] };
        const [p1, p2, p3, p4] = await Promise.all([
          avance("PLANILLA"),
          avance("STAFF"),
          avance("PLANILLA", "SI"),
          avance("STAFF", "SI"),
        ]);

        if (p1?.data?.count > 0) {
          dataG.PLANILLA = sumAndFilter(p1?.data?.data, "PLANILLA");
        }
        if (p2?.data?.count > 0) {
          dataG.STAFF = sumAndFilter(p2?.data?.data, "STAFF");
        }
        if (p3?.data?.count > 0) {
          dataG["OPC-PLANILLA"] = sumAndFilter(p3?.data?.data, "PLANILLA");
        }
        if (p4?.data?.count > 0) {
          dataG["OPC-STAFF"] = sumAndFilter(p4?.data?.data, "STAFF");
        }
        setDataGeneral(dataG);
        setFilteredDataPL(dataG.PLANILLA?.todo);
        setFilteredDataSTF(dataG.STAFF?.todo);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();
  }, []);

  return !dataGeneral.PLANILLA ? (
    ""
  ) : (
    <>
      <Container>
        {isLoading ? (
          <Box className="text-center mt-6">
            <Spinner
              as="span"
              animation="border"
              variant="dark"
              role="status"
              aria-hidden="true"
            />
          </Box>
        ) : (
          <>
            <Title className="text-center">Avance Documental</Title>
            <Text className="text-center mb-1">
              Medición del avance de los documentos cargados por cuidadores y
              RRHH.
            </Text>
            <Text className="text-center">
              Filtrado por criticidad e importancia de documentos.
            </Text>
            <TabGroup className="mt-6">
              <TabPanels>
                <TabPanel>
                  {/*<Grid numItemsMd={2} numItemsLg={4} className="gap-6 mt-6">
                  {width > breakpoint && <div></div>}
                  <Card key={"totalCuidadores"} className="text-center">
                    <Text>{"Encargado Cuidador"}</Text>
                    <Metric>{dataByEncargado?.CUIDADOR || 0}</Metric>
                  </Card>
                  <Card key={"totalRRHH"} className="text-center">
                    <Text>{"Encargado RRHH"}</Text>
                    <Metric>{dataByEncargado?.RRHH || 0}</Metric>
                  </Card>
                  {width > breakpoint && <div></div>}
                </Grid>*/}
                  <Flex>
                    <Flex
                      className="mt-4"
                      style={{
                        maxWidth: "420px",
                        margin: "auto",
                        marginRight: "1rem",
                      }}
                    >
                      <Text className="mb-0 text-tremor-select">
                        <span style={{ fontWeight: 500 }}>Criticidad</span>
                      </Text>
                      <Select
                        onValueChange={setSelectedCritico}
                        placeholder="Todos"
                        className="max-w-xs"
                        value={selectedCritico}
                      >
                        {opcionesCritico.map((opcion) => (
                          <SelectItem key={opcion.key} value={opcion.key}>
                            {opcion.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </Flex>
                    <Flex
                      className="mt-4"
                      style={{
                        maxWidth: "420px",
                        margin: "auto",
                        marginLeft: "1rem",
                      }}
                    >
                      <Text className="mb-0 text-tremor-select">
                        <span style={{ fontWeight: 500 }}>Documentos</span>
                      </Text>
                      <Select
                        onValueChange={setIsOpcional}
                        placeholder="Indispensables"
                        className="max-w-xs"
                        value={isOpcional}
                      >
                        {opcionesIndispensable.map((opcion) => (
                          <SelectItem key={opcion.key} value={opcion.key}>
                            {opcion.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </Flex>
                  </Flex>
                  <Flex
                    className="my-4"
                    style={{ maxWidth: "420px", margin: "auto" }}
                  >
                    <Text className="mb-0 text-tremor-select">
                      <span style={{ fontWeight: 500 }}>Encargado</span>
                    </Text>
                    <Select
                      onValueChange={setEncargado}
                      placeholder="Todos"
                      className="max-w-xs"
                      value={encargado}
                    >
                      {opcionesEncargado.map((opcion) => (
                        <SelectItem key={opcion.key} value={opcion.key}>
                          {opcion.name}
                        </SelectItem>
                      ))}
                    </Select>
                  </Flex>
                  <Grid numItemsMd={1} numItemsLg={2} className="gap-6 my-3">
                    <Card className="mx-auto">
                      <Title className="text-center">
                        Avance por Gerencia - Planilla
                      </Title>
                      <Flex className="mt-6">
                        <Text>
                          <Bold>Gerencia</Bold>
                        </Text>
                        <Text>
                          <Bold>% Avance</Bold>
                        </Text>
                      </Flex>
                      <Box
                        style={{ maxHeight: "80vh", overflow: "auto" }}
                        className="pe-2"
                      >
                        {sortData(filteredDataPL).map((d, i) =>
                          d.value === "0" ? (
                            ""
                          ) : (
                            <div key={i}>
                              <Flex>
                                <Text>
                                  {d.grupo} &bull;{" "}
                                  {encargado === "R"
                                    ? d.completados_general_r
                                    : encargado === "C"
                                    ? d.completados_general_c
                                    : d.completados_general}{" "}
                                  de{" "}
                                  {encargado === "R"
                                    ? d.total_requerido_r
                                    : encargado === "C"
                                    ? d.total_requerido_c
                                    : d.total_requerido}{" "}
                                  doc.
                                </Text>
                                <Text>
                                  {Math.round(
                                    (encargado === "R"
                                      ? d.porcentaje_r
                                      : encargado === "C"
                                      ? d.porcentaje_c
                                      : d.porcentaje) * 100
                                  )}
                                  %
                                </Text>
                              </Flex>
                              <ProgressBar
                                value={Math.round(
                                  (encargado === "R"
                                    ? d.porcentaje_r
                                    : encargado === "C"
                                    ? d.porcentaje_c
                                    : d.porcentaje) * 100
                                )}
                                color="sky"
                                className="mb-3"
                              />
                            </div>
                          )
                        )}
                      </Box>
                    </Card>
                    <Card className="mx-auto">
                      <Title className="text-center">
                        Avance por Especialidad - Staff
                      </Title>
                      <Flex className="mt-6">
                        <Text>
                          <Bold>Especialidad</Bold>
                        </Text>
                        <Text>
                          <Bold>% Avance</Bold>
                        </Text>
                      </Flex>
                      <Box
                        style={{ maxHeight: "80vh", overflow: "auto" }}
                        className="pe-2"
                      >
                        {sortData(filteredDataSTF).map((d, i) =>
                          d.value === "0" ? (
                            ""
                          ) : (
                            <div key={i}>
                              <Flex>
                                <Text>
                                  {d.grupo} &bull;{" "}
                                  {encargado === "R"
                                    ? d.completados_general_r
                                    : encargado === "C"
                                    ? d.completados_general_c
                                    : d.completados_general}{" "}
                                  de{" "}
                                  {encargado === "R"
                                    ? d.total_requerido_r
                                    : encargado === "C"
                                    ? d.total_requerido_c
                                    : d.total_requerido}{" "}
                                  doc.
                                </Text>
                                <Text>
                                  {Math.round(
                                    (encargado === "R"
                                      ? d.porcentaje_r
                                      : encargado === "C"
                                      ? d.porcentaje_c
                                      : d.porcentaje) * 100
                                  )}
                                  %
                                </Text>
                              </Flex>
                              <ProgressBar
                                value={Math.round(
                                  (encargado === "R"
                                    ? d.porcentaje_r
                                    : encargado === "C"
                                    ? d.porcentaje_c
                                    : d.porcentaje) * 100
                                )}
                                color="sky"
                                className="mb-3"
                              />
                            </div>
                          )
                        )}
                      </Box>
                    </Card>
                  </Grid>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </>
        )}
      </Container>
    </>
  );
}

export default PanelDahboard;
