import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Col,
  Badge,
  Navbar,
  Nav,
  Spinner,
} from "react-bootstrap";
import logo from "../img/logo-login-ci.png";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCircleExclamation,
  faCirclePlay,
  faCircleQuestion,
  faEye,
  faFile,
  faHome,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import Popover from "@mui/material/Popover";
import BackupIcon from "@mui/icons-material/Backup";
import HelpIcon from "@mui/icons-material/Help";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Fab from "@mui/material/Fab";
import Swal from "sweetalert2";
import moment from "moment/moment";
import axios from "axios";
import { messageAlert, limpiarTexto } from "../components/utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

/**TABLE */
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LoadingPage from "../components/loadingPage";
import ImgLoad from "../components/imgLoad";
/**TABLE */

function DashboardMedico() {
  const recaptcha = useRef();
  const MAX_FILE_SIZE = 20 * 1024 * 1024; //20mb
  const EXT_ALLOWED = ["png", "jpg", "jpeg", "pdf"];
  const COD_VACUNAS = [
    "2.1.2.3",
    "2.1.2",
    "2.1.2.4",
    "2.1.3",
    "2.1.2.1",
    "2.1.2.5",
    "2.1.2.2",
  ];
  const { rrhh } = useParams();
  const [keyDocNum, setKeyDocNum] = useState();
  const [dataPersonal, setDataPersonal] = useState()
  const [dataArchivos, setDataArchivos] = useState()
  const [dataArchivosProc, setDataArchivosProc] = useState()
  const [dataArchivosInvalid, setDataArchivosInvalid] = useState()
  const [dataArchivosVerificados, setDataArchivosVerificados] = useState()
  const [dataArchivosRRHH, setDataArchivosRRHH] = useState()
  const [TArchivos, setTArchivos] = useState([])
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowUploadModal, setIsShowUploadModal] = useState(false)
  const [isShowInfoModal, setIsShowInfoModal] = useState(false)
  const [isShowDetalleLS, setIsShowDetalleLS] = useState(false)
  const [TipoCapa, setTipoCapa] = useState("")
  const [controlModal, setControlModal] = useState(true)
  const [dataValidacion, setDataValidacion] = useState("")
  const [isShowWelcomeModal, setIsShowWelcomeModal] = useState(false)
  /*const [isShowComunicado, setIsShowComunicado] = useState(false)
  const [isComunicadoImgLoad, setIsComunicadoImgLoad] = useState(false)*/

  const [isViewSoporte, setIsViewSoporte] = useState(false);
  const [isViewSoporte2, setIsViewSoporte2] = useState(false);

  const [loggedUser, setLoggedUser] = useState();
  /**table */
  const [textPopover, setTextPopover] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(100);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(100);

  const [page4, setPage4] = useState(0);
  const [rowsPerPage4, setRowsPerPage4] = useState(100);
  /** */
  const [page5, setPage5] = useState(0);
  const [rowsPerPage5, setRowsPerPage5] = useState(100);

  /**data documento */
  const [tDoc, setTdoc] = useState("");
  const [tDocLabel, setTDocLabel] = useState("");
  const [tDocDescription, setTDocDescription] = useState("");
  const [aplicaVigencia, setAplicaVigencia] = useState("NO");
  const [vigencia, setVigencia] = useState("");
  /** */
  const [tabSelected, setTabSelected] = useState(0);
  const [arrFiles, setArrFiles] = useState([]);

  /** descripcion documento */
  const [arrDescripciones, setArrDescripciones] = useState({});
  const [arrDescripcionesCarga, setArrDescripcionesCarga] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 700;

  let navigate = useNavigate();

  const handlePopoverOpen = (event, cod) => {
    let text = arrDescripciones[cod];
    if (cod === "2.2.21" || text === "" || !text) {
      setTextPopover("");
      setAnchorEl(null);
      return;
    }
    setAnchorEl(event.currentTarget);
    setTextPopover(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  /** */

  const columns = [
    {
      id: "documento_requerido",
      label: "Documento Requerido",
      fontWeight: 700,
    },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    {
      id: "accion",
      label: "Cargar Documento",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
  ];

  const columns2 = [
    { id: "Documento", label: "Documento Requerido", fontWeight: 700 },
    { id: "Archivo", label: "Archivo", fontWeight: 700 },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    /*{ id: 'accion', label: 'Ver Documento', align: "center", minWidth: "10.20vw", fontWeight: 700 },*/
  ];

  const columns3 = [
    { id: "Documento", label: "Documento Requerido", fontWeight: 700 },
    //{ id: 'Archivo', label: 'Archivo temporal', fontWeight: 700, color: "white" },
    { id: "Archivo", label: "Responsable", fontWeight: 700, color: "white" },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    /*{ id: 'accion', label: 'Ver Documento', align: "center", minWidth: "10.20vw", fontWeight: 700 },*/
  ];

  const columns4 = [
    { id: "Documento", label: "Documento Requerido", fontWeight: 700 },
    { id: "Archivo", label: "Archivo", fontWeight: 700 },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
    {
      id: "obs",
      label: "Observación",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
  ];

  const columnsClinica = [
    {
      id: "documento_requerido",
      label: "Documento Requerido",
      fontWeight: 700,
    },
    {
      id: "estado",
      label: "Estado",
      align: "center",
      minWidth: "10.20vw",
      fontWeight: 700,
    },
  ];

  const capitalize = (str) => {
    if (str.length < 2) return str;
    return str.replace(/\b\w/g, function (l) {
      return l.toUpperCase();
    });
  };

  const verificar = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      await messageAlert("Alerta", "Revisar CAPTCHA.", 800);
      setIsLoading(false);
      return
    } else {
      const { data } = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/recaptcha", JSON.stringify({ cptokn: captchaValue }));
      if (!data?.data?.success) {
        setIsLoading(false);
        return
      }
    }
    setIsLoading(true);
    try {
      let aux = dataValidacion?.trim().replace(/^0+/, "");
      const dPersonal = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/compare-hash-and-access-app",
        JSON.stringify({ ci_password_documento: aux })
      );
      if (dPersonal?.data?.data?.count > 0) {
        let nombre =
          dPersonal?.data?.data?.data[0]["Apellidos y Nombres"] || "";
        nombre = capitalize(nombre?.toLowerCase());
        const res = await Swal.fire({
          title: "Verificación",
          text: `Este perfil pertenece a ${nombre} ¿Desea continuar?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#329fe7",
          cancelButtonColor: "#6c757d",
          confirmButtonText: "Sí, continuar",
          cancelButtonText: "Cancelar",
        });
        if (res.isConfirmed) {
          setKeyDocNum(dPersonal?.data?.data?.data[0]["N°Documento"]);
          closeControlModal();
          await loadData(dPersonal?.data?.data?.data[0]["N°Documento"]);
        } else {
          setDataPersonal(null);
        }
      } else {
        await messageAlert(
          "Alerta",
          "No se reconoce el documento de identidad ingresado.",
          2200
        );
      }
    } catch (error) {
      console.log(error);
      await messageAlert(
        "Error",
        "Se produjo un error al efectuar la acción.",
        1100
      );
    } finally {
      setIsLoading(false);
    }
    /*
    e.preventDefault();
    setIsLoading(true)
    const response = await axios.post("https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/compare-hash-and-access-app", JSON.stringify({ ci_hash: hashKey, ci_doc_identidad: dataValidacion }))
    let dHash = response.data;
    if (dHash.data.count > 0) {
      setKeyDocNum(dataValidacion)
      await messageAlert("¡Perfecto!", "Se completo la verificación de identidad.", 1000)
      closeControlModal()
      await loadData(dataValidacion)
    } else {
      await messageAlert("Alerta", "El documento de identidad no corresponde con el link de acceso.", 2200)
    }
    setIsLoading(false)
    */
  };

  /**tabla */
  const handleChangePage = (event, newPage, ord) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, ord) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage2 = (event, newPage, ord) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event, ord) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };

  const handleChangePage3 = (event, newPage, ord) => {
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = (event, ord) => {
    setRowsPerPage3(+event.target.value);
    setPage3(0);
  };

  const handleChangePage4 = (event, newPage, ord) => {
    setPage4(newPage);
  };

  const handleChangeRowsPerPage4 = (event, ord) => {
    setRowsPerPage4(+event.target.value);
    setPage4(0);
  };

  const handleChangePage5 = (event, newPage, ord) => {
    setPage5(newPage);
  };

  const handleChangeRowsPerPage5 = (event, ord) => {
    setRowsPerPage5(+event.target.value);
    setPage5(0);
  };

  const handleTabs = (event, newValue) => {
    setTimeout(() => {
      setTabSelected(newValue);
    }, 180);
  };
  /** */

  const getLabelTA = (l, arr) => {
    let idx = arr.findIndex((e) => {
      return e.value === l;
    });
    if (idx < 0) return { label: l, exp: false };
    else return { label: arr[idx].label, exp: arr[idx].exp };
  };

  const getName = (l) => {
    if (l.length < 6) return;
    let arr = l.split("/");
    return arr[arr.length - 1];
  };

  const getUri = (l) => {
    if (l.length < 6) return;
    let arr = l.split("s3://ci-gestor-documental-2023/");
    return arr[arr.length - 1];
  };

  const closeInfoModal = () => {
    setIsShowInfoModal(false);
  };

  const closeControlModal = () => {
    setControlModal(false);
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  /**Requests*/
  const getArchId = async (key) => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/missing-file-by-doctor",
        JSON.stringify({ ci_nro_doc: key || keyDocNum })
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  };
  const getArchPend = async (key) => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/get-Revisiones",
        JSON.stringify({ ci_nro_doc: key || keyDocNum })
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  };

  const getArchInvalid = async (key) => {
    try {
      const res1 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/get-Revisiones",
        JSON.stringify({ ci_nro_doc: key || keyDocNum, ci_status: "NO VALIDO" })
      );
      return res1.data;
    } catch (error) {
      throw error;
    }
  };

  const getArchTypes = async (key) => {
    try {
      const res0 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/puesto-archivo-by-medico",
        JSON.stringify({ ci_nro_documento: key || keyDocNum })
      );
      return res0.data;
    } catch (error) {
      throw error;
    }
  };
  const getArchCargaText = async () => {
    try {
      const res0 = await axios.get(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/texto-carga-informativa"
      );
      return res0.data;
    } catch (error) {
      throw error;
    }
  };
  const getMedicos = async (key) => {
    try {
      const res2 = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/medicos",
        JSON.stringify({ ci_valor: key || keyDocNum })
      );
      return res2.data;
    } catch (error) {
      throw error;
    }
  };
  /***/

  const orderAlpha = (arr) => {
    arr.sort((a, b) => {
      /*let pa = a.nombre_archivo.toUpperCase()
      let pb = b.nombre_archivo.toUpperCase()
      if (pa < pb) return -1
      if (pa > pb) return 1
      return 0*/
      if (a.documento_indispensable < b.documento_indispensable) return 1;
      if (a.documento_indispensable > b.documento_indispensable) return -1;
      return a.nombre_archivo.localeCompare(b.nombre_archivo);
    })
    return arr;
  }

  const refreshData = async () => {
    try {
      const [dArchivo, dPend, dInvalid] = await Promise.all([
        getArchId(),
        getArchPend(),
        getArchInvalid(),
      ]);
      if (dArchivo?.data?.count > 0) {
        let arr = dArchivo?.data?.data?.archivos_faltantes || [];
        let val = loggedUser?.rol === 2 ? "RRHH" : "CUIDADOR";
        let arr1 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === val
        );
        let arr2 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === "RRHH"
        );
        let arrV = dArchivo?.data?.data?.archivos_validados || [];
        arr1 = orderAlpha(arr1)
        arr2 = orderAlpha(arr2)
        let idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.21");
        if (idx >= 0) {
          let aux = arr1[idx];
          arr1.splice(idx, 1);
          arr1.push(aux);
        }
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.1.2");
        if (idx >= 0) arr1.splice(idx, 1);
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.4");
        if (idx >= 0) arr1.splice(idx, 1);
        setDataArchivos(arr1);
        setTabSelected(arr?.length < 1 ? 1 : 0);
        setDataArchivosRRHH(arr2);
        setDataArchivosVerificados(arrV);
      }
      if (dPend?.data?.count > 0) {
        setDataArchivosProc(dPend?.data?.data);
      }
      if (dInvalid?.data?.count > 0) {
        setDataArchivosInvalid(dInvalid?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async (key) => {
    try {
      setIsPageLoading(true);
      const [dTArchivos, dArchCargaText, dArchivo, dPersonal, dPend, dInvalid] =
        await Promise.all([
          getArchTypes(key),
          getArchCargaText(),
          getArchId(key),
          getMedicos(key),
          getArchPend(key),
          getArchInvalid(key),
        ]);
      if (dTArchivos?.data?.count > 0) {
        let arr = [];
        let aux = {};
        dTArchivos?.data?.data.forEach((opt) => {
          aux[(opt.codigo_archivo || "").trim()] = opt.detalle_documento || "";
          arr.push({
            label: opt.nombre_archivo,
            value: opt.codigo_archivo,
            exp: opt.seguimiento_vigencia,
          });
        });
        setArrDescripciones(aux);
        setTArchivos(arr);
      }
      if (dArchCargaText?.data?.count > 0) {
        let aux = {};
        dArchCargaText?.data?.data.forEach((opt) => {
          aux[(opt.codigo_archivo || "").trim()] = opt.detalle || "";
        });
        setArrDescripcionesCarga(aux);
      }
      if (dArchivo?.data?.count > 0) {
        let arr = dArchivo?.data?.data?.archivos_faltantes || [];
        let val = loggedUser?.rol === 2 ? "RRHH" : "CUIDADOR";
        let arr1 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === val
        );
        let arr2 = arr.filter(
          (objeto) => objeto.encargado_subir_archivo === "RRHH"
        );
        let arrV = dArchivo?.data?.data?.archivos_validados || [];
        arr1 = orderAlpha(arr1)
        arr2 = orderAlpha(arr2)
        let idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.21");
        if (idx >= 0) {
          let aux = arr1[idx];
          arr1.splice(idx, 1);
          arr1.push(aux);
        }
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.1.2");
        if (idx >= 0) arr1.splice(idx, 1);
        idx = arr1.findIndex((e) => e.codigo_archivo === "2.2.4");
        if (idx >= 0) arr1.splice(idx, 1);
        setDataArchivos(arr1);
        setTabSelected(arr?.length < 1 ? 1 : 0);
        setDataArchivosRRHH(arr2);
        setDataArchivosVerificados(arrV);
      }
      if (dPersonal?.data?.count > 0) {
        setDataPersonal(dPersonal?.data?.data[0]);
      }
      if (dPend?.data?.count > 0) {
        setDataArchivosProc(dPend?.data?.data);
      }
      if (dInvalid?.data?.count > 0) {
        setDataArchivosInvalid(dInvalid?.data?.data);
      }
      setIsPageLoading(false);
      //setIsComunicadoImgLoad(false);
      setTimeout(() => {
        setIsShowWelcomeModal(true);
      }, 290);
      /*setTimeout(() => {
        setIsShowComunicado(true);
      }, 300);*/
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (rrhh === "RRHH") {
      if (localStorage.getItem("user") !== null) {
        let user = JSON.parse(localStorage.getItem("user"));
        setLoggedUser(user);
        if (user.rol !== 2) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
        }
      } else navigate("/login", { replace: true });
    }

    setControlModal(true);
    setIsPageLoading(false);
    /*(async () => {
      try {
        if (localStorage.getItem("user") !== null) {
          let user = JSON.parse(localStorage.getItem("user"))
          setLoggedUser(user);
          setKeyDocNum(user.ext)
          await loadData(user.ext)
          if (user.rol !== 3) {
            localStorage.removeItem("user")
            navigate("/login", { replace: true })
          }
        } else navigate("/login", { replace: true })
      } catch (error) {
        //console.log(error)
        setIsPageLoading(false)
      }
    })()*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showUploadModal = (file) => {
    setTdoc(file.codigo_archivo);
    setTDocLabel(file.nombre_archivo);
    setTDocDescription(arrDescripciones[file.codigo_archivo] || "");
    setAplicaVigencia("NO" /*file.seguimiento_vigencia*/);
    setVigencia("");
    setIsShowUploadModal(true);
  };

  const closeUploadModal = () => {
    setTdoc("");
    setTDocLabel("");
    setAplicaVigencia("NO");
    setVigencia("");
    setIsShowUploadModal(false);
    setArrFiles([]);
  };

  const viewDocument = async (l) => {
    setIsLoading(true);
    let uri = getUri(l);
    try {
      let response = null;
      response = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/url-prefirmada-2",
        JSON.stringify({ key: uri })
      );
      let dS3 = response.data;
      if (dS3?.data?.count > 0) {
        if (dS3?.data?.data?.length > 8) window.open(dS3?.data?.data, "_blank");
      }
      setIsLoading(false);
      return;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return;
    }
  };

  const uploadFile = async (selectedFile, i) => {
    if (!selectedFile) {
      return;
    }
    let reg = {};
    let time = moment().format("x");
    let arr = selectedFile.name.split(".");
    let ext = arr[arr.length - 1];
    reg.ci_tb_general_ruta_origen = `POR REVISAR/${dataPersonal["T.Doc."]}_${dataPersonal["N°Documento"]
      }/${time}${i}_${limpiarTexto(
        dataPersonal["Apellidos y Nombres"]
      )}_${tDoc}.${ext}`;
    reg.ci_tb_general_vigencia_archivo_2024 = ""; //calcularVigencia(vigencia)
    reg.ci_tb_general_cod_tipo_doc = tDoc;
    reg.ci_tb_general_status_archivo = "POR REVISAR";
    reg.ci_tb_general_fecha_fin_vigencia_archivo = ""; //vigencia
    reg.ci_tb_general_id_usuario = loggedUser?.rol === 2 ? loggedUser?.ext : "";
    reg.ci_tb_general_pagina = 1;
    reg.ci_tb_general_observacion = "";
    reg.ci_tb_general_ruta_nueva = "";
    reg.ci_tb_general_doc_identidad_medico = dataPersonal["N°Documento"];
    try {
      const urlPut = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/url-put-object",
        { key: reg.ci_tb_general_ruta_origen, content_type: selectedFile.type }
      );
      let urlData = urlPut.data?.data?.data;
      await axios.put(urlData, selectedFile, {
        headers: { "Content-Type": selectedFile.type },
      });
      reg.ci_tb_general_ruta_origen =
        "s3://ci-gestor-documental-2023/" + reg.ci_tb_general_ruta_origen;
      let up = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/insert-tb-general",
        JSON.stringify(reg)
      );
      let dSave = up.data;
      if (dSave?.data?.count > 0) {
      }
    } catch (error) {
      throw error;
    }
  };

  /**Nuevo */
  const removeFile = (index) => {
    const rows = [...arrFiles];
    rows.splice(index, 1);
    setArrFiles(rows);
  };

  const checkSize = (file) => {
    if (file.size > MAX_FILE_SIZE) return false;
    return true;
  };
  const checkExtension = (filename) => {
    let arr = filename.split(".");
    let extension = arr[arr.length - 1];
    extension = extension?.toLowerCase();
    if (EXT_ALLOWED.indexOf(extension) === -1) return false;
    return true;
  };

  const selectFiles = (event) => {
    if (!(event.target.files && event.target.files[0])) return;
    let aux = [],
      e = 0,
      t = 0;
    for (const el of event.target.files) {
      if (!checkSize(el)) t = t + 1;
      else if (!checkExtension(el.name)) e = e + 1;
      else aux.push(el);
    }
    if (e > 0) {
      if (t > 0)
        messageAlert(
          "Alerta",
          "Se detectó documentos que no cumplen las restricciones de tamaño y extensión.",
          2000
        );
      else
        messageAlert(
          "Alerta",
          "Se detectó documentos que no cumplen las restricciones de extensión.",
          2000
        );
    } else if (t > 0) {
      messageAlert(
        "Alerta",
        "Se detectó documentos que no cumplen las restricciones de tamaño.",
        2000
      );
    }
    let files = [...arrFiles];
    let arr = [...files, ...aux];
    setArrFiles(arr);
    event.target.value = null;
  };

  const uploadMultipleFiles = async () => {
    try {
      //console.log("toup", arrFiles)
      setIsLoading(true);
      setIsPageLoading(true);
      await Promise.all(arrFiles.map((file, i) => uploadFile(file, i)));
      await refreshData();
      setIsPageLoading(false);
      closeUploadModal();
      await messageAlert("¡Perfecto!", "El documento se cargó correctamente.");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
      setIsLoading(false);
    }
  };

  /** */

  return (
    <>
      {isPageLoading && <LoadingPage />}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="shadow bg-body-tertiary mb-3"
      >
        <Container fluid className="mx-4">
          <Navbar.Brand href={loggedUser?.rol === 2 ? "/" : ""}>
            <Image
              src={logo}
              className="img-fluid me-auto"
              style={{ maxWidth: 120 }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="align-items-center" style={{ fontSize: 14 }}>
              {loggedUser?.rol === 2 && (
                <Nav.Link
                  as={Link}
                  to="/"
                  style={{ fontWeight: "700" }}
                  className="mx-5"
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    style={{ marginRight: "5px" }}
                  />
                  Inicio
                </Nav.Link>
              )}
              {dataPersonal && (
                <>
                  <b>{dataPersonal["Apellidos y Nombres"]}</b>{" "}
                  <Button
                    onClick={() => {
                      setIsShowInfoModal(true);
                    }}
                    size="sm"
                    style={{ maxWidth: 70, marginLeft: "8px" }}
                    variant="link"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                  {/*<Button onClick={() => { localStorage.removeItem("user"); window.location.reload(); }} size='sm' style={{ maxWidth: 70, marginLeft: "8px", textDecoration: "none" }} variant='link'><span className='text-dark' style={{ fontWeight: 600, fontSize: "1rem" }}>Salir</span> <FontAwesomeIcon icon={faRightFromBracket} /></Button>*/}
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={isShowInfoModal} onHide={closeInfoModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Información</Modal.Title>
        </Modal.Header>
        {dataPersonal && (
          <Modal.Body>
            <Row>
              <Col>
                <p>
                  <b>Documento:</b> {dataPersonal["T.Doc."]}{" "}
                  {dataPersonal["N°Documento"]}
                </p>
              </Col>
              <Col>
                <p>
                  <b>Puesto:</b> {dataPersonal["Desc. Posición"]}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <b>F. Ingreso:</b>{" "}
                  {dataPersonal["Fec. Ingreso"]
                    ? moment(dataPersonal["Fec. Ingreso"]).format("DD/MM/YYYY")
                    : ""}
                </p>
              </Col>
              <Col>
                <p>
                  <b>Situación:</b> {dataPersonal["Situación"] || "Activo"}
                </p>
              </Col>
            </Row>
            <p>
              <b>Archivo:</b> {dataPersonal["PUESTO ARCHIVO"]}
            </p>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={closeInfoModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isShowWelcomeModal}
        onHide={() => {
          setIsShowWelcomeModal(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1.25rem" }}>
            Completa tu file en simples pasos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video
            key={"1"}
            controls
            style={{ width: "100%", borderRadius: "6px" }}
          >
            <source
              src={"https://clinicainternacional.doctorfile.pe/cdn/instructivo.mp4"}
              type="video/mp4"
            />
          </video>
        </Modal.Body>
      </Modal>

      {/*<Modal
        show={isShowComunicado}
        onHide={() => {
          setIsShowComunicado(false);
          setIsComunicadoImgLoad(false);
        }}
        centered
        id="imagen-comunicado"
      >
        {isComunicadoImgLoad && <Modal.Header closeButton></Modal.Header>}
        <div className="text-center mb-3">
          <ImgLoad
            src={"https://clinicainternacional.doctorfile.pe/cdn/Comunicado.png"}
            alt="comunicado"
            isload={setIsComunicadoImgLoad}
          />
        </div>
      </Modal>*/}

      <Modal
        show={controlModal || isViewSoporte2}
        centered
        onHide={
          isViewSoporte2
            ? () => {
              setIsViewSoporte2(!isViewSoporte2);
            }
            : () => { }
        }
      >
        <Modal.Header closeButton={isViewSoporte2}>
          <Modal.Title>{isViewSoporte2 ? "Ayuda" : "Verificador"}</Modal.Title>
          {!isViewSoporte2 && (
            <Button
              variant={"secondary"}
              style={{
                backgroundColor: "var(--bs-btn-hover-bg)",
                borderColor: "var(--bs-btn-hover-border-color)",
              }}
              onClick={() => {
                setIsViewSoporte(!isViewSoporte);
              }}
              disabled={isLoading}
            >
              {!isViewSoporte ? (
                <>
                  ¿Tienes dudas? {width < breakpoint ? "" : "Haz click aquí"}{" "}
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faAngleLeft} /> Regresar
                </>
              )}
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          {isViewSoporte || isViewSoporte2 ? (
            <>
              <ul>
                <li>
                  Si eres <b>personal no médico</b> y tienes dudas con algún
                  documento puedes comunicarte a los números{" "}
                  <a
                    style={{ color: "#000" }}
                    href="https://wa.me/51955308140"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +51 955308140
                  </a>
                  ,{" "}
                  <a
                    style={{ color: "#000" }}
                    href="https://wa.me/51933927427"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +51 933927427
                  </a>{" "}
                  ,{" "}
                  <a
                    style={{ color: "#000" }}
                    href="https://wa.me/51960120682"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +51 960120682
                  </a>{" "}
                  y/o enviar un correo a{" "}
                  <a
                    style={{ color: "#000" }}
                    href="mailto:documentos.personal@cinternacional.com.pe"
                  >
                    documentos.personal@cinternacional.com.pe
                  </a>
                </li>
                <li className="mt-2">
                  Si eres <b>personal médico</b> y tienes dudas con algún
                  documento puedes enviar un correo a{" "}
                  <a
                    style={{ color: "#000" }}
                    href="mailto:ocp@cinternacional.com.pe"
                  >
                    ocp@cinternacional.com.pe
                  </a>
                </li>
              </ul>
            </>
          ) : (
            <Form className="mb-3">
              <p>Documento de identidad</p>
              <Form.Control
                placeholder="Ingresar el doc. identidad"
                type="text"
                value={dataValidacion}
                onChange={(e) => {
                  setDataValidacion(e.target.value);
                }}
              />
              <Row xs="auto" className="align-items-center justify-content-center mt-2" style={{ transform: "scale(0.8)" }}><ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} size="normal" /></Row>
              <Row
                xs="auto"
                className="align-items-center justify-content-center"
              >
                <Button
                  type="submit"
                  variant="dark"
                  className="mt-1"
                  onClick={verificar}
                  disabled={isLoading || dataValidacion?.trim().length < 8}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <>
                      Verificar <FontAwesomeIcon icon={faLock} />
                    </>
                  )}
                </Button>
              </Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={isShowDetalleLS} onHide={() => { setIsShowDetalleLS(false) }} centered id='comunicado'>
        <Modal.Header closeButton><p className='mb-0'><b>Detalle {TipoCapa}</b></p></Modal.Header>
        <div className="text-center mb-3">
          <p className='py-3 mb-0'>
            {TipoCapa === "PALS" && "Aplica para: Emergencia pediátrica, Pediatría, Neonatología, Anestesiología pediátrica, Cardiología pediátrica, UCI pediátrica y Neumología pediátrica"}
            {TipoCapa === "ACLS" && "Aplica para: Anestesiología, Cardiología, UCI, Emergencia, Médico hospitalario, Medicina Interna, Neumología y Terapia del dolor"}
            {TipoCapa === "BLS" && "Aplica para especialidades no incluidas en PALS y ACLS"}
          </p>
        </div>
      </Modal>
      <Container className="page-content dash-med">
        {!controlModal && !isPageLoading && (
          <Button
            id="welcome"
            variant="success"
            size="sm"
            onClick={() => setIsShowWelcomeModal(true)}
            disabled={isShowWelcomeModal}
          >
            <FontAwesomeIcon className="me-1" icon={faCirclePlay} />{" "}
            Instrucciones
          </Button>
        )}
        {!controlModal && (
          <Box
            sx={{ width: "100%", bgcolor: "background.paper" }}
            className="mt-3"
          >
            <Tabs
              value={tabSelected}
              onChange={handleTabs}
              centered={width < breakpoint ? false : true}
              variant={width < breakpoint ? "scrollable" : "standard"}
              scrollButtons={width < breakpoint ? "auto" : false}
            >
              <Tab
                value={0}
                disabled={!(dataArchivos && dataArchivos.length > 0)}
                icon={
                  <>
                    <BackupIcon />
                    <ArrowForwardIcon />
                  </>
                }
                label={
                  "Documentos pendientes (" + (dataArchivos?.length || 0) + ")"
                }
              />
              <Tab
                value={1}
                disabled={
                  !(
                    (dataArchivosProc && dataArchivosProc.length > 0) ||
                    (dataArchivosInvalid && dataArchivosInvalid.length > 0)
                  )
                }
                icon={
                  <>
                    <PublishedWithChangesIcon />
                    <ArrowForwardIcon />
                  </>
                }
                label={"Documentos en verificación"}
              />
              <Tab
                value={2}
                disabled={
                  !(
                    dataArchivosVerificados &&
                    dataArchivosVerificados.length > 0
                  )
                }
                icon={<ChecklistIcon />}
                label={
                  "Documentos verificados (" +
                  (dataArchivosVerificados?.length || 0) +
                  ")"
                }
              />
            </Tabs>
          </Box>
        )}
        {tabSelected === 0 && dataArchivos && dataArchivos.length > 0 && (
          <>
            <Row className="mt-4">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <p
                  className=""
                  style={width < breakpoint ? { textAlign: "center" } : {}}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} /> = Documentos
                  Indispensables
                </p>
                <TableContainer sx={{ maxHeight: "calc(100vh - 320px)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              fontWeight: column.fontWeight,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataArchivos
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((file, idx) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={idx}
                            >
                              <TableCell
                                key={"A"}
                                align={"left"}
                                style={{ fontWeight: 500 }}
                                onMouseEnter={(e) =>
                                  handlePopoverOpen(
                                    e,
                                    file.codigo_archivo.trim()
                                  )
                                }
                                onMouseLeave={handlePopoverClose}
                              >
                                {file.nombre_archivo}{" "}
                                {file.documento_indispensable === "SI" ? (
                                  <FontAwesomeIcon icon={faCircleExclamation} />
                                ) : (
                                  ""
                                )}
                                {file.codigo_archivo === "1.1.16" && (
                                  <Button
                                    className="ms-2 link-especial"
                                    variant="link"
                                    href="https://www.empleosperu.gob.pe/CertificadoUnicoLaboral/irIndex.html;jsessionid=-jYMcfXqgh6WAbI0F5NUJUyCJ6fr7ThxjV8U-nu5.srv-cjl"
                                    target="_blank"
                                  >
                                    Obtener
                                  </Button>
                                )}
                                {file.codigo_archivo === "5.1.7" && <Button className='ms-2 link-especial' variant='link' onClick={() => { setIsShowDetalleLS(true); setTipoCapa("BLS") }}>Ver más</Button>}
                                {file.codigo_archivo === "3.1.5" && <Button className='ms-2 link-especial' variant='link' onClick={() => { setIsShowDetalleLS(true); setTipoCapa("PALS") }}>Ver más</Button>}
                                {file.codigo_archivo === "3.1.4" && <Button className='ms-2 link-especial' variant='link' onClick={() => { setIsShowDetalleLS(true); setTipoCapa("ACLS") }}>Ver más</Button>}
                                {COD_VACUNAS.indexOf(file.codigo_archivo) !==
                                  -1 && (
                                    <Button
                                      className="ms-2 link-especial"
                                      variant="link"
                                      href="https://clinicainternacional.doctorfile.pe/cdn/Vacunas%20perfil%20laboral.pdf"
                                      target="_blank"
                                    >
                                      Ver Esquema
                                    </Button>
                                  )}
                              </TableCell>
                              <TableCell
                                key={"B"}
                                align={"center"}
                                style={{ fontWeight: 500 }}
                              >
                                {dataArchivosProc?.some(
                                  (obj) =>
                                    obj.cod_tipo_doc === file.codigo_archivo
                                ) ? (
                                  <Badge bg="danger" className="proceso">
                                    En proceso
                                  </Badge>
                                ) : (
                                  file.documento_indispensable === "SI" ?
                                    <Badge bg="danger">Pendiente</Badge> :
                                    <Badge bg="secondary">Opcional</Badge>
                                )}
                              </TableCell>
                              <TableCell key={"C"} align={"center"}>
                                <Button
                                  id="upload"
                                  variant="primary"
                                  size="sm"
                                  onClick={() => showUploadModal(file)}
                                  disabled={isLoading}
                                >
                                  <FontAwesomeIcon icon={faFile} /> Cargar
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 100]}
                  component="div"
                  count={dataArchivos?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Popover
                  id="mouse-over-popover"
                  sx={{ pointerEvents: "none" }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                  style={{ maxWidth: "40vw" }}
                >
                  {textPopover}
                </Popover>
              </Paper>
            </Row>
            {false && columnsClinica && columnsClinica.length > 0 && (
              <Row className="mt-4">
                <Paper sx={{ width: "60%", overflow: "hidden" }}>
                  <p>
                    <b>Documentos Pendientes RR. HH.</b>
                  </p>
                  <TableContainer sx={{ maxHeight: "40vh" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columnsClinica.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                fontWeight: column.fontWeight,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataArchivosRRHH
                          .slice(
                            page4 * rowsPerPage4,
                            page4 * rowsPerPage4 + rowsPerPage4
                          )
                          .map((file, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={idx}
                              >
                                <TableCell
                                  key={"A"}
                                  align={"left"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {file.nombre_archivo}
                                </TableCell>
                                <TableCell
                                  key={"B"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  <Badge bg="secondary">Pendiente</Badge>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={dataArchivosRRHH?.length || 0}
                    rowsPerPage={rowsPerPage4}
                    page={page4}
                    onPageChange={handleChangePage4}
                    onRowsPerPageChange={handleChangeRowsPerPage4}
                  />
                </Paper>
              </Row>
            )}
          </>
        )}
        {tabSelected === 1 && (
          <>
            {dataArchivosProc && dataArchivosProc.length > 0 && (
              <Row className="mt-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns2.map((column, idx) =>
                            width > breakpoint ||
                              (width < breakpoint && idx !== 1) ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: column.fontWeight,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : (
                              ""
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataArchivosProc
                          .slice(
                            page2 * rowsPerPage2,
                            page2 * rowsPerPage2 + rowsPerPage2
                          )
                          .map((file, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={idx}
                              >
                                <TableCell
                                  key={"#"}
                                  align={"left"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {
                                    getLabelTA(file.cod_tipo_doc, TArchivos)
                                      .label
                                  }
                                </TableCell>
                                {width > breakpoint && (
                                  <TableCell
                                    key={"A"}
                                    align={"left"}
                                    style={{ fontWeight: 500 }}
                                  >
                                    {getName(file.ruta_origen)}
                                  </TableCell>
                                )}
                                <TableCell
                                  key={"B"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  <Badge bg="warning">Por verificar</Badge>
                                </TableCell>
                                <TableCell key={"C"} align={"center"}>
                                  {false && (
                                    <Button
                                      id="upload"
                                      variant="primary"
                                      size="sm"
                                      onClick={() =>
                                        viewDocument(file.ruta_origen)
                                      }
                                      disabled={isLoading}
                                    >
                                      {isLoading ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <>
                                          <FontAwesomeIcon icon={faEye} /> Ver
                                        </>
                                      )}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={dataArchivosProc?.length || 0}
                    rowsPerPage={rowsPerPage2}
                    page={page2}
                    onPageChange={handleChangePage2}
                    onRowsPerPageChange={handleChangeRowsPerPage2}
                  />
                </Paper>
              </Row>
            )}
            {dataArchivosInvalid && dataArchivosInvalid.length > 0 && (
              <Row className="mt-4">
                <p>
                  <b>Archivos No Validos</b>
                </p>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns4.map((column, idx) =>
                            width > breakpoint ||
                              (width < breakpoint && idx !== 1) ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: column.fontWeight,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : (
                              ""
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataArchivosInvalid
                          .slice(
                            page5 * rowsPerPage5,
                            page5 * rowsPerPage5 + rowsPerPage5
                          )
                          .map((file, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={idx}
                              >
                                <TableCell
                                  key={"#"}
                                  align={"left"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {
                                    getLabelTA(file.cod_tipo_doc, TArchivos)
                                      .label
                                  }
                                </TableCell>
                                {width > breakpoint && (
                                  <TableCell
                                    key={"A"}
                                    align={"left"}
                                    style={{ fontWeight: 500 }}
                                  >
                                    {getName(file.ruta_origen)}
                                  </TableCell>
                                )}
                                <TableCell
                                  key={"B"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  <Badge bg="dark">No Valido</Badge>
                                </TableCell>
                                {/*<TableCell key={"C"} align={"center"}>
                                {false && <Button id="upload" variant="primary" size="sm" onClick={() => viewDocument(file.ruta_origen)} disabled={isLoading} >
                                  {isLoading ? <Spinner as="span" animation="border" variant="light" size="sm" role="status" aria-hidden="true" /> : <><FontAwesomeIcon icon={faEye} /> Ver</>}
                                </Button>}
                          </TableCell>*/}
                                <TableCell
                                  key={"D"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {file.observacion}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={dataArchivosInvalid?.length || 0}
                    rowsPerPage={rowsPerPage5}
                    page={page5}
                    onPageChange={handleChangePage5}
                    onRowsPerPageChange={handleChangeRowsPerPage5}
                  />
                </Paper>
              </Row>
            )}
          </>
        )}
        {tabSelected === 2 &&
          dataArchivosVerificados &&
          dataArchivosVerificados.length > 0 && (
            <>
              <Row className="mt-4">
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: "calc(100vh - 280px)" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns3.map(
                            (column, idx) =>
                              (width > breakpoint ||
                                (width < breakpoint && idx !== 1)) && (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    fontWeight: column.fontWeight,
                                    color: column.color,
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataArchivosVerificados
                          .slice(
                            page3 * rowsPerPage3,
                            page3 * rowsPerPage3 + rowsPerPage3
                          )
                          .map((file, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={idx}
                              >
                                <TableCell
                                  key={"#"}
                                  align={"left"}
                                  style={{ fontWeight: 500 }}
                                >
                                  {
                                    /*getLabelTA(file.cod_tipo_doc, TArchivos).label*/ file.nombre_archivo
                                  }
                                </TableCell>
                                {width > breakpoint && (
                                  <TableCell
                                    key={"A"}
                                    align={"left"}
                                    style={{
                                      fontWeight: 500,
                                      opacity: ".5",
                                      color: "white",
                                    }}
                                  >
                                    {
                                      /*getName(file.ruta_nueva)*/ file.encargado_subir_archivo
                                    }
                                  </TableCell>
                                )}
                                <TableCell
                                  key={"B"}
                                  align={"center"}
                                  style={{ fontWeight: 500 }}
                                >
                                  <Badge bg="success">Verificado</Badge>
                                </TableCell>
                                <TableCell key={"C"} align={"center"}>
                                  {false && (
                                    <Button
                                      id="upload"
                                      variant="primary"
                                      size="sm"
                                      onClick={() =>
                                        viewDocument(file.ruta_nueva)
                                      }
                                      disabled={isLoading}
                                    >
                                      {isLoading ? (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          variant="light"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        />
                                      ) : (
                                        <>
                                          <FontAwesomeIcon icon={faEye} /> Ver
                                        </>
                                      )}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={dataArchivosVerificados?.length || 0}
                    rowsPerPage={rowsPerPage3}
                    page={page3}
                    onPageChange={handleChangePage3}
                    onRowsPerPageChange={handleChangeRowsPerPage3}
                  />
                </Paper>
              </Row>
            </>
          )}
        {!isViewSoporte2 && !controlModal && !isPageLoading && (
          <Fab
            size="medium"
            variant="extended"
            onClick={() => setIsViewSoporte2(true)}
          >
            ¿Tienes dudas? {width < breakpoint ? "" : "Haz click aquí"}
            <HelpIcon sx={{ ml: 1 }} />
          </Fab>
        )}
      </Container>
      {!isPageLoading && (
        <Modal
          show={isShowUploadModal}
          onHide={closeUploadModal}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Carga de documentos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <Row>
                <Col>
                  <p style={{ marginBottom: 10 }}>
                    <b>{tDocLabel}</b>
                  </p>
                  {tDocDescription !== "" && (
                    <p style={{ marginBottom: 5 }}>
                      <span style={{ fontWeight: 500 }}>Detalle: </span>
                      {tDoc === "1.1.16" ? (
                        <>
                          Para descargar ingresa{" "}
                          <a
                            className="link-descripcion"
                            href="https://www.empleosperu.gob.pe/CertificadoUnicoLaboral/irIndex.html;jsessionid=-jYMcfXqgh6WAbI0F5NUJUyCJ6fr7ThxjV8U-nu5.srv-cjl"
                            target="_blank"
                            rel="noreferrer"
                          >
                            aquí
                          </a>
                        </>
                      ) : (
                        tDocDescription
                      )}
                    </p>
                  )}
                  {arrDescripcionesCarga[tDoc] && (
                    <p>
                      {arrDescripcionesCarga[tDoc]}{" "}
                      {COD_VACUNAS.indexOf(tDoc) !== -1 && (
                        <a
                          className="link-descripcion"
                          href="https://clinicainternacional.doctorfile.pe/cdn/Vacunas%20perfil%20laboral.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          aquí
                        </a>
                      )}
                    </p>
                  )}
                  {tDoc === "3.1.5" && <p>Aplica para: Emergencia pediátrica, Pediatría, Neonatología, Anestesiología pediátrica, Cardiología pediátrica, UCI pediátrica y Neumología pediátrica</p>}
                  {tDoc === "3.1.4" && <p>Aplica para: Anestesiología, Cardiología, UCI, Emergencia, Médico hospitalario, Medicina Interna, Neumología y Terapia del dolor</p>}
                  {tDoc === "5.1.7" && <p>Aplica para especialidades no incluidas en PALS y ACLS</p>}
                  {false && (
                    <i>
                      Recuerde verificar que los documentos cuenten con la
                      legibilidad, nitidez y vigencia correctas. Evite incluir
                      hojas en blanco o documentos que no cumplan con los
                      lineamientos establecidos. ¡Gracias por su colaboración!
                    </i>
                  )}
                  <InputGroup className="mt-3">
                    <Form.Control
                      style={{ width: "100%" }}
                      placeholder="Seleccionar archivo"
                      multiple
                      accept="image/*, application/pdf"
                      type="file"
                      onChange={selectFiles}
                    />
                    <p className="mt-2">
                      * Solo se permite subir documentos en formato PDF, JPG o
                      PNG. Máximo archivos de 20 MB.
                    </p>
                  </InputGroup>
                </Col>
              </Row>
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                flexWrap="wrap"
                className="mt-2"
              >
                {arrFiles.map((file, idx) => {
                  return (
                    <Chip
                      key={idx}
                      label={file.name}
                      onClick={() => removeFile(idx)}
                      onDelete={() => removeFile(idx)}
                    />
                  );
                })}
              </Stack>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={uploadMultipleFiles}
              disabled={
                isLoading ||
                arrFiles.length < 1 ||
                (aplicaVigencia?.toUpperCase() !== "NO" &&
                  (!vigencia || vigencia === ""))
              }
            >
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DashboardMedico;
