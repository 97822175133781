import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Error404() {
  return (
    <Container fluid className="vh-100 d-flex align-items-center justify-content-center">
      <Row>
        <Col className="text-center">
          <h1 className="display-1">404</h1>
          <p className="lead">Página no encontrada</p>
          <Link to="/">
            <Button variant="primary">Volver al inicio</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Error404;
