import React, { useEffect, useState } from "react";
import logo from "../img/logo-login-ci.png";

import {
  Image,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Spinner,
  Button,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
//import Avatar from "@mui/material/Avatar";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoadingPage from "./loadingPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faSave,
  faFileLines,
  faPencilAlt,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { messageAlert } from "./utils";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import axios from "axios";
//userContext
//import { UserComponent } from "../context/userComponents";

const MenuPrincipal = (props) => {
  //{ isLoading: true, notifPendientes: false }
  const [loggedUser, setLoggedUser] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");

  const navigate = useNavigate();

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/edit-pass",
        JSON.stringify({ id: loggedUser.ext, pass: pass1 })
      );
      if (!data?.data?.success) {
        await messageAlert(
          "Error",
          "Se produjo un error al efectuar la acción.",
          1100
        );
        return;
      }
      await messageAlert("¡Listo!", "Contraseña actualizada", 1800);
      setIsShow(false);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      await messageAlert(
        "Error",
        "Se produjo un error al efectuar la acción.",
        1100
      );
    }
  };

  const handleClose = () => {
    setIsShow(false);
  };

  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      let user = JSON.parse(localStorage.getItem("user"));
      setLoggedUser(user);
      if (user.rol === 3) {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {localStorage.getItem("user") === null && (
        <Navigate to="/login" replace={true} />
      )}
      {props.isLoading && <LoadingPage />}

      <Navbar collapseOnSelect expand="lg" id="navbar">
        <Container fluid className="mx-4">

          <Navbar.Brand
            href="/"
            className="d-flex align-items-end"
            style={{ fontWeight: "600", width: "35%" }}
            id="navbar-brand"
          >
            <Image
              src={logo}
              className="img-fluid me-auto ml-2"
              style={{ maxWidth: "250px" }}
            />
            <div className="hello flex-column ml-6">
              <p className="m-0">Hola !</p>
              <p className="m-0">{loggedUser?.name ? loggedUser.name : ""}</p>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {(loggedUser?.rol === 1 || props.accesos?.v1 || props.accesos?.adm) && props.notifPendientes && (
                <Nav.Link
                  href="/docList/revision"
                  style={{ fontWeight: "600", marginRight: "2rem" }}
                >
                  <Tooltip
                    title="Nuevos archivos prioritarios por revisar"
                    placement="bottom"
                  >
                    <Badge
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent=""
                      variant="dot"
                      color="error"
                      className="noti-bell"
                    >
                      <NotificationImportantIcon color="action" />
                    </Badge>
                  </Tooltip>
                </Nav.Link>
              )}
              {(props.accesos?.v4 || props.accesos?.adm) && <Nav.Link as={Link} to="/misReportes" style={{ fontWeight: "600", marginRight: "1rem" }}><FontAwesomeIcon icon={faFileLines} style={{ marginRight: "5px" }} />Reportes</Nav.Link>}
              <Nav.Link as={Link} to="/" style={{ fontWeight: "600" }}>
                <FontAwesomeIcon icon={faHome} style={{ marginRight: "5px" }} />
                Inicio
              </Nav.Link>
              {/* Marco */}
              {(props.accesos?.adm || props.accesos?.v2) && (
                <Nav.Link
                  as={Link}
                  to="/dashboardUpdate"
                  style={{ fontWeight: "600" }}
                >
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    style={{ marginRight: "5px" }}
                  />
                  Adm. Personal
                </Nav.Link>
              )}

              <NavDropdown
                title={
                  <FontAwesomeIcon
                    icon={faGear}
                    style={{ marginRight: "5px" }}
                  />
                }
                id="collapsible-nav-dropdown"
                style={{ fontWeight: 600 }}
              >
                <NavDropdown.Item onClick={() => setIsShow(true)}>
                  Cambiar contraseña
                </NavDropdown.Item>

                {(props.accesos?.adm || props.accesos?.v3) && (
                  <>
                    {/*  <NavDropdown.Item
                      onClick={() => navigate("/dashboardUpdate")}
                    >
                      Adm. Personal
                    </NavDropdown.Item> */}
                    <NavDropdown.Item onClick={() => navigate("/admUsuarios")}>
                      Configuracion de cuentas{/* Usuarios */}
                    </NavDropdown.Item>
                  </>
                )}

                <NavDropdown.Item
                  onClick={() => {
                    localStorage.removeItem("user");
                    window.location.reload();
                  }}
                >
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>

              {/* Fin Marco */}
              <div
                style={{ fontWeight: "600", color: "#296E95" }}
                className="d-flex flex-column justify-content-end"
              >
                <div style={{ margin: 0 }}>
                  <div className="mb-0" style={{ fontSize: "1rem" }}>
                    {loggedUser && loggedUser.name
                      ? loggedUser.name.indexOf(" ") > -1
                        ? loggedUser.name.substring(
                          0,
                          loggedUser.name.indexOf(" ")
                        )
                        : loggedUser.name
                      : ""}
                  </div>
                  <div
                    style={{ fontSize: "0.8rem" }}
                    className="mb-0 justify-content-end"
                  >
                    <p style={{ margin: 0 }}>
                      {props.accesos?.adm &&
                        loggedUser?.rol === 1 &&
                        "Admin-Verificador"}
                      {loggedUser?.rol === 1 && !props.accesos?.adm && "Verificador"}
                      {props.accesos?.adm &&
                        loggedUser?.rol === 2 &&
                        "Administrador-RRHH"}
                      {loggedUser?.rol === 2 && !props.accesos?.adm && "RRHH"}
                      {loggedUser?.rol === 3 && "Médico"}
                    </p>
                  </div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal centered show={isShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={"warning"}>Se requiere al menos 6 caracteres.</Alert>
          <Form>
            <Form.Group className="mb-3" controlId="pass1">
              <Form.Label>Nueva Contraseña</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => {
                  setPass1(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="rpass1">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => {
                  setPass2(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            disabled={isLoading || pass1 !== pass2 || pass1.length < 6}
            onClick={handleSave}
          >
            {isLoading === true ? "" : "Guardar "}
            {isLoading === true ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FontAwesomeIcon icon={faSave} />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MenuPrincipal;
