import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, InputGroup } from "react-bootstrap";
//import personalService from "../services/personalService";
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import Swal from "sweetalert2";
import { messageAlert } from '../components/utils';

function VerS3() {

  const [dataSearch, setDataSearch] = useState("")
  let [searchParams, setSearchParams] = useSearchParams();
  const url = searchParams.get("url");

  const getUri = (l) => {
    if (l.length < 6) return
    let arr = l.split("s3://clinica-internacional-2023/");
    return arr[arr.length - 1];
  }

  const getUri2 = (l) => {
    if (l.length < 6) return
    let arr = l.split("s3://ci-gestor-documental-2023/");
    return arr[arr.length - 1];
  }

  const openVisor = async (e, s) => {
    e.preventDefault();
    if (dataSearch.length < 6) return;
    await newTab(dataSearch, true);
  }

  const newTab = async (ds, nw) => {
    let linkEnv = 'clinica-internacional-2023'
    if (ds.includes("s3://clinica-internacional-2023/")) ds = getUri(ds);
    else if (ds.includes("s3://ci-gestor-documental-2023/")) { ds = getUri2(ds); linkEnv = 'ci-gestor-documental-2023' }
    try {
      const response = await axios.get(
        `https://qigo043dp3.execute-api.us-east-1.amazonaws.com/apirpa/getObject?expires=0&env=${linkEnv}&key=${ds}`
      );
      if (response.data) {
        if (nw) window.open(response.data, '_blank')
        else window.location.replace(response.data)
      }
      else {
        await messageAlert("Archivo no encontrado", "El archivo seleccionado no se encontró.")
      }
    } catch (error) {
      //console.log(error)
      Swal.fire({
        icon: "info",
        title: "Error",
        text: "Error al generar la petición.",
      })
    }
  }

  useEffect(() => {
    if (url) {
      newTab(url, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className='my-5' style={{ maxWidth: "700px" }}>
      <h3 style={{ textAlign: "center" }}>Buscador S3</h3>
      <p style={{ textAlign: "center" }}>Ingresa la cadena de texto para buscar en S3. Se abrira el archivo en un nuevo tab.</p>
      <Form className="mb-3">
        <InputGroup className='rm-input-text'>
          <Form.Control
            placeholder="Ingresar uri s3"
            type="text"
            style={{ paddingRight: "50px" }}
            value={dataSearch}
            onChange={(e) => {
              setDataSearch(e.target.value);
            }}
          />
          {dataSearch?.length > 0 && <InputGroup.Text className='rm-icon'><Button variant='link' onClick={() => { setDataSearch("") }}><FontAwesomeIcon icon={faClose} /></Button></InputGroup.Text>}
          <InputGroup.Text><button type="submit" className="btn" onClick={openVisor}>Buscar <FontAwesomeIcon icon={faSearch} /></button></InputGroup.Text>
        </InputGroup>
      </Form>
    </Container>
  )
}

export default VerS3;