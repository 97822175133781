import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import array_asistencial from "../data/jerarquia_update_asistencial";
import array_staff from "../data/jerarquia_update_staff";
import axios from "axios";
import Swal from "sweetalert2";
//utils
import {
  validateTypeNumberDoc,
  validateLengthNumberDoc,
  cleanBlankSpacesAndToUpperCase,
} from "./utils";

function ModalUpdated({ data, show, onHide }) {
  // useState para manejar los datos editados
  const [loggedUser, setLoggedUser] = useState(null);
  const [editedData, setEditedData] = useState(data);
  const [array_posicion_asistencial, setArray_posicion_asistencial] =
    useState(array_asistencial);
  const [array_posicion_staff, setArray_posicion_staff] = useState(array_staff);
  const [validated, setValidated] = useState(false);

  // useEffect para obtener el usuario logueado
  useEffect(() => {
    if (localStorage.getItem("user") !== null) {
      let user = JSON.parse(localStorage.getItem("user"));
      setLoggedUser(user);
    }
  }, []);

  //ASISTENCIAL
  /* 
  ---JERARQUIA DE PUESTOS 

 SELECT medico_perfil,"PUESTO ARCHIVO", gerencia, unidad_org_3, unidad_org_2, "Unid.Organizativa 1", count(1) 
 FROM public.medicos
 where medico_perfil ='ASISTENCIAL'
 group by medico_perfil,"PUESTO ARCHIVO", gerencia, unidad_org_3, unidad_org_2, "Unid.Organizativa 1"

  */
  const unique_gerencia_asistencial = () => {
    //obtener los valores de la gerencia unicos y ordenados
    const unique = [
      ...new Set(array_posicion_asistencial.map((item) => item.gerencia)),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_org_3 = (gerencia) => {
    //obtener los valores de la unidad organizativa 3 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter((item) => item.gerencia === gerencia)
          .map((item) => item.unidad_org_3)
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_org_2 = (gerencia, unidad_org_3) => {
    //obtener los valores de la unidad organizativa 2 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter(
            (item) =>
              item.gerencia === gerencia && item.unidad_org_3 === unidad_org_3
          )
          .map((item) => item.unidad_org_2)
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_org_1 = (gerencia, unidad_org_3, unidad_org_2) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter(
            (item) =>
              item.gerencia === gerencia &&
              item.unidad_org_3 === unidad_org_3 &&
              item.unidad_org_2 === unidad_org_2
          )
          .map((item) => item["Unid.Organizativa 1"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_unidad_puesto_archivo = (
    gerencia,
    unidad_org_3,
    unidad_org_2,
    unidad_org_1
  ) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_asistencial
          .filter(
            (item) =>
              item.gerencia === gerencia &&
              item.unidad_org_3 === unidad_org_3 &&
              item.unidad_org_2 === unidad_org_2 &&
              item["Unid.Organizativa 1"] === unidad_org_1
          )
          .map((item) => item["PUESTO ARCHIVO"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  //STAFF

  /* 
    SELECT medico_perfil,"PUESTO ARCHIVO", "Desc. Posición", "Desc. Área Personal", count(1) 
    FROM public.medicos
    where medico_perfil ='STAFF'
    group by medico_perfil,"PUESTO ARCHIVO", "Desc. Posición", "Desc. Área Personal"

  */

  const unique_gerencia_staff = () => {
    //obtener los valores de la gerencia unicos y ordenados
    const unique = [
      ...new Set(array_posicion_staff.map((item) => item["Desc. Posición"])),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_area_personal = (desc_posicion) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_staff
          .filter((item) => item["Desc. Posición"] === desc_posicion)
          .map((item) => item["Desc. Área Personal"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  const unique_puesto_archivo = (desc_posicion, desc_area_personal) => {
    //obtener los valores de la unidad organizativa 1 unicos y ordenados
    const unique = [
      ...new Set(
        array_posicion_staff
          .filter(
            (item) =>
              item["Desc. Posición"] === desc_posicion &&
              item["Desc. Área Personal"] === desc_area_personal
          )
          .map((item) => item["PUESTO ARCHIVO"])
      ),
    ];
    const unique_sorted = unique.sort();
    return unique_sorted;
  };

  //manejo de fechas
  const formatDate = (dateString) => {
    return moment(dateString).format("DD/MM/YYYY");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "gerencia")
      setEditedData({
        ...editedData,
        [name]: value,
        unidad_org_3: "",
        unidad_org_2: "",
        "Unid.Organizativa 1": "",
        "PUESTO ARCHIVO": "",
      });
    else if (name === "Desc. Posición")
      setEditedData({
        ...editedData,
        [name]: value,
        "Desc. Área Personal": "",
        "PUESTO ARCHIVO": "",
      });
    else setEditedData({ ...editedData, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Por favor, complete todos los campos.",
        confirmButtonColor: "#dc3545",
      });
    } else {
      const data = {
        "N°Documento": editedData["N°Documento"],
        medico_perfil: editedData["medico_perfil"],
        gerencia: editedData["gerencia"],
        unidad_org_3: editedData["unidad_org_3"],
        unidad_org_2: editedData["unidad_org_2"],
        "Unid.Organizativa 1": editedData["Unid.Organizativa 1"],
        "PUESTO ARCHIVO": editedData["PUESTO ARCHIVO"],
        "Desc. Posición": editedData["Desc. Posición"],
        "Desc. Área Personal": editedData["Desc. Área Personal"],
        Situación: editedData["Situación"],
        CRITICIDAD: editedData["CRITICIDAD"],
        user: loggedUser.name,
      };

      //validaciones todos los campos son obligatorios
      if (
        data["N°Documento"] === "" ||
        data.medico_perfil === "" ||
        data.gerencia === "" ||
        data.unidad_org_3 === "" ||
        data.unidad_org_2 === "" ||
        data["Unid.Organizativa 1"] === "" ||
        data["PUESTO ARCHIVO"] === "" ||
        data["Desc. Posición"] === "" ||
        data["Desc. Área Personal"] === "" ||
        data.Situación === "" ||
        data.CRITICIDAD === ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Por favor, complete todos los campos.",
          confirmButtonColor: "#dc3545",
        });
        return;
      }

      //validaciones de tipo de dato
      editedData["N°Documento"] = cleanBlankSpacesAndToUpperCase(
        editedData["N°Documento"]
      );

      try {
        const res = await axios.post(
          "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/update-medicos",
          JSON.stringify(data)
        );
        if (!res?.data?.data?.success) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res?.data?.data?.message,
            confirmButtonColor: "#dc3545",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Éxito",
            text: res?.data?.data?.message,
            confirmButtonColor: "#329fe7",
          });

          onHide();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al agregar el registro.",
          confirmButtonColor: "#dc3545",
        });
      }
    }
    setValidated(true);
  };

  //AVISOS ANTES DE CERRAR EL MODAL
  const closeModalUpdate = () => {
    if (
      editedData["N°Documento"] !== data["N°Documento"] ||
      editedData["medico_perfil"] !== data["medico_perfil"] ||
      editedData["gerencia"] !== data["gerencia"] ||
      editedData["unidad_org_3"] !== data["unidad_org_3"] ||
      editedData["unidad_org_2"] !== data["unidad_org_2"] ||
      editedData["Unid.Organizativa 1"] !== data["Unid.Organizativa 1"] ||
      editedData["PUESTO ARCHIVO"] !== data["PUESTO ARCHIVO"] ||
      editedData["Desc. Posición"] !== data["Desc. Posición"] ||
      editedData["Desc. Área Personal"] !== data["Desc. Área Personal"] ||
      editedData["Situación"] !== data["Situación"] ||
      editedData["CRITICIDAD"] !== data["CRITICIDAD"]
    ) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Los cambios realizados no se guardarán.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#329fe7",
        cancelButtonColor: "#dc3545",
        confirmButtonText: "Si, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          onHide();
        }
      });
    } else {
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={closeModalUpdate} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar Información del Médico
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSave}>
          <Row>
            {/* Primera Columna */}
            <Col md={6}>
              <Form.Group className="mt-2" controlId="formTDoc">
                <Form.Label>T.Doc.:</Form.Label>
                <Form.Select
                  name="T.Doc."
                  value={editedData["T.Doc."] === "DNI" ? "DNI" : "CEX"}
                  onChange={handleChange}
                  autoFocus={true}
                  disabled
                >
                  <option defaultValue>DNI</option>
                  <option value="CEX">CEX</option>
                </Form.Select>
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-2" controlId="formNDocumento">
                <Form.Label>Número de Documento:</Form.Label>
                <Form.Control
                  type="text"
                  name="N°Documento"
                  value={editedData["N°Documento"]}
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-2" controlId="formApellidosNombres">
                <Form.Label>Apellidos y Nombres:</Form.Label>
                <Form.Control
                  type="text"
                  name="Apellidos y Nombres"
                  value={editedData["Apellidos y Nombres"]}
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-2" controlId="formFecIngreso">
                <Form.Label>Fec. Ingreso:</Form.Label>
                <Form.Control
                  type="text"
                  name="Fec. Ingreso"
                  value={
                    formatDate(editedData["Fec. Ingreso"])
                      ? formatDate(editedData["Fec. Ingreso"])
                      : "-"
                  }
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mt-2" controlId="formMedicoPerfil">
                <Form.Label>Medico Perfil:</Form.Label>
                <Form.Control
                  type="text"
                  name="medico_perfil"
                  value={editedData["medico_perfil"] === 'ASISTENCIAL' ? 'PLANILLA' : editedData["medico_perfil"]}
                  onChange={handleChange}
                  disabled
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>
            </Col>

            {/* Segunda Columna */}
            <Col md={6}>
              {editedData["medico_perfil"] === "ASISTENCIAL" ? (
                <>
                  <Form.Group className="mt-2" controlId="formGerencia">
                    <Form.Label>Gerencia:</Form.Label>
                    <Form.Select
                      name="gerencia"
                      value={editedData["gerencia"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_gerencia_asistencial().map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formUnidadOrg3">
                    <Form.Label>U.O 3:</Form.Label>
                    <Form.Select
                      name="unidad_org_3"
                      value={editedData["unidad_org_3"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_unidad_org_3(editedData["gerencia"]).map(
                        (item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      )}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formUnidadOrg2">
                    <Form.Label>U.O 2:</Form.Label>
                    <Form.Select
                      name="unidad_org_2"
                      value={editedData["unidad_org_2"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_unidad_org_2(
                        editedData["gerencia"],
                        editedData["unidad_org_3"]
                      ).map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formUnidadOrg1">
                    <Form.Label>U.O 1:</Form.Label>
                    <Form.Select
                      name="Unid.Organizativa 1"
                      value={editedData["Unid.Organizativa 1"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_unidad_org_1(
                        editedData["gerencia"],
                        editedData["unidad_org_3"],
                        editedData["unidad_org_2"]
                      ).map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formPuestoArchivo">
                    <Form.Label>PUESTO ARCHIVO:</Form.Label>
                    <Form.Select
                      name="PUESTO ARCHIVO"
                      value={editedData["PUESTO ARCHIVO"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_unidad_puesto_archivo(
                        editedData["gerencia"],
                        editedData["unidad_org_3"],
                        editedData["unidad_org_2"],
                        editedData["Unid.Organizativa 1"]
                      ).map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formSituacion">
                    <Form.Label>Situación:</Form.Label>
                    <Form.Select
                      name="Situación"
                      value={editedData["Situación"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      <option value="ACTIVO">ACTIVO</option>
                      <option value="INACTIVO">INACTIVO</option>
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formCriticicidad">
                    <Form.Label>Criticidad:</Form.Label>
                    <Form.Select
                      name="CRITICIDAD"
                      value={editedData["CRITICIDAD"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group className="mt-2" controlId="formDescPosicion">
                    <Form.Label>Especialidad: </Form.Label>
                    <Form.Select
                      name="Desc. Posición"
                      value={editedData["Desc. Posición"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_gerencia_staff().map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formDescAreaPersonal">
                    <Form.Label>Área:</Form.Label>
                    <Form.Select
                      name="Desc. Área Personal"
                      value={editedData["Desc. Área Personal"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_area_personal(editedData["Desc. Posición"]).map(
                        (item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      )}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formPuestoArchivo">
                    <Form.Label>PUESTO ARCHIVO:</Form.Label>
                    <Form.Select
                      name="PUESTO ARCHIVO"
                      value={editedData["PUESTO ARCHIVO"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      {unique_puesto_archivo(
                        editedData["Desc. Posición"],
                        editedData["Desc. Área Personal"]
                      ).map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formSituacion">
                    <Form.Label>Situación:</Form.Label>
                    <Form.Select
                      name="Situación"
                      value={editedData["Situación"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      <option value="ACTIVO">ACTIVO</option>
                      <option value="INACTIVO">INACTIVO</option>
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="formCriticicidad">
                    <Form.Label>Criticidad:</Form.Label>
                    <Form.Select
                      name="CRITICIDAD"
                      value={editedData["CRITICIDAD"]}
                      onChange={handleChange}
                    >
                      <option value="">Seleccione</option>
                      <option value="SI">SI</option>
                      <option value="NO">NO</option>
                    </Form.Select>
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </Col>
          </Row>
          <hr />
          {/* Botón de Guardar */}
          <Form.Group controlId="formGuardar" className="mt-2 d-flex justify-content-end">
            <Button variant="primary" type="submit" className="me-2">
              Actualizar
            </Button>
            <Button variant="secondary" onClick={closeModalUpdate} className="me-2">
              Cancelar
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalUpdated;
