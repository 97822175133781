import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import MenuPrincipal from "../components/navbar";
import {
  faClose,
  faListCheck,
  faSearch,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, InputGroup, Modal, Alert } from "react-bootstrap";
import StickyHeadTable from "../components/table";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import PanelDahboard from "./reportes/panelDahboard";
import { verificarAcceso } from '../components/utils';

function Dashboard() {
  const [dataSearch, setDataSearch] = useState("");
  const [isSearched, setIsSearchead] = useState(false);
  const [arrPersonal, setArrPersonal] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [accesosAdicionales, setAccesosAdicionales] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [botomAyuda, setBotomAyuda] = useState(true);
  const [showModalGuia1, setShowModalGuia1] = useState(false);
  const [showModalGuia2, setShowModalGuia2] = useState(false);
  const [showModalGuia3, setShowModalGuia3] = useState(false);

  const user = JSON.parse(localStorage.getItem("user")) || {};

  let navigate = useNavigate();
  /**Notificacion X Revisar */
  const [isRegPendRev, setIsRegPendRev] = useState(false);
  /** */

  const columns = [
    { id: "T.Doc.", label: "T. Doc", minWidth: 70 },
    { id: "N°Documento", label: "Nro. Documento", minWidth: 100 },
    { id: "Apellidos y Nombres", label: "Nombre", minWidth: 170 },
    { id: "Desc. Posición", label: "Puesto", minWidth: 170 },
    { id: "medico_perfil", label: "Perfil", minWidth: 100 },
    { id: "Situación", label: "Estado", minWidth: 100 },
    { id: "accion", label: "Archivos", minWidth: 70 },
  ];

  const getPorRevisarNotif = async () => {
    try {
      const res = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/rpa-ci-temp/v1/get-Revisione-init",
        {}
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const searchPersonal = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let aux = dataSearch?.trim().replace(/^0+/, "");
      //const res = await personalService.searchPersonal(dataSearch)
      const { data } = await axios.post(
        "https://ndvvbppkef.execute-api.us-east-1.amazonaws.com/bpi/clinica-internacional/v1/medicos",
        JSON.stringify({ ci_valor: aux })
      );
      if (data?.data?.count > 0) {
        setArrPersonal(data?.data?.data);
      } else {
        setArrPersonal([]);
      }
      setIsSearchead(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const [dRevisarNotif, accesos] = await Promise.all([
          getPorRevisarNotif(),
          verificarAcceso(user.ext)
        ]);
        if (dRevisarNotif?.data?.count > 0) {
          setIsRegPendRev(true);
        } else setIsRegPendRev(false);
        if (accesos) {
          setAccesosAdicionales(accesos)
        }
        setIsPageLoading(false)
      } catch (error) {
        console.log(error);
        setIsPageLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <MenuPrincipal isLoading={isPageLoading} notifPendientes={isRegPendRev} accesos={accesosAdicionales} />
      <Container className='page-content'>
        <Row>
          <Col md={10}>
            <h4>Buscador de personal</h4>
            <p>
              Puedes realizar la búsqueda por nombre, documento de identidad y
              puesto. Ingresa datos precisos para optimizar la búsqueda.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col md={10} lg={8}>
            <Form className="mb-3">
              <InputGroup className="rm-input-text">
                <Form.Control
                  placeholder="Buscar por nombre, doc. identidad, año, puesto"
                  type="text"
                  value={dataSearch}
                  onChange={(e) => {
                    setDataSearch(e.target.value);
                  }}
                />
                {dataSearch?.length > 0 && (
                  <InputGroup.Text className="rm-icon">
                    <Button
                      variant="link"
                      onClick={() => {
                        setDataSearch("");
                      }}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  </InputGroup.Text>
                )}
                <InputGroup.Text>
                  <button
                    type="submit"
                    className="btn"
                    onClick={searchPersonal}
                    disabled={isLoading}
                  >
                    Buscar <FontAwesomeIcon icon={faSearch} />
                  </button>
                </InputGroup.Text>
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            {arrPersonal.length > 0 ?
              <StickyHeadTable columns={columns} rows={arrPersonal} refIdButton={"N°Documento"} iconButton={[faListCheck]} isRRHH={user.rol === 2} permisoVerificador={accesosAdicionales?.v1 || accesosAdicionales?.adm} />
              : ""}
            {isSearched && arrPersonal?.length < 1 && <p className='text-center'>No se encontraron datos</p>}
          </Col>
        </Row>
        {user.rol === 2 && <PanelDahboard />}
      </Container>

      {/* 
        boton de ayuda
       */}
      {botomAyuda && (
        <div className="btn-ayuda">
          <Button
            style={{ position: "fixed", bottom: "20px", left: "40px", borderRadius: "50%", height: "70px", textAlign: "center" }}
            onClick={() => {
              setShowModalGuia1(true);
            }}
          >
            <FontAwesomeIcon icon={faQuestionCircle} size="3x" />
          </Button>
        </div>
      )}
      {/* modal guia */}
      <Modal
        show={showModalGuia1}
        onHide={() => setShowModalGuia1(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Guía de uso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <img src="https://clinicainternacional.doctorfile.pe/reportes/guia_usuario/pantalla1/adm_rrhh_pantalla1_adm_personal.png" width={"100%"} alt="guia" />
            </div>
            <div>
              <p>
                <strong>1. </strong>El boton "Adm. Personal" permite buscar el personal de la clinica internacional.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalGuia1(false)}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModalGuia1(false);
              setShowModalGuia2(true);
            }}
          >
            Siguiente
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal guia 2 */}
      <Modal
        show={showModalGuia2}
        onHide={() => setShowModalGuia2(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Guía de uso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>1. </strong>Para editar un archivo, haga click en el botón
            "Editar" en la esquina superior derecha.
          </p>
          <p>
            <strong>2. </strong>Seleccione el archivo que desea editar y haga
            click en "Abrir".
          </p>
          <p>
            <strong>3. </strong>Complete los campos solicitados y haga click en
            "Guardar".
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalGuia2(false)}>
            Cerrar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModalGuia2(false);
              setShowModalGuia3(true);
            }}
          >
            Siguiente
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal guia 3 */}
      <Modal
        show={showModalGuia3}
        onHide={() => setShowModalGuia3(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Guía de uso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>1. </strong>Para ver los archivos subidos, haga click en el
            botón "Archivos" en la esquina superior derecha.
          </p>
          <p>
            <strong>2. </strong>Seleccione el archivo que desea ver y haga click
            en "Abrir".
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalGuia3(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Dashboard;

//rutas de las imagenes https://clinicainternacional.doctorfile.pe/reportes/guia_usuario/pantalla1/adm_rrhh_pantalla1_adm_personal.png
